import React, { Component } from "react";
import _ from 'lodash';
import { getISOWeek } from "date-fns";

import styles from "./Alcance.module.scss";

const mesesAlcance = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

class Alcance extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tooltipElements: [],
        };
    }

    componentDidMount() {
        const { apiData } = this.props;

        let ventasData = [...apiData.ventas];
        let ventasByCadena = _.groupBy(ventasData, 'cadena');
        ventasByCadena = Object.keys(ventasByCadena).map(cadena => {
            let cadenaInfo = ventasByCadena[cadena];
            let maxDate = _.maxBy(cadenaInfo, 'fechaFormato').fechaFormato;
            let maxDateFormat;

            if (cadena === "Sam's Club") {
                let week = getISOWeek(maxDate);
                let year = maxDate.getFullYear();
                maxDateFormat = `Semana ${week}-${year}`;
            } else {
                let day = maxDate.getDate() + '';
                day = day.length === 1 ? `0${day}` : day
                let month = mesesAlcance[maxDate.getMonth()];
                let year = maxDate.getFullYear();
                maxDateFormat = `${day !== '01' ? `${day} de ` : ''}${month} ${year}`;
            }

            return { cadena, maxDateFormat };
        });
        ventasByCadena = _.sortBy(ventasByCadena, 'cadena');

        let tooltipElements = ventasByCadena.map((c, i) => {
            return (<div className={styles["alcanceTooltipRow"]} key={`alcance_${i}`}>
                <span className={styles["alcanceTooltipBold"]}>{`${c.cadena}: `}</span>
                {`${c.maxDateFormat}`}
            </div>);
        });

        this.setState({ tooltipElements });
    }

    render() {
        const { tooltipElements } = this.state;

        return (
            <div className={styles["alcanceSubcontainer"]}>
                <span className={styles["aligHelper"]}></span>
                <div className={styles["alcanceSubtext"]}>Última actualización</div>
                <div className={styles["tooltipContainer"]}>
                    <img className={styles["alcanceIcon"]} src="./images/iconosMenu/info.svg" alt=""></img>
                    <div className={styles["alcanceTooltip"]}>
                        <div className={styles["alcanceTooltipTitle"]}>Última actualización</div>
                        {tooltipElements}
                    </div>
                </div>
            </div>
        );
    }
}

export default Alcance;