import React, { Component } from "react";
import { connect } from "react-redux";
import ReactECharts from 'echarts-for-react';
// import Switch from "react-switch";
import _ from 'lodash';

import Header from "../header/Header";
import Filtro from "../filtro/Filtro";
import TooltipRanking from "./TooltipRanking";
import IndicadorTotales from "../indicadortotales/IndicadorTotales";
import Alcance from "../alcance/Alcance";
import FiltrosAplicados from "../alcance/FiltrosAplicados";
// import PseudoBreadcrumb from "../pseudobreadcrumb/PseudoBreadcrumb";
// import FiltroTreemap from "../filtrotreemap/FiltroTreemap";
import styles from "./Vista6.module.scss";

import { formatNumberWithCommas, getYTDText, getCanalSubtitle } from "../../scripts/helpers";
import { dataAction, filterDataAction } from "../../actions";
import { startOfMonth, endOfMonth, endOfISOWeek } from "date-fns";

class Vista6 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nombresGrafica: [], valoresVolumen: [], valoresVentas: [],
            lineGraphData: [],
            // ==================================
            graphColor: '',
            graphOptionsA: {},
            graphOptionsB: {},
            graphOptionsC: {},
            barGraphHeight: 500,
            lineGraphHeight: 750,
            // ==================================
            calendarDate: [new Date(2022, 0, 1), new Date(2022, 11, 1)],
            calendarSPFilter: 'all',
            tipoDivision: 0,
            volumenValor: 0,
            radioValue: 0,
            isSellIn: 0,
            regionesShown: [],
            estadosShown: [],
            clientesShown: [],
            sucursalesShown: [],
            marcasShown: [],
            productosShown: [],
            // ================================================
            availableRegiones: [],
            availableEstados: [],
            availableClientes: [],
            availableSucursales: [],
            availableMarcas: [],
            availableProductos: [],
        };

        this.handleChangeCalendar = this.handleChangeCalendar.bind(this);
        this.handleChangeCalendarWeek = this.handleChangeCalendarWeek.bind(this);
        this.handleChanceCalendarSPFilter = this.handleChanceCalendarSPFilter.bind(this);
        this.handleChangeTipo = this.handleChangeTipo.bind(this);
        this.handleChangeVolumenValor = this.handleChangeVolumenValor.bind(this);
        this.handleChangeRadio = this.handleChangeRadio.bind(this);
        this.handleChangeIsSellIn = this.handleChangeIsSellIn.bind(this);
        this.handleChangeRegion = this.handleChangeRegion.bind(this);
        this.handleChangeEstado = this.handleChangeEstado.bind(this);
        this.handleChangeCliente = this.handleChangeCliente.bind(this);
        this.handleChangeSucursal = this.handleChangeSucursal.bind(this);
        this.handleChangeMarca = this.handleChangeMarca.bind(this);
        this.handleChangeProducto = this.handleChangeProducto.bind(this);
        this.restartFilter = this.restartFilter.bind(this);
    }

    handleChangeCalendar(calendarDate) { this.setState({ calendarDate }); }
    handleChangeCalendarWeek(weekNumber, date, event) {
        let endOfWeek = endOfISOWeek(date);
        let calendarDate = [date, endOfWeek];

        this.setState({ calendarDate });
    }
    handleChanceCalendarSPFilter(newCalendarSPFilter) {
        const { calendarSPFilter, calendarDate } = this.state;

        let newCalendarDate = calendarDate;
        if (['daily', 'weekly'].includes(calendarSPFilter) && newCalendarSPFilter === 'all') {
            newCalendarDate = [startOfMonth(calendarDate[0]), endOfMonth(calendarDate[1])];
        }

        this.setState({ calendarSPFilter: newCalendarSPFilter, calendarDate: newCalendarDate });
    }
    handleChangeTipo(tipoDivision) { this.setState({ tipoDivision }); }
    handleChangeVolumenValor(volumenValor) { this.setState({ volumenValor }) }
    handleChangeIsSellIn(isSellIn) { this.setState({ isSellIn }) }
    handleChangeRadio(event) {
        let radioValue = parseInt(event.target.value);
        this.setState({ radioValue });
    }
    handleChangeRegion(event) {
        const { regionesShown } = this.state;
        let region = event.target.value;
        let newRegionesShown = [];
        let newSucursalesShown = [];
        let newEstadosShown = [];
        let newClientesShown = [];

        if (region === 'all') {
            if (regionesShown.length !== 7) {
                newRegionesShown = [0, 1, 2, 3, 4, 5, 6];
                newSucursalesShown = this.props.data.sucursales.map(s => s.id);
                newEstadosShown = this.props.data.estados.map(e => e.estado);
                newClientesShown = this.props.data.cadenas.map(c => c.id);
            }
        } else {
            region = parseInt(region);
            if (regionesShown.includes(region)) {
                newRegionesShown = [...regionesShown].filter(r => r !== region);
            } else {
                newRegionesShown = [...regionesShown];
                newRegionesShown.push(region);
            }

            newSucursalesShown = _.uniq([...this.props.data.sucursales].filter(s => newRegionesShown.includes(parseInt(s.region))).map(s => s.id));
            newEstadosShown = this.props.data.estados.filter(e => newRegionesShown.includes(parseInt(e.region))).map(e => e.estado);
            newClientesShown = _.uniq([...this.props.data.sucursales].filter(s => newSucursalesShown.includes(s.id)).map(s => s.idCadena));
        }

        this.setState({ regionesShown: newRegionesShown, sucursalesShown: newSucursalesShown, estadosShown: newEstadosShown, clientesShown: newClientesShown });
    }
    handleChangeEstado(event) {
        const { estados, sucursales } = this.props.data;
        const { estadosShown } = this.state;
        let estado = event.target.value;
        let newEstadosShown = [];
        let newSucursalesShown = [];
        let newRegionesShown = [];
        let newClientesShown = [];

        if (estado === 'all') {
            if (estadosShown.length !== 33) {
                newEstadosShown = estados.map(e => e.estado);
                newSucursalesShown = sucursales.map(s => s.id);
                newRegionesShown = [0, 1, 2, 3, 4, 5, 6];
                newClientesShown = this.props.data.cadenas.map(c => c.id);
            }
        } else {
            if (estadosShown.includes(estado)) {
                newEstadosShown = [...estadosShown].filter(e => e !== estado);
            } else {
                newEstadosShown = [...estadosShown];
                newEstadosShown.push(estado);
            }

            newSucursalesShown = _.uniq([...sucursales].filter(s => newEstadosShown.includes(s.estado)).map(s => s.id));
            newRegionesShown = _.uniq([...estados].filter(e => newEstadosShown.includes(e.estado)).map(e => parseInt(e.region)));
            newClientesShown = _.uniq([...sucursales].filter(s => newSucursalesShown.includes(s.id)).map(s => s.idCadena));
        }

        this.setState({ estadosShown: newEstadosShown, sucursalesShown: newSucursalesShown, regionesShown: newRegionesShown, clientesShown: newClientesShown });
    }
    handleChangeSucursal(event) {
        const { sucursalesShown, availableSucursales } = this.state;
        let idSucursal = event.target.value;
        let newSucursalesShown = [];
        let newRegionesShown = [];
        let newEstadosShown = [];
        let newClientesShown = [];

        if (idSucursal === 'all') {
            if (sucursalesShown.length !== this.props.data.sucursales.length) {
                newSucursalesShown = this.props.data.sucursales.map(s => s.id);
                newRegionesShown = [0, 1, 2, 3, 4, 5, 6];
                newEstadosShown = this.props.data.estados.map(e => e.estado);
                newClientesShown = this.props.data.cadenas.map(c => c.id);
            }
        } else if (idSucursal.includes('all')) {
            let idCadena = idSucursal.split('_')[1];

            let allSucursalesInRegion = [...this.props.data.sucursales].filter(s => s.idCadena === idCadena).map(s => s.id);
            if (!allSucursalesInRegion.some(s => sucursalesShown.includes(s))) {
                newSucursalesShown = [...sucursalesShown].concat(allSucursalesInRegion);
            } else {
                newSucursalesShown = [...sucursalesShown].filter(s => !allSucursalesInRegion.includes(s));
            }

            let tmpObject = [...this.props.data.sucursales].filter(s => newSucursalesShown.includes(s.id));
            newRegionesShown = _.uniq(tmpObject.map(s => parseInt(s.region)));
            newEstadosShown = _.uniq(tmpObject.map(s => s.estado));
            newClientesShown = _.uniq(tmpObject.map(s => s.idCadena));
        } else {
            if (sucursalesShown.includes(idSucursal)) {
                newSucursalesShown = [...sucursalesShown].filter(s => s !== idSucursal);
            } else {
                newSucursalesShown = [...sucursalesShown];
                newSucursalesShown.push(idSucursal);
            }
            newSucursalesShown = newSucursalesShown.filter(s => availableSucursales.includes(s));

            let tmpObject = [...this.props.data.sucursales].filter(s => newSucursalesShown.includes(s.id));
            newRegionesShown = _.uniq(tmpObject.map(s => parseInt(s.region)));
            newEstadosShown = _.uniq(tmpObject.map(s => s.estado));
            newClientesShown = _.uniq(tmpObject.map(s => s.idCadena));
        }

        this.setState({ sucursalesShown: newSucursalesShown, regionesShown: newRegionesShown, estadosShown: newEstadosShown, clientesShown: newClientesShown });
    }
    handleChangeCliente(event) {
        const { clientesShown, availableClientes } = this.state;
        let idCadena = event.target.value;
        let newClientesShown = [];

        if (idCadena === 'all') {
            if (clientesShown.length !== this.props.data.cadenas.length) {
                newClientesShown = this.props.data.cadenas.map(c => c.id);
            }
        } else if (idCadena.includes('all')) {
            let letra = idCadena.split('_')[1];

            let allClientesInLetter = [];
            if (letra === '#') allClientesInLetter = [...this.props.data.cadenas].filter(c => /^\d/.test(c.nombre)).map(c => c.id);
            else allClientesInLetter = [...this.props.data.cadenas].filter(c => c.nombre.toUpperCase().startsWith(letra)).map(c => c.id);

            if (!allClientesInLetter.some(p => clientesShown.includes(p))) {
                newClientesShown = [...clientesShown].concat(allClientesInLetter);
            } else {
                newClientesShown = [...clientesShown].filter(p => !allClientesInLetter.includes(p));
            }
        } else {
            if (clientesShown.includes(idCadena)) {
                newClientesShown = [...clientesShown].filter(c => c !== idCadena);
            } else {
                newClientesShown = [...clientesShown];
                newClientesShown.push(idCadena);
            }
            newClientesShown = newClientesShown.filter(c => availableClientes.includes(c));
        }

        this.setState({ clientesShown: newClientesShown });
    }
    handleChangeMarca(event) {
        const { marcasShown } = this.state;
        let marca = event.target.value;
        let newMarcasShown = [];
        let newproductosShown = [];

        if (marca === 'all') {
            if (marcasShown.length !== this.props.data.marcas.length) {
                newMarcasShown = this.props.data.marcas.map(m => m.marca);
                newproductosShown = this.props.data.productos.map(c => c.id);
            }
        } else {
            if (marcasShown.includes(marca)) {
                newMarcasShown = [...marcasShown].filter(m => m !== marca);
            } else {
                newMarcasShown = [...marcasShown];
                newMarcasShown.push(marca);
            }
            newproductosShown = [...this.props.data.productos].filter(p => newMarcasShown.includes(p.marca)).map(p => p.id);
        }

        this.setState({ marcasShown: newMarcasShown, productosShown: newproductosShown });
    }
    handleChangeProducto(event) {
        const { productosShown, availableProductos } = this.state;
        let idProducto = event.target.value;
        let newproductosShown = [];
        let newMarcasShown = [];

        if (idProducto === 'all') {
            if (productosShown.length !== this.props.data.productos.length) {
                newproductosShown = this.props.data.productos.map(c => c.id);
                newMarcasShown = this.props.data.marcas.map(m => m.marca);
            }
        } else if (idProducto.includes('all')) {
            let marca = idProducto.split('_')[1];
            let allProductsInMarca = [...this.props.data.productos].filter(p => p.marca === marca).map(p => p.id);

            if (!allProductsInMarca.some(p => productosShown.includes(p))) {
                newproductosShown = [...productosShown].concat(allProductsInMarca);
            } else {
                newproductosShown = [...productosShown].filter(p => !allProductsInMarca.includes(p));
            }

            newMarcasShown = _.uniq([...this.props.data.productos].filter(p => newproductosShown.includes(p.id)).map(p => p.marca));

        } else {
            if (productosShown.includes(idProducto)) {
                newproductosShown = [...productosShown].filter(c => c !== idProducto);
            } else {
                newproductosShown = [...productosShown];
                newproductosShown.push(idProducto);
            }
            newproductosShown = newproductosShown.filter(p => availableProductos.includes(p));

            newMarcasShown = _.uniq([...this.props.data.productos].filter(p => newproductosShown.includes(p.id)).map(p => p.marca));
        }

        this.setState({ productosShown: newproductosShown, marcasShown: newMarcasShown });
    }

    async componentDidMount() {
        const hostname = window.location.hostname;
        let graphColor;

        const root = document.documentElement;
        root.style.setProperty('--calendarSelectedRangeColor', '#CCCCCC');

        switch (hostname) {
            case '3.12.19.179':
                graphColor = '#515662';
                break;
            case 'demo.datalabmx.com':
                graphColor = '#575756';
                break;
            case '3.17.121.27':
                graphColor = '#515662';
                break;
            case 'corporacionrica.datalabmx.com':
                graphColor = '#333333';
                break;
            case 'locotequila.datalabmx.com':
                graphColor = '#772835';
                break;
            case 'cbrands.datalabmx.com':
                graphColor = '#001E5B';
                break;
            case 'alianza.datalabmx.com':
                graphColor = '#68476C';
                break;
            default:
                graphColor = '#68476C';
        }

        this.setState({ graphColor });

        // ====================================================================

        if (_.isEmpty(this.props.data)) await this.props.dataAction();
        /* console.log('========== INFORMACIÓN EN RANKING ==========');
        console.log(this.props.data); */

        let regionesShown = [0, 1, 2, 3, 4, 5, 6];
        let estadosShown = this.props.data.estados.map(e => e.estado);
        let clientesShown = this.props.data.cadenas.map(c => c.id);
        let sucursalesShown = this.props.data.sucursales.map(s => s.id);
        let marcasShown = this.props.data.marcas.map(m => m.marca);
        let productosShown = this.props.data.productos.map(p => p.id);

        let isSellIn = ['locotequila.datalabmx.com'].includes(hostname) ? 1 : 0;
        this.setState({ regionesShown, estadosShown, clientesShown, sucursalesShown, marcasShown, productosShown, isSellIn }, () => { this.buildGraphData(); });
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            (prevState.volumenValor !== this.state.volumenValor) || (!_.isEqual(prevState.calendarDate, this.state.calendarDate)) || (prevState.calendarSPFilter !== this.state.calendarSPFilter) ||
            (prevState.tipoDivision !== this.state.tipoDivision) || (prevState.radioValue !== this.state.radioValue) || (prevState.isSellIn !== this.state.isSellIn) ||
            (!_.isEqual(prevState.regionesShown, this.state.regionesShown)) || (!_.isEqual(prevState.estadosShown, this.state.estadosShown)) || (!_.isEqual(prevState.clientesShown, this.state.clientesShown)) || (!_.isEqual(prevState.marcasShown, this.state.marcasShown)) ||
            (!_.isEqual(prevState.productosShown, this.state.productosShown)) || (!_.isEqual(prevState.sucursalesShown, this.state.sucursalesShown))
        ) {
            this.buildGraphData();
        } else if (
            (!_.isEqual(prevState.nombresGrafica, this.state.nombresGrafica)) || (!_.isEqual(prevState.valoresVolumen, this.state.valoresVolumen)) ||
            (!_.isEqual(prevState.valoresVentas, this.state.valoresVentas)) ||
            (!_.isEqual(prevState.lineGraphData, this.state.lineGraphData))
        ) {
            this.updateGraphOptions();
        }
    }

    buildGraphData() {
        const { calendarDate, calendarSPFilter, tipoDivision, volumenValor, radioValue, isSellIn, regionesShown, estadosShown, clientesShown, sucursalesShown, marcasShown, productosShown } = this.state;
        const { ventas, sucursales, cadenas, marcas, productos } = this.props.data;
        const hostname = window.location.hostname;

        let mainGroupBy = tipoDivision === 0 ? 'cadena' : (tipoDivision === 1 ? 'marca' : (tipoDivision === 2 ? 'nombreProducto' : 'sucursal'));
        let ventasData = [...ventas];
        if (calendarSPFilter === 'daily') {
            ventasData = ventasData.filter(v => ['1', '2', '3', '5', '13', '14'].includes(v.idCadena));
        } else if (calendarSPFilter === 'weekly') {
            ventasData = ventasData.filter(v => ['1', '2', '3', '4', '5', '13', '14'].includes(v.idCadena));
        }
        ventasData = ventasData.filter(v => v.fechaFormato >= calendarDate[0] && v.fechaFormato <= calendarDate[1]);
        ventasData = ventasData.filter(v => v.isSellIn === isSellIn);

        let availableRegiones = _.uniq(ventasData.map(v => v.region));
        let availableEstados = _.uniq(ventasData.map(v => v.estado));
        let availableClientes = _.uniq(ventasData.map(v => v.idCadena));
        let availableSucursales = _.uniq(ventasData.map(v => v.idSucursal));
        let availableMarcas = _.uniq(ventasData.map(v => v.marca));
        let availableProductos = _.uniq(ventasData.map(v => v.idProducto));

        ventasData = ventasData.filter(v => regionesShown.includes(v.region));
        ventasData = ventasData.filter(v => estadosShown.includes(v.estado));
        ventasData = ventasData.filter(v => clientesShown.includes(v.idCadena));
        ventasData = ventasData.filter(v => sucursalesShown.includes(v.idSucursal));
        ventasData = ventasData.filter(v => marcasShown.includes(v.marca));
        ventasData = ventasData.filter(v => productosShown.includes(v.idProducto));

        if (['locotequila.datalabmx.com'].includes(hostname)) {
            if (radioValue !== 0) ventasData = ventasData.filter(v => v.idCanal === radioValue);
        } else {
            let ventasPorTelefono = ['23', '149', '3484', '6046'];
            let ventasPorInternet = ['22', '148', '190', '693', '3478', '4928', '5262', '4856', '4990'];
            let ventasConjuntas = ventasPorTelefono.concat(ventasPorInternet);

            let cadenasMayoristas = ['5', '6', '9', '10', '11', '12', '13', '14', '16', '19', '20'];

            switch (radioValue) {
                case 0:
                    // Todos
                    break;
                case 1:
                    // Mayoreo
                    ventasData = ventasData.filter(v => !ventasConjuntas.includes(v.idSucursal) && cadenasMayoristas.includes(v.idCadena));
                    break;
                case 2:
                    // Menudeo
                    ventasData = ventasData.filter(v => !ventasConjuntas.includes(v.idSucursal) && !cadenasMayoristas.includes(v.idCadena));
                    break;
                case 3:
                    // Telemarketing
                    ventasData = ventasData.filter(v => ventasPorTelefono.includes(v.idSucursal));
                    break;
                case 4:
                    // E-Commerce
                    ventasData = ventasData.filter(v => ventasPorInternet.includes(v.idSucursal));
                    break;
                default:
                    console.log('Opción no encontrada');
            }
        }

        ventasData = _.groupBy(ventasData, mainGroupBy);
        ventasData = Object.keys(ventasData).map(key => {
            let keyInfo = ventasData[key];
            let valueMXN = parseFloat(_.sumBy(keyInfo, (v) => { return v.ventas_mxn; }).toFixed(0));
            let valueUnidades = _.sumBy(keyInfo, (v) => { return v.ventas_unidades; });
            valueUnidades = parseFloat(valueUnidades.toFixed(valueUnidades > 1 ? 0 : 2));
            let valueTicket = parseFloat(_.meanBy(keyInfo, (v) => { return v.ticket }).toFixed(0));

            return { key, valueMXN, valueUnidades, valueTicket, keyInfo }
        });

        let objetoParaCompletar = tipoDivision === 0 ? cadenas : (tipoDivision === 1 ? marcas : (tipoDivision === 2 ? productos : sucursales));
        let objetoParaVerificar = tipoDivision === 0 ? clientesShown : (tipoDivision === 1 ? marcasShown : (tipoDivision === 2 ? productosShown : sucursalesShown))
        if (tipoDivision === 3) {
            switch (radioValue) {
                case 0:
                    // Todos
                    break;
                case 2:
                    // Menudeo
                    objetoParaCompletar = objetoParaCompletar.filter(s => !['22', '23', '148', '149', '190'].includes(s.id));
                    break;
                case 3:
                    // Telemarketing
                    objetoParaCompletar = objetoParaCompletar.filter(s => ['23', '149'].includes(s.id));
                    break;
                case 4:
                    // E-Commerce
                    objetoParaCompletar = objetoParaCompletar.filter(s => ['22', '148', '190'].includes(s.id));
                    break;
                default:
                    console.log('Opción no encontrada');
            }
        }

        objetoParaCompletar.forEach(o => {
            if (tipoDivision !== 1) {
                if (!ventasData.some(v => v.key === o.nombre) && objetoParaVerificar.includes(o.id)) ventasData.push({ key: o.nombre, valueMXN: 0, valueUnidades: 0, valueTicket: 0, keyInfo: [] });
            } else {
                if (!ventasData.some(v => v.key === o.marca) && objetoParaVerificar.includes(o.marca)) ventasData.push({ key: o.marca, valueMXN: 0, valueUnidades: 0, valueTicket: 0, keyInfo: [] });
            }
        });
        /* console.log('Iniciamos con', ventasData); */

        let mainOrderBy = volumenValor === 0 ? 'valueUnidades' : (volumenValor === 1 ? 'valueMXN' : 'valueTicket');

        let objetosGrafica = _.orderBy(ventasData, mainOrderBy, 'desc').slice(0, 2400);
        let nombresGrafica = objetosGrafica.map(v => v.key);
        let coloresGrafica = tipoDivision === 2 ? this.obtainSKUColors(objetosGrafica) : (tipoDivision === 3 ? this.obtainSucursalesColors(objetosGrafica) : []);
        let valoresVolumen = objetosGrafica.map((v, i) => {
            let color = 'red';
            if (mainGroupBy === 'cadena') color = cadenas.find(c => c.nombre === nombresGrafica[i]).color;
            else if (mainGroupBy === 'marca') color = marcas.find(m => m.marca === nombresGrafica[i]).colorMarca;
            else if (mainGroupBy === 'nombreProducto' || mainGroupBy === 'sucursal') color = coloresGrafica[i];

            return { value: v.valueUnidades, itemStyle: { color } };
        });
        let valoresVentas = objetosGrafica.map((v, i) => {
            let color = 'red';
            if (mainGroupBy === 'cadena') color = cadenas.find(c => c.nombre === nombresGrafica[i]).color;
            else if (mainGroupBy === 'marca') color = marcas.find(m => m.marca === nombresGrafica[i]).colorMarca;
            else if (mainGroupBy === 'nombreProducto' || mainGroupBy === 'sucursal') color = coloresGrafica[i];

            return { value: v.valueMXN, itemStyle: { color } };
        });

        let lineGraphData = objetosGrafica.map((v, i) => {
            let infoByMonths = _.groupBy(v.keyInfo, 'mesVenta');
            let arrayData = [];
            for (let i = 0; i <= 11; i++) {
                let monthValue = infoByMonths[i];
                if (!monthValue) {
                    arrayData.push(0);
                    continue;
                }
                let suma = volumenValor !== 2 ? _.sumBy(monthValue, volumenValor === 0 ? 'ventas_unidades' : 'ventas_mxn') : _.meanBy(monthValue, 'ticket');
                arrayData.push(suma.toFixed(suma > 1 ? 0 : 2));
            }

            let color = 'red';
            if (mainGroupBy === 'cadena') color = cadenas.find(c => c.nombre === v.key).color;
            else if (mainGroupBy === 'marca') color = marcas.find(m => m.marca === v.key).colorMarca;
            else if (mainGroupBy === 'nombreProducto' || mainGroupBy === 'sucursal') color = coloresGrafica[i];

            let objectToReturn = {
                name: v.key,
                type: 'line',
                showSymbol: true,
                symbol: 'circle',
                symbolSize: 7,
                lineStyle: { width: 3 },
                emphasis: { focus: "series" },
                color,
                data: arrayData,
            };

            return objectToReturn
        });

        let barGraphHeight = objetosGrafica.length <= 8 ? 350 : (objetosGrafica.length * (tipoDivision === 3 ? 12 : 40));

        let lineGraphHeight = 700;
        if (tipoDivision !== 3) lineGraphHeight = 655 + 95 + ((Math.ceil(objetosGrafica.length / 7) - 1) * 21.4);

        this.setState({
            nombresGrafica, valoresVolumen, valoresVentas,
            lineGraphData,
            barGraphHeight, lineGraphHeight,
            availableRegiones, availableEstados, availableClientes, availableSucursales, availableMarcas, availableProductos
        });
    }

    updateGraphOptions() {
        const {
            calendarDate,
            nombresGrafica, valoresVolumen, valoresVentas, lineGraphData,
            tipoDivision, volumenValor, graphColor, lineGraphHeight
        } = this.state;

        let firstYear = calendarDate[0].getFullYear();
        let secondYear = calendarDate[1].getFullYear();
        let graphYear = firstYear === secondYear ? `– ${firstYear}` : `DE ${firstYear} A ${secondYear}`

        const graphOptionsA = {
            grid: {
                top: 35,
                bottom: 10,
                right: '10%',
                left: '5%',
                containLabel: true,
            },
            title: {
                text: `Valor ${graphYear}`,
                top: 'top',
                left: '5%',
                textStyle: {
                    fontSize: '25px',
                    fontFamily: 'AvenirNextDemiBold',
                    color: '#040415',
                },
            },
            yAxis: {
                type: 'category',
                inverse: true,
                axisLabel: {
                    color: '#58595b',
                    fontFamily: 'AvenirHeavy',
                    fontSize: tipoDivision === 3 ? 6 : 12,
                },
                axisLine: {
                    lineStyle: {
                        width: 2,
                        color: '#D0D0D0'
                    },
                },
                axisTick: { show: false },
                data: nombresGrafica,
            },
            xAxis: {
                type: 'value',
                min: 0,
                axisLabel: {
                    color: '#d1d3d4',
                    fontFamily: 'AvenirNextRegular',
                    fontSize: '10px',
                    formatter: '${value}',
                },
                splitLine: {
                    lineStyle: {
                        color: '#000',
                        type: 'dashed',
                        opacity: 0.25,
                    },
                },
            },
            series: [
                {
                    type: 'bar',
                    data: valoresVentas,
                    label: {
                        show: true,
                        distance: 5,
                        position: 'right',
                        color: '#939598',
                        fontFamily: 'AvenirHeavy',
                        fontSize: tipoDivision === 3 ? 6 : 12,
                        formatter: (s) => { return `$${formatNumberWithCommas(s.value)}`; }
                    },
                    barWidth: tipoDivision === 3 ? 5 : 25,
                }
            ],
            color: graphColor,
            animation: false,
        };

        const graphOptionsB = {
            grid: {
                top: 35,
                bottom: 10,
                right: '5%',
                left: '5%',
                containLabel: true,
            },
            title: {
                text: `Volumen ${graphYear}`,
                top: 'top',
                left: '5%',
                textStyle: {
                    fontSize: '25px',
                    fontFamily: 'AvenirNextDemiBold',
                    color: '#040415',
                },
            },
            yAxis: {
                type: 'category',
                inverse: true,
                axisLabel: {
                    color: '#58595b',
                    fontFamily: 'AvenirHeavy',
                    fontSize: tipoDivision === 3 ? 6 : 12,
                },
                axisLine: {
                    lineStyle: {
                        width: 2,
                        color: '#D0D0D0'
                    },
                },
                axisTick: { show: false },
                data: nombresGrafica,
            },
            xAxis: {
                type: 'value',
                min: 0,
                axisLabel: {
                    color: '#d1d3d4',
                    fontFamily: 'AvenirNextRegular',
                    fontSize: '10px'
                },
                splitLine: {
                    lineStyle: {
                        color: '#000',
                        type: 'dashed',
                        opacity: 0.25,
                    },
                },
            },
            series: [
                {
                    type: 'bar',
                    data: valoresVolumen,
                    label: {
                        show: true,
                        distance: 5,
                        position: 'right',
                        color: '#939598',
                        fontFamily: 'AvenirHeavy',
                        fontSize: tipoDivision === 3 ? 6 : 12,
                        formatter: (s) => { return `${formatNumberWithCommas(s.value)}`; }
                    },
                    barWidth: tipoDivision === 3 ? 5 : 25,
                }
            ],
            color: graphColor,
            animation: false,
        };

        const graphOptionsC = {
            grid: {
                top: tipoDivision !== 3 ? lineGraphHeight - 655 : 10, // 95
                bottom: 55,
                right: '3%',
                left: '5%',
            },
            title: {
                show: tipoDivision !== 3,
                text: `${volumenValor === 0 ? 'Volumen' : 'Valor'} Mensual`,
                top: 25,
                left: '2%',
                textStyle: {
                    fontSize: '25px',
                    fontFamily: 'AvenirNextDemiBold',
                    color: '#040415',
                },
            },
            legend: {
                show: tipoDivision !== 3,
                itemWidth: 15,
                itemHeight: 15,
                right: '2%',
                icon: 'rect',
                top: 65,
                data: nombresGrafica,
                textStyle: {
                    fontFamily: 'AvenirHeavy', color: '#58595b',
                    fontSize: '10px',
                },
            },
            tooltip: {
                trigger: 'item',
                formatter: (d) => {
                    return `
                    <div style="display: flex; flex-direction: column">
                        <div>
                            <div style="display: inline-block; width: 1.85vh; height: 1.85vh; background-color: ${d.color};"></div>
                            <span style="font-size: 1.8vh; font-family: AvenirHeavy; color: #58595b;">${d.seriesName}: </span>
                        </div>
                        <span style="font-size: 2.29vh; font-family: AvenirHeavy; margin-left: 1.5vw; color: #58595b;">
                            ${volumenValor === 0 ? "" : "$"}${formatNumberWithCommas(parseFloat(d.value).toFixed(parseFloat(d.value) > 1 ? 0 : 2))}
                            <span style="font-size: 1.04vh; font-family: AvenirMedium; color: #939598;"> ${volumenValor === 0 ? " Cajas (9L)" : " "}</span>
                        </span>
                    </div>`;
                },
                /* trigger: 'axis',
                formatter: (series) => {
                    let objectsByColumn = 36;
                    let objectRows = series.map(s => {
                        return `<div style="margin: -8px 0px;">
                            <div style="display: inline-block; width: 8px; height: 0.65vh; background-color: ${s.color}; border-radius: 50%;"></div>
                            <div style="display: inline-block; padding-left: 0.05vw;">
                                <span style="font-size: 0.75vh; font-family: AvenirMedium;">${s.seriesName}: </span>
                                <span style="font-size: 0.85vh; font-family: AvenirBook;">${volumenValor === 0 ? '' : '$'}${formatNumberWithCommas(parseFloat(s.value).toFixed(s.value > 1 ? 0 : 2))} </span>
                            </div>
                        </div>`;
                    });
                    let tooltipTitle = `<span style="font-size: 1vh; font-family: AvenirHeavy; margin-bottom: 10px;">${volumenValor === 0 ? 'Cajas (9L)' : '$'} por ${tipoDivision === 0 ? 'Cadena' : (tipoDivision === 1 ? 'Marca' : (tipoDivision === 2 ? 'SKU' : 'Sucursal'))}</span>`;

                    if (objectRows.length <= objectsByColumn) {
                        return `
                        ${tooltipTitle}
                        <div style="grid-template-columns: 100%;">
                            <div>
                                ${objectRows.join('')}
                            </div>
                        </div>
                        `;
                    } else {
                        let numberOfColumns = Math.ceil(objectRows.length / objectsByColumn);
                        let gridTemplateColumns = `repeat(${numberOfColumns}, ${(100 / numberOfColumns).toFixed(0)}%)`;
                        // let gridTemplateColumns = `50% 50%`;
                        let columnsArray = [];

                        for (let i = 0; i < objectRows.length; i += objectsByColumn) {
                            let endIndex = i + objectsByColumn;
                            let html = `<div>
                                ${objectRows.slice(i, endIndex).join('')}
                            </div>`;
                            columnsArray.push(html);
                        }

                        return `
                        ${tooltipTitle}
                        <div style="display: grid; grid-template-columns: ${gridTemplateColumns};">
                            ${columnsArray.join('')}
                        </div>
                        `;
                    }


                }, */
            },
            xAxis: {
                type: 'category',
                boundaryGap: false,
                axisLabel: {
                    color: '#58595b',
                    fontFamily: 'AvenirHeavy',
                },
                axisLine: {
                    lineStyle: {
                        width: 2,
                        color: '#707070'
                    },
                },
                axisTick: { show: false },
                data: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
            },
            yAxis: {
                type: 'value',
                axisLabel: {
                    color: '#d1d3d4',
                    fontFamily: 'AvenirNextRegular',
                    formatter: `${volumenValor === 0 ? '' : '$'}{value}`,
                },
                axisLine: { show: false },
                axisTick: { show: false },
                splitLine: {
                    lineStyle: {
                        color: '#000',
                        type: 'dashed',
                        opacity: 0.15,
                    },
                },
                min: 0,
            },
            series: lineGraphData,
            animation: false,
        }
        this.setState({ graphOptionsA, graphOptionsB, graphOptionsC });
    }

    restartFilter() {
        const hostname = window.location.hostname;
        let isSellIn = ['locotequila.datalabmx.com'].includes(hostname) ? 1 : 0;

        this.setState({
            calendarDate: [new Date(2022, 0, 1), new Date(2022, 11, 1)],
            calendarSPFilter: 'all',
            volumenValor: 0,
            tipoDivision: 0,
            radioValue: 0,
            isSellIn,
            regionesShown: [0, 1, 2, 3, 4, 5, 6],
            estadosShown: this.props.data.estados.map(e => e.estado),
            clientesShown: this.props.data.cadenas.map(c => c.id),
            sucursalesShown: this.props.data.sucursales.map(s => s.id),
            marcasShown: this.props.data.marcas.map(m => m.marca),
            productosShown: this.props.data.productos.map(p => p.id),
        });
    }

    obtainSKUColors(top10Array) {
        const { marcas, productos } = this.props.data;
        let productColorArray = [];

        // console.log(top10Array);
        let marcasAvailable = [...top10Array].map(v => {
            let targetMarca = '';
            if (!_.isEmpty(v.keyInfo)) targetMarca = v.keyInfo[0].marca;
            else targetMarca = productos.find(p => p.nombre === v.key).marca;
            return { marca: targetMarca };
        });
        let marcasCount = _.countBy(marcasAvailable, 'marca');
        let marcasFollowUp = {};
        let marcasColors = Object.keys(marcasCount).map(marca => {
            marcasFollowUp[marca] = 0;
            let gradienteMarca = marcas.find(m => m.marca === marca).gradiente;
            let coloresMarca = gradienteMarca.colors(marcasCount[marca]);
            return { marca, coloresMarca };
        });

        marcasAvailable.forEach(marca => {
            let colorArray = marcasColors.find(m => m.marca === marca.marca).coloresMarca;
            productColorArray.push(colorArray[marcasFollowUp[marca.marca]]);

            marcasFollowUp[marca.marca]++;
        });

        return productColorArray;
    }

    obtainSucursalesColors(top10Array) {
        const { cadenas, sucursales } = this.props.data;
        let sucursalesColorArray = [];

        let cadenasAvailable = [...top10Array].map(v => {
            let targetCadena = '';
            if (!_.isEmpty(v.keyInfo)) targetCadena = v.keyInfo[0].cadena;
            else targetCadena = sucursales.find(s => s.nombre === v.key).cadena;
            return { cadena: targetCadena };
        });
        let cadenasCount = _.countBy(cadenasAvailable, 'cadena');
        let cadenasFollowUp = {};
        let cadenasColors = Object.keys(cadenasCount).map(cadena => {
            cadenasFollowUp[cadena] = 0;
            let gradienteCadena = cadenas.find(c => c.nombre === cadena).gradiente;
            let coloresCadena = gradienteCadena.colors(cadenasCount[cadena]);
            return { cadena, coloresCadena };
        });

        cadenasAvailable.forEach(cadena => {
            let colorArray = cadenasColors.find(c => c.cadena === cadena.cadena).coloresCadena;
            sucursalesColorArray.push(colorArray[cadenasFollowUp[cadena.cadena]]);

            cadenasFollowUp[cadena.cadena]++;
        });

        return sucursalesColorArray;
    }

    render() {
        const {
            graphOptionsA, graphOptionsB, graphOptionsC, lineGraphData,
            barGraphHeight, lineGraphHeight,
            calendarDate, calendarSPFilter, volumenValor, tipoDivision, radioValue, isSellIn,
            regionesShown, estadosShown, clientesShown, sucursalesShown, marcasShown, productosShown,
            availableRegiones, availableEstados, availableClientes, availableSucursales, availableMarcas, availableProductos
        } = this.state;
        const apiData = this.props.data;

        let subtitlePartA = volumenValor === 0 ? 'Volumen' : (volumenValor === 1 ? 'Valor' : 'Ticket');
        let subtitlePartB = getCanalSubtitle(radioValue, apiData);
        let subtitlePartC = tipoDivision === 0 ? 'Cadenas' : (tipoDivision === 1 ? 'Marca' : (tipoDivision === 2 ? 'SKU' : 'Sucursal'));
        let infoSubtitle = ` (${subtitlePartA} / ${subtitlePartB} / ${subtitlePartC})`;

        if (_.isEmpty(apiData)) {
            return (
                <div className={styles["loadingContainer"]}>
                    <span className={styles["aligHelper"]}></span>
                    <object
                        className={styles["loading"]}
                        data="./images/fact_loading_sp.svg"
                        type="image/svg+xml"
                    >
                        <img
                            src="./images/fact_loading_sp.svg"
                            alt="Cargando contenido"
                        />
                    </object>
                </div>
            );
        } else {
            return (
                <div className={styles["mainContainer"]}>
                    <Header
                        history={this.props.history}
                    />
                    <div className={styles["subContainer"]}>
                        {!_.isEmpty(apiData) ? (
                            <Filtro
                                tipo={'F'}
                                calendarDate={calendarDate}
                                handleChangeCalendar={this.handleChangeCalendar}
                                handleChangeCalendarWeek={this.handleChangeCalendarWeek}
                                calendarSPFilter={calendarSPFilter}
                                handleChanceCalendarSPFilter={this.handleChanceCalendarSPFilter}
                                tipoDivision={tipoDivision}
                                handleChangeTipo={this.handleChangeTipo}
                                volumenValor={volumenValor}
                                handleChangeVolumenValor={this.handleChangeVolumenValor}
                                radioValue={radioValue}
                                handleChangeRadio={this.handleChangeRadio}
                                isSellIn={isSellIn}
                                handleChangeIsSellIn={this.handleChangeIsSellIn}
                                regionesShown={regionesShown}
                                handleChangeRegion={this.handleChangeRegion}
                                estadosShown={estadosShown}
                                handleChangeEstado={this.handleChangeEstado}
                                clientesShown={clientesShown}
                                handleChangeCliente={this.handleChangeCliente}
                                sucursalesShown={sucursalesShown}
                                handleChangeSucursal={this.handleChangeSucursal}
                                marcasShown={marcasShown}
                                handleChangeMarca={this.handleChangeMarca}
                                productosShown={productosShown}
                                handleChangeProducto={this.handleChangeProducto}
                                // ====================================================
                                availableRegiones={availableRegiones}
                                availableEstados={availableEstados}
                                availableClientes={availableClientes}
                                availableSucursales={availableSucursales}
                                availableMarcas={availableMarcas}
                                availableProductos={availableProductos}
                                // ====================================================
                                apiData={apiData}
                                restartFilter={this.restartFilter}
                            />
                        ) : ''}
                        <div className={styles["infoContainer"]}>
                            {lineGraphData.length === 0 ? (
                                <div className={styles["noDataContainer"]}>No existen datos correspondientes a este filtrado</div>
                            ) : ''}
                            <div className={styles["alcanceContainer"]}>
                                <Alcance apiData={apiData} />
                                <FiltrosAplicados
                                    apiData={apiData}
                                    tipoDivision={tipoDivision} volumenValor={volumenValor} radioValue={radioValue}
                                    regionesShown={regionesShown} estadosShown={estadosShown}
                                    clientesShown={clientesShown} marcasShown={marcasShown} productosShown={productosShown}
                                />
                            </div>
                            <div className={styles["infoTitle"]}>
                                Ranking de {tipoDivision === 0 ? 'Cadenas' : (tipoDivision === 1 ? 'Marcas' : (tipoDivision === 2 ? 'SKU' : 'Sucursales'))}
                                {/* <span className={styles["infoSubtitle"]}>{infoSubtitle}</span> */}
                                {/* <PseudoBreadcrumb
                                    apiData={apiData} tipoDivision={tipoDivision}
                                    regionesShown={regionesShown} clientesShown={clientesShown}
                                    marcasShown={marcasShown} productosShown={productosShown}
                                /> */}
                                <IndicadorTotales tipo={'A'} infoVolumen={graphOptionsB} infoValor={graphOptionsA} />
                                <div className={styles["ytdContainer"]}>{getYTDText(calendarDate, true)}</div>
                            </div>
                            <div className={styles["chartContainerA"]}>
                                <ReactECharts
                                    option={graphOptionsB}
                                    style={{ height: `${barGraphHeight}px` }}
                                />
                                <ReactECharts
                                    option={graphOptionsA}
                                    style={{ height: `${barGraphHeight}px` }}
                                />
                            </div>
                            {tipoDivision === 3 ? (
                                <div className={styles["sucursalesInfoContainer"]}>
                                    <div className={styles["sucursalesGraphTitle"]}>{`${volumenValor === 0 ? 'Volumen' : 'Valor'} Mensual`}</div>
                                    <TooltipRanking apiData={apiData} clientesShown={clientesShown} lineGraphData={lineGraphData} volumenValor={volumenValor} />
                                </div>
                            ) : ''}
                            <div className={styles["chartContainerB"]}>
                                <ReactECharts
                                    option={graphOptionsC}
                                    style={{ height: `${lineGraphHeight}px` }}
                                    onEvents={{ selectchanged: () => { console.log(''); } }} // Añadir esto arregló el problema de encimados, y no sé porqué
                                />
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

function mapStateToProps(state) {
    return {
        data: state.data,
    };
}

export default connect(mapStateToProps, {
    dataAction,
    filterDataAction,
})(Vista6);