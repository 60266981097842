import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";

import rootReducer from "./reducers";
import App from "./App";

import "./index.css";
import * as serviceWorker from "./serviceWorker";

import { AUTHENTICATED, UNAUTHENTICATED } from './actions';

const store = createStore(rootReducer, applyMiddleware(thunk));


function recoverLogInAction() {
  // console.log('recover logIn')
  let token = localStorage.getItem('token');
  // console.log(token);
  if (token) {
    store.dispatch({
      type: AUTHENTICATED
    });
  } else {
    store.dispatch({
      type: UNAUTHENTICATED
    });
  }
}
recoverLogInAction();

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();