import React, { Component } from "react";
import { connect } from "react-redux";
import _ from 'lodash';

import Header from "../../components/header/Header";
import styles from "./Home.module.scss";
import { dataAction, filterDataAction } from "../../actions";

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fechaString: '',
            logoURL: '',
            gifSufix: '',
        };
    }

    async componentDidMount() {
        const diasSemana = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
        const meses = ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'];

        let today = new Date();
        let weekday = today.getDay();
        let day = today.getDate() + '';
        day = day.length === 1 ? `0${day}` : day;
        let month = today.getMonth();
        let year = today.getFullYear();

        let fechaString = `${diasSemana[weekday]} ${day} de ${meses[month]} de ${year}`;

        // =========================================================================================

        let hostname = window.location.hostname;
        let logoURL, gifSufix;

        const root = document.documentElement;

        switch (hostname) {
            case '3.12.19.179':
                logoURL = 'monte_xanic_logo.svg';
                gifSufix = '_MonteXanic';
                root.style.setProperty('--homeLogoFilter', 'brightness(1)');
                root.style.setProperty('--homeMainColor', '#363A43');
                root.style.setProperty('--homeImageBorder', '1px solid rgba(54, 58, 67, 0.4)');
                root.style.setProperty('--homeLogoWidth', '15%');
                break;
            case 'demo.datalabmx.com':
                logoURL = 'datalabmx.svg';
                gifSufix = '_DataLabMX';
                root.style.setProperty('--homeLogoFilter', 'brightness(1)');
                root.style.setProperty('--homeMainColor', '#575756');
                root.style.setProperty('--homeImageBorder', '1px solid rgba(87, 87, 86, 0.4)');
                root.style.setProperty('--homeLogoWidth', '15%');
                break;
            case '3.17.121.27':
                logoURL = 'monte_xanic_logo.svg';
                gifSufix = '_MonteXanic';
                root.style.setProperty('--homeLogoFilter', 'brightness(1)');
                root.style.setProperty('--homeMainColor', '#363A43');
                root.style.setProperty('--homeImageBorder', '1px solid rgba(54, 58, 67, 0.4)');
                root.style.setProperty('--homeLogoWidth', '15%');
                break;
            case 'corporacionrica.datalabmx.com':
                logoURL = 'corporica_logo_gris.svg';
                gifSufix = '_CorpoRica';
                root.style.setProperty('--homeLogoFilter', 'brightness(1)');
                root.style.setProperty('--homeMainColor', '#ed1b2f');
                root.style.setProperty('--homeImageBorder', '1px solid rgba(237, 27, 48, 0.4)');
                root.style.setProperty('--homeLogoWidth', '15%');
                break;
            case 'locotequila.datalabmx.com':
                logoURL = 'locotequila_logo.png';
                gifSufix = '_LocoTequila';
                root.style.setProperty('--homeLogoFilter', 'brightness(1)');
                root.style.setProperty('--homeMainColor', '#772835');
                root.style.setProperty('--homeImageBorder', '1px solid rgba(119, 40, 53, 0.4)');
                root.style.setProperty('--homeLogoWidth', '15%');
                break;
            case 'cbrands.datalabmx.com':
                logoURL = 'cbrands_logo_gris.svg';
                gifSufix = '_CBrands';
                root.style.setProperty('--homeLogoFilter', 'brightness(1)');
                root.style.setProperty('--homeMainColor', '#001E5B');
                root.style.setProperty('--homeImageBorder', '1px solid rgba(0, 30, 91, 0.4)');
                root.style.setProperty('--homeLogoWidth', '40%');
                break;
            case 'alianza.datalabmx.com':
                logoURL = 'alianza_logo.svg';
                gifSufix = '_Alianza';
                root.style.setProperty('--homeLogoFilter', 'brightness(0) invert(10%) sepia(15%) saturate(2458%) hue-rotate(245deg) brightness(92%) contrast(102%)');
                root.style.setProperty('--homeMainColor', '#68476c');
                root.style.setProperty('--homeImageBorder', '1px solid rgba(54, 58, 67, 0.4)');
                root.style.setProperty('--homeLogoWidth', '15%');
                break;
            default:
                logoURL = 'alianza_logo.svg';
                gifSufix = '_Alianza';
                root.style.setProperty('--homeLogoFilter', 'brightness(0) invert(10%) sepia(15%) saturate(2458%) hue-rotate(245deg) brightness(92%) contrast(102%)');
                root.style.setProperty('--homeMainColor', '#68476c');
                root.style.setProperty('--homeImageBorder', '1px solid rgba(54, 58, 67, 0.4)');
                root.style.setProperty('--homeLogoWidth', '15%');
        }

        // =========================================================================================

        this.setState({ fechaString, logoURL, gifSufix });

        // if (_.isEmpty(this.props.data)) await this.props.dataAction();
        /* console.log('========== INFORMACIÓN EN HOME ==========');
        console.log(this.props.data); */
    }

    reroute(option) {
        this.props.history.push(`/${option}`);
    }

    render() {
        const { /* logoURL, */ gifSufix } = this.state;

        // if (_.isEmpty(this.props.data)) {
        if (false) {
            return (
                <div className={styles["loadingContainer"]}>
                    <span className={styles["aligHelper"]}></span>
                    <object
                        className={styles["loading"]}
                        data="./images/fact_loading_sp.svg"
                        type="image/svg+xml"
                    >
                        <img
                            src="./images/fact_loading_sp.svg"
                            alt="Cargando contenido"
                        />
                    </object>
                </div>
            );
        } else {
            return (
                <div className={styles["mainContainer"]}>
                    <Header
                        history={this.props.history}
                    />
                    <div className={styles["panelContainer"]}>
                        {/* <div className={styles["fechaContainer"]}>{fechaString}</div> */}
                        {/* <img src={`./images/${logoURL}`} alt="Logotipo" className={styles["panelLogo"]}></img> */}
                        <div className={styles["optionsMainContainer"]}>
                            <div className={styles["topRow"]}>
                                <div className={styles["optionContainer"]} onClick={() => { this.reroute('sellout') }}>
                                    <div className={styles["optionImageContainer"]}>
                                        <img src={`./images/gif_panel/SellOut${gifSufix}.gif`} alt='GIF de Opción' className={styles["optionImage"]}></img>
                                    </div>
                                    <div className={styles["optionTitle"]}>Sell Out</div>
                                    <div className={styles["optionButton"]}>{'VER MÁS >'}</div>
                                </div>
                                <div className={styles["optionContainer"]} onClick={() => { this.reroute('treemap') }}>
                                    <div className={styles["optionImageContainer"]}>
                                        <img src={`./images/gif_panel/Treemap${gifSufix}.gif`} alt='GIF de Opción' className={styles["optionImage"]}></img>
                                    </div>
                                    <div className={styles["optionTitle"]}>Treemap</div>
                                    <div className={styles["optionButton"]}>{'VER MÁS >'}</div>
                                </div>
                                <div className={styles["optionContainer"]} onClick={() => { this.reroute('top10') }}>
                                    <div className={styles["optionImageContainer"]}>
                                        <img src={`./images/gif_panel/Top10${gifSufix}.gif`} alt='GIF de Opción' className={styles["optionImage"]}></img>
                                    </div>
                                    <div className={styles["optionTitle"]}>Top 10</div>
                                    <div className={styles["optionButton"]}>{'VER MÁS >'}</div>
                                </div>
                            </div>
                            <div className={styles["bottomRow"]}>
                                {/* <div className={styles["optionContainer"]} onClick={() => { this.reroute('mapa') }}>
                                    <div className={styles["optionImageContainer"]}>
                                        <img src={`./images/gif_panel/Mapa${gifSufix}.gif`} alt='GIF de Opción' className={styles["optionImage"]}></img>
                                    </div>
                                    <div className={styles["optionTitle"]}>Mapa</div>
                                    <div className={styles["optionButton"]}>{'VER MÁS >'}</div>
                                </div> */}
                                <div className={styles["optionContainer"]} onClick={() => { this.reroute('comparativo') }}>
                                    <div className={styles["optionImageContainer"]}>
                                        <img src={`./images/gif_panel/Comparativo${gifSufix}.gif`} alt='GIF de Opción' className={styles["optionImage"]}></img>
                                    </div>
                                    <div className={styles["optionTitle"]}>Comparativo</div>
                                    <div className={styles["optionButton"]}>{'VER MÁS >'}</div>
                                </div>
                                <div className={styles["optionContainer"]} onClick={() => { this.reroute('ranking') }}>
                                    <div className={styles["optionImageContainer"]}>
                                        <img src={`./images/gif_panel/Ranking${gifSufix}.gif`} alt='GIF de Opción' className={styles["optionImage"]}></img>
                                    </div>
                                    <div className={styles["optionTitle"]}>Ranking</div>
                                    <div className={styles["optionButton"]}>{'VER MÁS >'}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

function mapStateToProps(state) {
    return {
        data: state.data,
    };
}

export default connect(mapStateToProps, {
    dataAction,
    filterDataAction,
})(Home);