import React, { Component } from "react";
import { connect } from "react-redux";
import ReactECharts from 'echarts-for-react';
import _ from 'lodash';

import Header from "../header/Header";
import Filtro from "../filtro/Filtro";
import Alcance from "../alcance/Alcance";
import FiltrosAplicados from "../alcance/FiltrosAplicados";
// import PseudoBreadcrumb from "../pseudobreadcrumb/PseudoBreadcrumb";
// import FiltroTreemap from "../filtrotreemap/FiltroTreemap";
import styles from "./Vista4.module.scss";

import { formatNumberWithCommas, getCanalSubtitle, getYTDText } from "../../scripts/helpers";
import { dataAction, filterDataAction } from "../../actions";
import { startOfMonth, endOfMonth, endOfISOWeek } from "date-fns";

class Vista4 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            graphData: [[]],
            legendValues: [],
            graphSeries: [],
            pieChartData: [],
            trueTotal: 0,
            // ================================================
            graphOptions: {},
            graphColor: '',
            // ================================================
            calendarDate: [new Date(2022, 0, 1), new Date(2022, 11, 1)],
            calendarSPFilter: 'all',
            tipoDivision: 0,
            volumenValor: 0,
            radioValue: 0,
            isSellIn: 0,
            regionesShown: [],
            estadosShown: [],
            clientesShown: [],
            sucursalesShown: [],
            marcasShown: [],
            productosShown: [],
            // ================================================
            availableRegiones: [],
            availableEstados: [],
            availableClientes: [],
            availableSucursales: [],
            availableMarcas: [],
            availableProductos: [],
        };

        this.handleChangeCalendar = this.handleChangeCalendar.bind(this);
        this.handleChangeCalendarWeek = this.handleChangeCalendarWeek.bind(this);
        this.handleChanceCalendarSPFilter = this.handleChanceCalendarSPFilter.bind(this);
        this.handleChangeTipo = this.handleChangeTipo.bind(this);
        this.handleChangeVolumenValor = this.handleChangeVolumenValor.bind(this);
        this.handleChangeIsSellIn = this.handleChangeIsSellIn.bind(this);
        this.handleChangeRadio = this.handleChangeRadio.bind(this);
        this.handleChangeRegion = this.handleChangeRegion.bind(this);
        this.handleChangeEstado = this.handleChangeEstado.bind(this);
        this.handleChangeCliente = this.handleChangeCliente.bind(this);
        this.handleChangeSucursal = this.handleChangeSucursal.bind(this);
        this.handleChangeMarca = this.handleChangeMarca.bind(this);
        this.handleChangeProducto = this.handleChangeProducto.bind(this);
        this.restartFilter = this.restartFilter.bind(this);
    }

    handleChangeCalendar(calendarDate) { this.setState({ calendarDate }); }
    handleChangeCalendarWeek(weekNumber, date, event) {
        let endOfWeek = endOfISOWeek(date);
        let calendarDate = [date, endOfWeek];

        this.setState({ calendarDate });
    }
    handleChanceCalendarSPFilter(newCalendarSPFilter) {
        const { calendarSPFilter, calendarDate } = this.state;

        let newCalendarDate = calendarDate;
        if (['daily', 'weekly'].includes(calendarSPFilter) && newCalendarSPFilter === 'all') {
            newCalendarDate = [startOfMonth(calendarDate[0]), endOfMonth(calendarDate[1])];
        }

        this.setState({ calendarSPFilter: newCalendarSPFilter, calendarDate: newCalendarDate });
    }
    handleChangeTipo(tipoDivision) { this.setState({ tipoDivision }); }
    handleChangeVolumenValor(volumenValor) { this.setState({ volumenValor }) }
    handleChangeIsSellIn(isSellIn) { this.setState({ isSellIn }) }
    handleChangeRadio(event) {
        let radioValue = parseInt(event.target.value);
        this.setState({ radioValue });
    }
    handleChangeRegion(event) {
        const { regionesShown } = this.state;
        let region = event.target.value;
        let newRegionesShown = [];
        let newSucursalesShown = [];
        let newEstadosShown = [];
        let newClientesShown = [];

        if (region === 'all') {
            if (regionesShown.length !== 7) {
                newRegionesShown = [0, 1, 2, 3, 4, 5, 6];
                newSucursalesShown = this.props.data.sucursales.map(s => s.id);
                newEstadosShown = this.props.data.estados.map(e => e.estado);
                newClientesShown = this.props.data.cadenas.map(c => c.id);
            }
        } else {
            region = parseInt(region);
            if (regionesShown.includes(region)) {
                newRegionesShown = [...regionesShown].filter(r => r !== region);
            } else {
                newRegionesShown = [...regionesShown];
                newRegionesShown.push(region);
            }

            newSucursalesShown = _.uniq([...this.props.data.sucursales].filter(s => newRegionesShown.includes(parseInt(s.region))).map(s => s.id));
            newEstadosShown = this.props.data.estados.filter(e => newRegionesShown.includes(parseInt(e.region))).map(e => e.estado);
            newClientesShown = _.uniq([...this.props.data.sucursales].filter(s => newSucursalesShown.includes(s.id)).map(s => s.idCadena));
        }

        this.setState({ regionesShown: newRegionesShown, sucursalesShown: newSucursalesShown, estadosShown: newEstadosShown, clientesShown: newClientesShown });
    }
    handleChangeEstado(event) {
        const { estados, sucursales } = this.props.data;
        const { estadosShown } = this.state;
        let estado = event.target.value;
        let newEstadosShown = [];
        let newSucursalesShown = [];
        let newRegionesShown = [];
        let newClientesShown = [];

        if (estado === 'all') {
            if (estadosShown.length !== 33) {
                newEstadosShown = estados.map(e => e.estado);
                newSucursalesShown = sucursales.map(s => s.id);
                newRegionesShown = [0, 1, 2, 3, 4, 5, 6];
                newClientesShown = this.props.data.cadenas.map(c => c.id);
            }
        } else {
            if (estadosShown.includes(estado)) {
                newEstadosShown = [...estadosShown].filter(e => e !== estado);
            } else {
                newEstadosShown = [...estadosShown];
                newEstadosShown.push(estado);
            }

            newSucursalesShown = _.uniq([...sucursales].filter(s => newEstadosShown.includes(s.estado)).map(s => s.id));
            newRegionesShown = _.uniq([...estados].filter(e => newEstadosShown.includes(e.estado)).map(e => parseInt(e.region)));
            newClientesShown = _.uniq([...sucursales].filter(s => newSucursalesShown.includes(s.id)).map(s => s.idCadena));
        }

        this.setState({ estadosShown: newEstadosShown, sucursalesShown: newSucursalesShown, regionesShown: newRegionesShown, clientesShown: newClientesShown });
    }
    handleChangeSucursal(event) {
        const { sucursalesShown, availableSucursales } = this.state;
        let idSucursal = event.target.value;
        let newSucursalesShown = [];
        let newRegionesShown = [];
        let newEstadosShown = [];
        let newClientesShown = [];

        if (idSucursal === 'all') {
            if (sucursalesShown.length !== this.props.data.sucursales.length) {
                newSucursalesShown = this.props.data.sucursales.map(s => s.id);
                newRegionesShown = [0, 1, 2, 3, 4, 5, 6];
                newEstadosShown = this.props.data.estados.map(e => e.estado);
                newClientesShown = this.props.data.cadenas.map(c => c.id);
            }
        } else if (idSucursal.includes('all')) {
            let idCadena = idSucursal.split('_')[1];

            let allSucursalesInRegion = [...this.props.data.sucursales].filter(s => s.idCadena === idCadena).map(s => s.id);
            if (!allSucursalesInRegion.some(s => sucursalesShown.includes(s))) {
                newSucursalesShown = [...sucursalesShown].concat(allSucursalesInRegion);
            } else {
                newSucursalesShown = [...sucursalesShown].filter(s => !allSucursalesInRegion.includes(s));
            }

            let tmpObject = [...this.props.data.sucursales].filter(s => newSucursalesShown.includes(s.id));
            newRegionesShown = _.uniq(tmpObject.map(s => parseInt(s.region)));
            newEstadosShown = _.uniq(tmpObject.map(s => s.estado));
            newClientesShown = _.uniq(tmpObject.map(s => s.idCadena));
        } else {
            if (sucursalesShown.includes(idSucursal)) {
                newSucursalesShown = [...sucursalesShown].filter(s => s !== idSucursal);
            } else {
                newSucursalesShown = [...sucursalesShown];
                newSucursalesShown.push(idSucursal);
            }
            newSucursalesShown = newSucursalesShown.filter(s => availableSucursales.includes(s));

            let tmpObject = [...this.props.data.sucursales].filter(s => newSucursalesShown.includes(s.id));
            newRegionesShown = _.uniq(tmpObject.map(s => parseInt(s.region)));
            newEstadosShown = _.uniq(tmpObject.map(s => s.estado));
            newClientesShown = _.uniq(tmpObject.map(s => s.idCadena));
        }

        this.setState({ sucursalesShown: newSucursalesShown, regionesShown: newRegionesShown, estadosShown: newEstadosShown, clientesShown: newClientesShown });
    }
    handleChangeCliente(event) {
        const { clientesShown } = this.state;
        let idCadena = event.target.value;
        let newClientesShown = [];

        if (idCadena === 'all') {
            if (clientesShown.length !== this.props.data.cadenas.length) {
                newClientesShown = this.props.data.cadenas.map(c => c.id);
            }
        } else if (idCadena.includes('all')) {
            let letra = idCadena.split('_')[1];

            let allClientesInLetter = [];
            if (letra === '#') allClientesInLetter = [...this.props.data.cadenas].filter(c => /^\d/.test(c.nombre)).map(c => c.id);
            else allClientesInLetter = [...this.props.data.cadenas].filter(c => c.nombre.toUpperCase().startsWith(letra)).map(c => c.id);

            if (!allClientesInLetter.some(p => clientesShown.includes(p))) {
                newClientesShown = [...clientesShown].concat(allClientesInLetter);
            } else {
                newClientesShown = [...clientesShown].filter(p => !allClientesInLetter.includes(p));
            }
        } else {
            if (clientesShown.includes(idCadena)) {
                newClientesShown = [...clientesShown].filter(c => c !== idCadena);
            } else {
                newClientesShown = [...clientesShown];
                newClientesShown.push(idCadena);
            }
        }

        this.setState({ clientesShown: newClientesShown });
    }
    handleChangeMarca(event) {
        const { marcasShown } = this.state;
        let marca = event.target.value;
        let newMarcasShown = [];
        let newproductosShown = [];

        if (marca === 'all') {
            if (marcasShown.length !== this.props.data.marcas.length) {
                newMarcasShown = this.props.data.marcas.map(m => m.marca);
                newproductosShown = this.props.data.productos.map(c => c.id);
            }
        } else {
            if (marcasShown.includes(marca)) {
                newMarcasShown = [...marcasShown].filter(m => m !== marca);
            } else {
                newMarcasShown = [...marcasShown];
                newMarcasShown.push(marca);
            }
            newproductosShown = [...this.props.data.productos].filter(p => newMarcasShown.includes(p.marca)).map(p => p.id);
        }

        this.setState({ marcasShown: newMarcasShown, productosShown: newproductosShown });
    }
    handleChangeProducto(event) {
        const { productosShown, availableProductos } = this.state;
        let idProducto = event.target.value;
        let newproductosShown = [];
        let newMarcasShown = [];

        if (idProducto === 'all') {
            if (productosShown.length !== this.props.data.productos.length) {
                newproductosShown = this.props.data.productos.map(c => c.id);
                newMarcasShown = this.props.data.marcas.map(m => m.marca);
            }
        } else if (idProducto.includes('all')) {
            let marca = idProducto.split('_')[1];
            let allProductsInMarca = [...this.props.data.productos].filter(p => p.marca === marca).map(p => p.id);

            if (!allProductsInMarca.some(p => productosShown.includes(p))) {
                newproductosShown = [...productosShown].concat(allProductsInMarca);
            } else {
                newproductosShown = [...productosShown].filter(p => !allProductsInMarca.includes(p));
            }

            newMarcasShown = _.uniq([...this.props.data.productos].filter(p => newproductosShown.includes(p.id)).map(p => p.marca));

        } else {
            if (productosShown.includes(idProducto)) {
                newproductosShown = [...productosShown].filter(c => c !== idProducto);
            } else {
                newproductosShown = [...productosShown];
                newproductosShown.push(idProducto);
            }
            newproductosShown = newproductosShown.filter(p => availableProductos.includes(p));

            newMarcasShown = _.uniq([...this.props.data.productos].filter(p => newproductosShown.includes(p.id)).map(p => p.marca));
        }

        this.setState({ productosShown: newproductosShown, marcasShown: newMarcasShown });
    }

    async componentDidMount() {
        const hostname = window.location.hostname;
        let graphColor;
        const root = document.documentElement;
        root.style.setProperty('--calendarSelectedRangeColor', '#CCCCCC');

        switch (hostname) {
            case '3.12.19.179':
                graphColor = '#515662';
                break;
            case 'demo.datalabmx.com':
                graphColor = '#575756';
                break;
            case '3.17.121.27':
                graphColor = '#515662';
                break;
            case 'corporacionrica.datalabmx.com':
                graphColor = '#333333';
                break;
            case 'locotequila.datalabmx.com':
                graphColor = '#772835';
                break;
            case 'cbrands.datalabmx.com':
                graphColor = '#001E5B';
                break;
            case 'alianza.datalabmx.com':
                graphColor = '#68476C';
                break;
            default:
                graphColor = '#68476C';
        }

        // ============================================================

        if (_.isEmpty(this.props.data)) await this.props.dataAction();
        /* console.log('========== INFORMACIÓN EN SELL OUT ==========');
        console.log(this.props.data); */

        // console.log(this.props.data.ventas.length);

        let regionesShown = [0, 1, 2, 3, 4, 5, 6];
        let estadosShown = this.props.data.estados.map(e => e.estado);
        let clientesShown = this.props.data.cadenas.map(c => c.id);
        let sucursalesShown = this.props.data.sucursales.map(s => s.id);
        let marcasShown = this.props.data.marcas.map(m => m.marca);
        let productosShown = this.props.data.productos.map(p => p.id);

        let isSellIn = ['locotequila.datalabmx.com'].includes(hostname) ? 1 : 0;

        this.setState({ regionesShown, estadosShown, clientesShown, marcasShown, sucursalesShown, productosShown, isSellIn, graphColor }, () => { this.buildGraphData(); });
    }

    componentDidUpdate(prevProps, prevState) {
        /* console.log('Antes', prevState)
        console.log('Ahora', this.state); */

        if (
            (prevState.volumenValor !== this.state.volumenValor) || (!_.isEqual(prevState.calendarDate, this.state.calendarDate)) || (prevState.calendarSPFilter !== this.state.calendarSPFilter) || (prevState.tipoDivision !== this.state.tipoDivision) || (prevState.radioValue !== this.state.radioValue) || (prevState.isSellIn !== this.state.isSellIn) ||
            (!_.isEqual(prevState.regionesShown, this.state.regionesShown)) || (!_.isEqual(prevState.estadosShown, this.state.estadosShown)) || (!_.isEqual(prevState.clientesShown, this.state.clientesShown)) || (!_.isEqual(prevState.marcasShown, this.state.marcasShown)) ||
            (!_.isEqual(prevState.productosShown, this.state.productosShown)) || (!_.isEqual(prevState.sucursalesShown, this.state.sucursalesShown))
        ) {
            this.buildGraphData();
        } else if (!_.isEqual(prevState.graphSeries, this.state.graphSeries)) {
            this.updateGraphOptions();
        }
    }

    buildGraphData() {
        const { calendarDate, calendarSPFilter, tipoDivision, volumenValor, radioValue, isSellIn, regionesShown, estadosShown, clientesShown, marcasShown, sucursalesShown, productosShown, graphColor } = this.state;
        const { ventas, cadenas, marcas } = this.props.data;
        const hostname = window.location.hostname;

        let mainGroupBy = tipoDivision === 0 ? 'cadena' : (tipoDivision === 1 ? 'marca' : 'nombreProducto');

        let ventasData = [...ventas];
        if (calendarSPFilter === 'daily') {
            ventasData = ventasData.filter(v => ['1', '2', '3', '5', '13', '14'].includes(v.idCadena));
        } else if (calendarSPFilter === 'weekly') {
            ventasData = ventasData.filter(v => ['1', '2', '3', '4', '5', '13', '14'].includes(v.idCadena));
        }
        ventasData = ventasData.filter(v => v.fechaFormato >= calendarDate[0] && v.fechaFormato <= calendarDate[1]);
        ventasData = ventasData.filter(v => v.isSellIn === isSellIn);

        let availableRegiones = _.uniq(ventasData.map(v => v.region));
        let availableEstados = _.uniq(ventasData.map(v => v.estado));
        let availableClientes = _.uniq(ventasData.map(v => v.idCadena));
        let availableSucursales = _.uniq(ventasData.map(v => v.idSucursal));
        let availableMarcas = _.uniq(ventasData.map(v => v.marca));
        let availableProductos = _.uniq(ventasData.map(v => v.idProducto));

        ventasData = ventasData.filter(v => regionesShown.includes(v.region));
        ventasData = ventasData.filter(v => estadosShown.includes(v.estado));
        ventasData = ventasData.filter(v => clientesShown.includes(v.idCadena));
        ventasData = ventasData.filter(v => sucursalesShown.includes(v.idSucursal));
        ventasData = ventasData.filter(v => marcasShown.includes(v.marca));
        ventasData = ventasData.filter(v => productosShown.includes(v.idProducto));

        if (['locotequila.datalabmx.com'].includes(hostname)) {
            if (radioValue !== 0) ventasData = ventasData.filter(v => v.idCanal === radioValue);
        } else {
            let ventasPorTelefono = ['23', '149', '3484', '6046'];
            let ventasPorInternet = ['22', '148', '190', '693', '3478', '4928', '5262', '4856', '4990'];
            let ventasConjuntas = ventasPorTelefono.concat(ventasPorInternet);

            let cadenasMayoristas = ['5', '6', '9', '10', '11', '12', '13', '14', '16', '19', '20'];

            switch (radioValue) {
                case 0:
                    // Todos
                    break;
                case 1:
                    // Mayoreo
                    ventasData = ventasData.filter(v => !ventasConjuntas.includes(v.idSucursal) && cadenasMayoristas.includes(v.idCadena));
                    break;
                case 2:
                    // Menudeo
                    ventasData = ventasData.filter(v => !ventasConjuntas.includes(v.idSucursal) && !cadenasMayoristas.includes(v.idCadena));
                    break;
                case 3:
                    // Telemarketing
                    ventasData = ventasData.filter(v => ventasPorTelefono.includes(v.idSucursal));
                    break;
                case 4:
                    // E-Commerce
                    ventasData = ventasData.filter(v => ventasPorInternet.includes(v.idSucursal));
                    break;
                default:
                    console.log('Opción no encontrada');
            }
        }

        let valuesToSum = volumenValor === 0 ? 'ventas_unidades' : 'ventas_mxn';

        ventasData = _.groupBy(ventasData, mainGroupBy);
        ventasData = Object.keys(ventasData).map(key => {
            let keyInfo = ventasData[key];
            let totalValue = _.sumBy(keyInfo, valuesToSum);
            let infoByMonths = _.groupBy(keyInfo, 'mesVenta');

            return { 'key': key, infoByMonths, keyInfo, totalValue }
        });

        let productColorArray = [];
        if (
            (tipoDivision === 2 && ventasData.length > 10 && ['demo.datalabmx.com', '3.17.121.27', '3.12.19.179'].includes(hostname)) ||
            (tipoDivision === 0 && ventasData.length > 10 && ['locotequila.datalabmx.com'].includes(hostname))
        ) {
            productColorArray = ['#A0A0A0'];

            ventasData = _.orderBy(ventasData, 'totalValue', 'desc');
            let top10Productos = ventasData.slice(0, 10);
            let otrosProductos = ventasData.slice(10);
            let otrosAllArrays = [];
            otrosProductos.forEach(o => {
                otrosAllArrays = otrosAllArrays.concat(o.keyInfo)
            });
            let otrosInfoByMonths = _.groupBy(otrosAllArrays, 'mesVenta');

            let otrosObject = {
                'key': 'Otros',
                'infoByMonths': otrosInfoByMonths,
            };

            ventasData = top10Productos;
            ventasData = _.orderBy(ventasData, 'totalValue'/* , 'desc' */);
            ventasData.unshift(otrosObject);

        } else ventasData = _.orderBy(ventasData, 'totalValue'/* , 'desc' */);
        // console.log('¿Qué forma tienes aquí?', ventasData);

        if (tipoDivision === 2) {
            let marcasAvailable = [...ventasData].filter(v => v.key !== 'Otros').map(v => { return { marca: v.keyInfo[0].marca }; });

            let marcasCount = _.countBy(marcasAvailable, 'marca');

            let marcasFollowUp = {};
            let marcasColors = Object.keys(marcasCount).map(marca => {
                marcasFollowUp[marca] = 0;
                let gradienteMarca = marcas.find(m => m.marca === marca).gradiente;
                let coloresMarca = gradienteMarca.colors(marcasCount[marca]).reverse();
                return { marca, coloresMarca };
            });

            marcasAvailable.forEach(marca => {
                let colorArray = marcasColors.find(m => m.marca === marca.marca).coloresMarca;
                productColorArray.push(colorArray[marcasFollowUp[marca.marca]]);

                marcasFollowUp[marca.marca]++;
            });
        }

        let graphData = [['Compañía', 'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']];
        let graphSeries = [];
        let pieChartData = [];
        ventasData.forEach((v, i) => {
            // console.log(`============= ${v.key} =============`);
            let arrayData = [v.key];
            let sumsArray = [];
            for (let i = 0; i <= 11; i++) {
                let monthValue = v.infoByMonths[i];
                if (!monthValue) {
                    arrayData.push(0);
                    continue;
                }
                let suma = _.sumBy(monthValue, valuesToSum);
                arrayData.push(suma.toFixed(suma > 1 ? 0 : 2));
                sumsArray.push(suma);
            }
            graphData.push(arrayData);

            let graphSeriesObject = { name: v.key, type: 'bar', seriesLayoutBy: 'row', stack: 'total' };
            let pieChartDataObject = { name: v.key, value: _.sum(sumsArray) };
            if (['demo.datalabmx.com', '3.17.121.27', '3.12.19.179'].includes(hostname) && ['cadena', 'marca', 'nombreProducto'].includes(mainGroupBy)) {
                let colorCadena = '';
                if (mainGroupBy === 'cadena') colorCadena = cadenas.find(c => c.nombre === v.key).color;
                else if (mainGroupBy === 'marca') colorCadena = marcas.find(c => c.marca === v.key).colorMarca;
                else if (mainGroupBy === 'nombreProducto') colorCadena = productColorArray[i];

                graphSeriesObject['color'] = colorCadena;
                pieChartDataObject['itemStyle'] = { color: colorCadena };
            }

            graphSeries.push(graphSeriesObject);
            pieChartData.push(pieChartDataObject);
        });

        let legendValues = graphData.map(d => d[0]).reverse();
        legendValues.pop();

        graphSeries.push({
            type: 'pie',
            radius: ["30%", "45%"],
            center: ["50%", "70%"],
            clockwise: false,
            label: {
                formatter: (params) => {
                    let labelFinal = [
                        /* '{cuadro|}', */
                        `{titulo|${params.name} /}`,
                        `{porcentaje|${params.percent}%}`
                    ].join('');
                    return labelFinal;
                },
                rich: {
                    /* cuadro: {
                        width: 16, height: 16,
                        backgroundColor: 'red',
                    }, */
                    titulo: {
                        color: '#939598',
                        fontSize: 11,
                        fontFamily: "AvenirMedium",
                        padding: [0, 8, 0, 0],
                    },
                    porcentaje: {
                        color: '#58595b',
                        fontSize: 14,
                        fontFamily: "AvenirHeavy",
                    },
                },
            },
            tooltip: {
                trigger: "item",
                formatter: (d) => {
                    return `
                    <div style="display: flex; flex-direction: column">
                        <div>
                            <div style="display: inline-block; width: 1.85vh; height: 1.85vh; background-color: ${d.color};"></div>
                            <span style="font-size: 1.8vh; font-family: AvenirHeavy; color: #58595b;">${d.name}: </span>
                        </div>
                        <span style="font-size: 2.29vh; font-family: AvenirHeavy; margin-left: 1.5vw; color: #58595b;">
                            ${volumenValor === 0 ? "" : "$"}${formatNumberWithCommas(d.value.toFixed(d.value > 1 ? 0 : 2))}
                            <span style="font-size: 1.04vh; font-family: AvenirMedium; color: #939598;"> ${volumenValor === 0 ? " Cajas (9L)" : " "}</span>
                        </span>
                        <span style="font-size: 1.46vh; font-family: AvenirMedium; margin-left: 1.5vw; color: #939598;">
                            ${d.percent}%
                        </span>
                    </div>`;
                },
            },
            data: pieChartData,
        });

        graphSeries.push({
            type: 'line',
            stack: 'total',
            symbol: 'circle',
            symbolSize: 8,
            color: '#939598',
            lineStyle: { width: 1 },
            // color: graphColor,
            // smooth: true,
            data: [
                ['Enero', 0], ['Febrero', 0], ['Marzo', 0],
                ['Abril', 0], ['Mayo', 0], ['Junio', 0],
                ['Julio', 0], ['Agosto', 0], ['Septiembre', 0],
                ['Octubre', 0], ['Noviembre', 0], ['Diciembre', 0],
            ],
        });

        let trueTotal = _.sumBy(pieChartData, 'value');

        this.setState({
            graphData, legendValues, graphSeries,
            trueTotal, pieChartData,
            availableRegiones, availableEstados, availableClientes, availableMarcas, availableProductos, availableSucursales,
        });
    }

    updateGraphOptions() {
        const {
            graphData, legendValues, graphSeries, trueTotal,
            calendarDate, tipoDivision, volumenValor,
        } = this.state;
        const screenWidth = window.innerWidth;

        const graphOptions = {
            grid: {
                top: '12%',
                bottom: '62%',
                right: '3%',
                left: '7%',
            },
            title: {
                text: "Ventas Totales por mes",
                top: 'top',
                left: '2%',
                textStyle: {
                    fontSize: '25px',
                    fontFamily: 'AvenirNextDemiBold',
                    color: '#040415',
                },
            },
            dataset: {
                source: graphData,
            },
            graphic: {
                elements: [
                    {
                        id: 'A',
                        type: 'text', silent: true,
                        top: '65%', left: 'center',
                        z: 100,
                        style: {
                            text: [
                                '{textoA|Total}',
                                `{textoB|${volumenValor === 0 ? `${formatNumberWithCommas(trueTotal.toFixed(trueTotal > 1 ? 0 : 2))}` : `$${formatNumberWithCommas(trueTotal.toFixed(0))}`}}`,
                                `{textoC|${volumenValor === 0 ? 'Cajas (9L)' : ''}}`,
                            ].join('\n'),
                            rich: {
                                textoA: {
                                    fill: '#d1d3d4',
                                    fontFamily: 'AvenirNextMedium',
                                    fontSize: 20,
                                    align: 'center',
                                },
                                textoB: {
                                    fill: '#414042',
                                    fontFamily: 'AvenirNextDemiBold',
                                    fontSize: 36,
                                    align: 'center',
                                },
                                textoC: {
                                    fill: '#58595b',
                                    fontFamily: 'AvenirNextMedium',
                                    fontSize: 27,
                                    align: 'center',
                                },
                            },
                        },
                    },
                ],
            },
            xAxis: {
                type: 'category',
                axisLabel: {
                    color: '#58595b',
                    fontFamily: 'AvenirHeavy',
                },
                axisLine: {
                    lineStyle: {
                        color: '#939598',
                        width: 2,
                    },
                },
                axisTick: { show: false, },
            },
            yAxis: {
                type: 'value',
                axisLabel: {
                    color: '#d1d3d4',
                    fontFamily: 'AvenirNextRegular',
                    formatter: `${volumenValor === 0 ? '' : '$'}{value}`,
                },
                splitLine: {
                    lineStyle: {
                        color: '#e6e7e8',
                        type: 'dashed',
                        opacity: 1,
                    },
                },
                min: 0,
            },
            legend: {
                orient: 'horizontal',
                icon: "rect", itemWidth: 15, itemHeight: 15, itemGap: 10,
                top: '4.5%',
                left: 'right',
                padding: [0, 40, 0, 15],
                data: legendValues,
                textStyle: {
                    fontFamily: 'AvenirHeavy', color: '#58595b',
                    // fontSize: (0.00651 * screenWidth) - 2.62239,
                    fontSize: 10,
                },
            },
            tooltip: {
                trigger: 'axis',
                confine: true,
                axisPointer: {
                    lineStyle: {
                        color: '#414042',
                        type: 'solid',
                        width: 2,
                    },
                },
                formatter: (series) => {
                    series = series.filter(s => s.seriesType !== 'line');
                    let rowTotal = 0;
                    series.forEach(d => {
                        let value = d.data[d.seriesIndex + 1];
                        rowTotal += parseFloat(value);
                    });

                    let tooltipRow = series.map(d => {
                        let value = d.data[d.seriesIndex + 1];
                        return `
                            <div style="margin: 0.24vh 0px; font-size: 1.17vh;">
                                <div style="display: inline-block; background-color: ${d.color}; width: 0.65vw; height: 0.65vw;"></div>
                                <div style="display: inline-block; vertical-align: text-top; margin-top: -0.4vh; width: 6.2vw; padding-left: 0.2vw; white-space: initial; font-family: AvenirHeavy; color: #58595b;">${d.seriesName}</div>
                                <div style="display: inline-block; vertical-align: text-top; margin-top: -0.4vh; width: 5.42vw; text-align: center; font-family: AvenirMedium; color: #939598;">${volumenValor === 0 ? '' : '$'}${formatNumberWithCommas(value)}</div>
                                <div style="display: inline-block; vertical-align: text-top; margin-top: -0.4vh; width: 5vw; text-align: center; font-family: AvenirHeavy; color: #58595b;">${(value / rowTotal * 100).toFixed(2)}%</div>
                            </div>
                        `;
                    });
                    tooltipRow = tooltipRow.reverse();
                    tooltipRow.unshift(`
                        <div style="font-size: 0.96vh; font-family: AvenirNextMedium; color: #d1d3d4;">
                            <div style="display: inline-block; background-color: white; width: 0.65vw; height: 0.65vw;"></div>
                            <div style="display: inline-block; width: 6.2vw; padding-left: 0.2vw;">${tipoDivision === 0 ? 'Cadena' : (tipoDivision === 1 ? 'Marca' : 'SKU')}</div>
                            <div style="display: inline-block; width: 5.42vw; text-align: center;">${volumenValor === 0 ? 'Cajas (9L)' : '$'}</div>
                            <div style="display: inline-block; width: 5vw; text-align: center;">Porcentaje</div>
                        </div>
                    `);
                    tooltipRow.push(`
                        <div style="font-size: 1.17vh; font-family: AvenirHeavy; color: #414042; border-top: 3px solid #707070; padding-top: 0.24vh;">
                            <div style="display: inline-block; background-color: white; width: 0.65vw; height: 0.65vw;"></div>
                            <div style="display: inline-block; width: 6.2vw; padding-left: 0.2vw;">Total</div>
                            <div style="display: inline-block; width: 5.42vw; text-align: center;">${volumenValor === 0 ? '' : '$'}${formatNumberWithCommas(rowTotal.toFixed(0))}</div>
                            <div style="display: inline-block; width: 5vw; text-align: center;">100%</div>
                        </div>
                    `);

                    return tooltipRow.join('');
                },
                // valueFormatter: (value) => { return `$${formatNumberWithCommas(value)}` },
            },
            series: graphSeries,
        };

        this.setState({ graphOptions });
    }

    updateTotalText(event, chartObject) {
        const { volumenValor, pieChartData } = this.state;

        let selectedValues = event.selected;
        let newTotal = _.sumBy(pieChartData, (d) => {
            let value = 0;
            if (selectedValues[d.name]) value = d.value;

            return value;
        });

        chartObject.setOption({
            graphic: {
                elements: [
                    {
                        id: 'A',
                        style: {
                            text: [
                                '{textoA|Total}',
                                `{textoB|${volumenValor === 0 ? `${formatNumberWithCommas(newTotal.toFixed(newTotal > 1 ? 0 : 2))}` : `$${formatNumberWithCommas(newTotal.toFixed(0))}`}}`,
                                `{textoC|${volumenValor === 0 ? 'Cajas (9L)' : ''}}`,
                            ].join('\n'),
                            rich: {
                                textoA: {
                                    fill: '#d1d3d4',
                                    fontFamily: 'AvenirNextMedium',
                                    fontSize: 20,
                                    align: 'center',
                                },
                                textoB: {
                                    fill: '#414042',
                                    fontFamily: 'AvenirNextDemiBold',
                                    fontSize: 36,
                                    align: 'center',
                                },
                                textoC: {
                                    fill: '#58595b',
                                    fontFamily: 'AvenirNextMedium',
                                    fontSize: 27,
                                    align: 'center',
                                },
                            },
                        },
                    },
                ],
            },
        });
    }

    restartFilter() {
        const hostname = window.location.hostname;
        let isSellIn = ['locotequila.datalabmx.com'].includes(hostname) ? 1 : 0;

        this.setState({
            calendarDate: [new Date(2022, 0, 1), new Date(2022, 11, 1)],
            calendarSPFilter: 'all',
            tipoDivision: 0,
            volumenValor: 0,
            radioValue: 0,
            isSellIn,
            regionesShown: [0, 1, 2, 3, 4, 5, 6],
            estadosShown: this.props.data.estados.map(e => e.estado),
            clientesShown: this.props.data.cadenas.map(c => c.id),
            sucursalesShown: this.props.data.sucursales.map(s => s.id),
            marcasShown: this.props.data.marcas.map(m => m.marca),
            productosShown: this.props.data.productos.map(p => p.id),
        });
    }

    render() {
        const {
            graphOptions, graphData,
            calendarDate, calendarSPFilter, tipoDivision, volumenValor, radioValue, isSellIn,
            regionesShown, estadosShown, clientesShown, sucursalesShown, marcasShown, productosShown,
            availableRegiones, availableEstados, availableClientes, availableSucursales, availableMarcas, availableProductos
        } = this.state;
        const apiData = this.props.data;

        let subtitlePartA = volumenValor === 0 ? 'Volumen' : 'Valor';
        let subtitlePartB = getCanalSubtitle(radioValue, apiData);
        let subtitlePartC = tipoDivision === 0 ? 'Cadenas' : (tipoDivision === 1 ? 'Marca' : 'SKU');
        let infoSubtitle = ` (${subtitlePartA} / ${subtitlePartB} / ${subtitlePartC})`;

        if (_.isEmpty(apiData)) {
            return (
                <div className={styles["loadingContainer"]}>
                    <span className={styles["aligHelper"]}></span>
                    <object
                        className={styles["loading"]}
                        data="./images/fact_loading_sp.svg"
                        type="image/svg+xml"
                    >
                        <img
                            src="./images/fact_loading_sp.svg"
                            alt="Cargando contenido"
                        />
                    </object>
                </div>
            );
        } else {
            return (
                <div className={styles["mainContainer"]}>
                    <Header
                        history={this.props.history}
                    />
                    <div className={styles["subContainer"]}>
                        {!_.isEmpty(apiData) ? (
                            <Filtro
                                tipo={'C'}
                                calendarDate={calendarDate}
                                handleChangeCalendar={this.handleChangeCalendar}
                                handleChangeCalendarWeek={this.handleChangeCalendarWeek}
                                calendarSPFilter={calendarSPFilter}
                                handleChanceCalendarSPFilter={this.handleChanceCalendarSPFilter}
                                tipoDivision={tipoDivision}
                                handleChangeTipo={this.handleChangeTipo}
                                volumenValor={volumenValor}
                                handleChangeVolumenValor={this.handleChangeVolumenValor}
                                radioValue={radioValue}
                                handleChangeRadio={this.handleChangeRadio}
                                isSellIn={isSellIn}
                                handleChangeIsSellIn={this.handleChangeIsSellIn}
                                regionesShown={regionesShown}
                                handleChangeRegion={this.handleChangeRegion}
                                estadosShown={estadosShown}
                                handleChangeEstado={this.handleChangeEstado}
                                clientesShown={clientesShown}
                                handleChangeCliente={this.handleChangeCliente}
                                sucursalesShown={sucursalesShown}
                                handleChangeSucursal={this.handleChangeSucursal}
                                marcasShown={marcasShown}
                                handleChangeMarca={this.handleChangeMarca}
                                productosShown={productosShown}
                                handleChangeProducto={this.handleChangeProducto}
                                // ====================================================
                                availableRegiones={availableRegiones}
                                availableEstados={availableEstados}
                                availableClientes={availableClientes}
                                availableSucursales={availableSucursales}
                                availableMarcas={availableMarcas}
                                availableProductos={availableProductos}
                                // ====================================================
                                apiData={apiData}
                                restartFilter={this.restartFilter}
                            />
                        ) : ''}
                        <div className={styles["infoContainer"]}>
                            {graphData.length === 1 ? (
                                <div className={styles["noDataContainer"]}>No existen datos correspondientes a este filtrado</div>
                            ) : ''}
                            <div className={styles["alcanceContainer"]}>
                                <Alcance apiData={apiData} />
                                <FiltrosAplicados
                                    apiData={apiData}
                                    tipoDivision={tipoDivision} volumenValor={volumenValor} radioValue={radioValue}
                                    regionesShown={regionesShown} estadosShown={estadosShown}
                                    clientesShown={clientesShown} marcasShown={marcasShown} productosShown={productosShown}
                                />
                            </div>
                            <div className={styles["infoTitle"]}>
                                Sell Out
                                {/* <span className={styles["infoSubtitle"]}>{infoSubtitle}</span> */}
                                {/* <PseudoBreadcrumb
                                    apiData={apiData} tipoDivision={tipoDivision}
                                    regionesShown={regionesShown} clientesShown={clientesShown}
                                    marcasShown={marcasShown} productosShown={productosShown}
                                /> */}
                                <div className={styles["ytdContainer"]}>{getYTDText(calendarDate, true)}</div>
                            </div>
                            <div className={styles["chartContainerA"]}>
                                {!_.isEmpty(graphOptions) ? (
                                    <ReactECharts
                                        option={graphOptions}
                                        style={{ height: '100%' }}
                                        onEvents={{
                                            selectchanged: () => { console.log(''); }, // Añadir esto arregló el problema de encimados, y no sé porqué
                                            legendselectchanged: (event, chartObject) => { this.updateTotalText(event, chartObject); },
                                        }}
                                    />
                                ) : ''}
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

function mapStateToProps(state) {
    return {
        data: state.data,
    };
}

export default connect(mapStateToProps, {
    dataAction,
    filterDataAction,
})(Vista4);