import { parseISO, lastDayOfMonth } from 'date-fns';
import { maxBy } from 'lodash';
import chroma from "chroma-js";

import { GET_DATA, FILTER_DATA } from "../actions";

const DEFAULT_STATE = {
    databank: null,
    filteredData: [],
};

export default function (state = {}, action) {
    switch (action.type) {
        case GET_DATA:
            let data = action.payload;
            // console.log('¿Qué tenemos aquí?', action.payload);

            let ventas = data.ventas.map(v => {
                let fechaFormato = parseISO(v.fechaVenta);
                let mesVenta = fechaFormato.getMonth();
                let ventas_mxn = parseFloat(v.ventas_mxn);
                let ventas_unidades = parseFloat(v.ventas_unidades);
                let isSellIn = parseInt(v.isSellIn);
                let ticket = parseFloat(v.ticket);
                // =================================================
                let idCanal = 0;
                if (v.idCanal) idCanal = parseInt(v.idCanal);
                let region = 0;
                if (v.region) region = parseInt(v.region);

                return { ...v, fechaFormato, mesVenta, ventas_mxn, ventas_unidades, isSellIn, ticket, idCanal, region };
            });
            let maxAvailableDate = maxBy(ventas, 'fechaFormato').fechaFormato;
            maxAvailableDate = lastDayOfMonth(maxAvailableDate);

            let marcas = data.marcas.map(m => {
                let gradiente = chroma.scale([m.colorMarca, m.colorLimite]);

                return { ...m, gradiente };
            });

            let cadenas = data.cadenas.map(c => {
                let gradiente = chroma.scale([c.color, c.colorLimite]);

                return { ...c, gradiente };
            });

            return {
                ...state,
                ...data,
                ventas,
                marcas,
                cadenas,
                maxAvailableDate,
            };

        case FILTER_DATA:
            return { ...state };

        default:
            return { ...state };
    }
}