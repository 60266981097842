import React, { Component } from "react";
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import OutsideClickHandler from 'react-outside-click-handler';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Switch from "react-switch";

import styles from "./Filtro.module.scss";
import '../../extras/DateRangePicker.scss';
import '../../extras/react-tabs.scss';
import '../../extras/react-switch.scss';

const abc = ['#', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
const meses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

class Filtro extends Component {
    constructor(props) {
        super(props);
        this.state = {
            calendarioTipo: 0,
            showCanales: true,
            canalTipo: '0',
            showCat: false,
            showPtosVentaA: false,
            showPtosVentaB: false,
            // ==============================
            showRegion: false,
            showEstado: false,
            showCliente: false,
            showMarca: false,
            showSKU: false,
            // ==============================
            activeStartDate: new Date(),
        };

        // this.handleChangeCalendarRadio = this.handleChangeCalendarRadio.bind(this);
        this.handleChangeCanalRadio = this.handleChangeCanalRadio.bind(this);
    }

    handleChangeCalendarRadio(calendarioTipo, calendarSPFilter) {
        this.props.handleChanceCalendarSPFilter(calendarSPFilter);
        this.setState({ calendarioTipo });
    }

    handleChangeCanalRadio(event) {
        let canalTipo = event.target.value;
        this.setState({ canalTipo });
    }

    handleActiveStartDate(params) {
        // const { calendarDate } = this.props;

        let activeStartDate = params.activeStartDate;
        if (activeStartDate.getFullYear() < 2018) activeStartDate = new Date(2018, 0, 1);

        this.setState({ activeStartDate });
    }

    componentDidMount() {
        const { apiData } = this.props;

        let activeStartDate = apiData.maxAvailableDate ? apiData.maxAvailableDate : new Date();
        this.setState({ activeStartDate });
    }

    render() {
        const {
            showRegion, showEstado, showCliente, showMarca, showSKU,
            calendarioTipo, showCanales, showCat, showPtosVentaA, showPtosVentaB,
            activeStartDate,
        } = this.state;
        const {
            tipo, apiData,
            calendarDate, mesesRange, tipoDivision, volumenValor, radioValue, isSellIn,
            regionesShown, estadosShown, clientesShown, marcasShown, productosShown, sucursalesShown,
            checkedIDSU, checkedAMAI, checkedDensidad, shownCadenasMapa,
            availableRegiones, availableEstados, availableClientes, availableMarcas, availableProductos, availableSucursales
        } = this.props;
        const hostname = window.location.hostname;
        // console.log('¿Se obtiene?', apiData);

        let calendarMaxDetail, calendarMinDetail, calendarFormat, calendarShowWeekNumbers;
        switch (calendarioTipo) {
            case 0: // Anual
                calendarMaxDetail = 'decade';
                calendarMinDetail = 'decade';
                calendarFormat = 'y';
                calendarShowWeekNumbers = false;
                break;
            case 1: // Mensual
                calendarMaxDetail = 'year';
                calendarMinDetail = 'year';
                calendarFormat = 'MM/y';
                calendarShowWeekNumbers = false;
                break;
            case 3: // Semanal
                calendarMaxDetail = 'month';
                calendarFormat = 'dd/MM/y';
                calendarShowWeekNumbers = true;
                break;
            case 2: // Diario
                calendarMaxDetail = 'month';
                calendarFormat = 'dd/MM/y';
                calendarShowWeekNumbers = false;
                break;
            default:
                calendarMaxDetail = 'decade';
                calendarMinDetail = 'year';
                calendarFormat = 'y';
        }

        let calendarGridTemplateColumns;
        switch (tipo) {
            case 'A':
                calendarGridTemplateColumns = '25% 25% 25% 25%';
                break;
            case 'B':
                calendarGridTemplateColumns = '25% 25% 25% 25%';
                break;
            case 'C':
                calendarGridTemplateColumns = '100%';
                break;
            case 'D':
                calendarGridTemplateColumns = '100%';
                break;
            case 'E':
                calendarGridTemplateColumns = '100%';
                break;
            case 'F':
                calendarGridTemplateColumns = '100%';
                break;
            default:
                calendarGridTemplateColumns = '25% 25% 25% 25%';
        }

        let mesesDropdownA = [];
        let mesesDropdownB = [];
        if (mesesRange) {
            mesesDropdownA = meses.map((m, i) => {
                return <option key={`mesA_${i}`} value={i} disabled={i > mesesRange[1]}>{m}</option>
            });
            mesesDropdownB = meses.map((m, i) => {
                return <option key={`mesB_${i}`} value={i} disabled={i < mesesRange[0]}>{m}</option>
            });
        }

        let opcionesRadio = [];
        switch (tipo) {
            /* case 'B':
                opcionesRadio = [
                    { value: 1, label: 'Cadenas' },
                    { value: 0, label: 'Sucursales' },
                    { value: 2, label: 'Marca' },
                    { value: 3, label: 'SKU' },
                ];
                if (['locotequila.datalabmx.com'].includes(hostname)) opcionesRadio = opcionesRadio.filter(o => o.value !== 0);
                break; */
            default:
                // opcionesRadio = ['Todos', 'Mayoreo', 'Menudeo', 'Telemarketing', 'E-Commerce', 'Off-Trade Mayoreo', 'Off-Trade Moderno', 'On-Trade E-Commerce', 'Venta Directa'];
                if (['locotequila.datalabmx.com'].includes(hostname)) {
                    opcionesRadio = [{ value: 0, label: 'Todos' }];
                    let tmpOpciones = apiData.canales.map(c => { return { value: parseInt(c.id), label: c.nombre }; });
                    opcionesRadio = opcionesRadio.concat(tmpOpciones);
                } else {
                    opcionesRadio = [
                        { value: 0, label: 'Todos' },
                        { value: 2, label: 'Canal Moderno' },
                        { value: 1, label: 'Canal Tradicional / Mayoristas' },
                        { value: 3, label: 'Telemarketing' },
                        { value: 4, label: 'E-Commerce' },
                    ];
                }
        }

        let canalRadioOptions = [];
        if (radioValue !== undefined) {
            canalRadioOptions = opcionesRadio.map((c, i) => {
                return <label className={styles["radioButtonContainer"]} key={Math.random()}>
                    <span className={styles["aligHelper"]}></span>
                    <input type="radio" id={`filterRadioCanal_${i}`} name="filterCanalOption" value={c.value} defaultChecked={c.value === radioValue} />
                    <div className={styles["radioCheckmark"]}></div>
                    <div className={styles["radioLabel"]}>{c.label}</div>
                </label>
            });
        }

        let regionesOptions = [
            { value: 0, label: 'Sin Región' }, { value: 1, label: 'Región I' }, { value: 2, label: 'Región II' },
            { value: 3, label: 'Región III' }, { value: 4, label: 'Región IV' }, { value: 5, label: 'Región V' }, { value: 6, label: 'Región VI' }
        ];
        let regionesCheckOptions = [];
        if (regionesShown) {
            regionesCheckOptions = regionesOptions.map(r => {
                return <label className={`${styles["checkmarkContainer"]}${availableRegiones.includes(r.value) ? '' : ` ${styles["disabledCheck"]}`}`} key={Math.random()}>
                    {r.label}
                    <input type="checkbox" name='checkmarkCliente' value={r.value} defaultChecked={regionesShown.includes(r.value) && availableRegiones.includes(r.value)}></input>
                    <span className={styles["checkmark"]}></span>
                </label>
            });
        }

        let estadosCheckOptions = [];
        if (estadosShown) {
            estadosCheckOptions = apiData.estados.map(e => {
                return <label className={`${styles["checkmarkContainer"]}${availableEstados.includes(e.estado) ? '' : ` ${styles["disabledCheck"]}`}`} key={Math.random()}>
                    {e.estado}
                    <input type="checkbox" name='checkmarkCliente' value={e.estado} defaultChecked={estadosShown.includes(e.estado) && availableEstados.includes(e.estado)}></input>
                    <span className={styles["checkmark"]}></span>
                </label>
            });
        }

        let clienteCheckOptions = [];
        if (clientesShown && sucursalesShown) {
            if (['locotequila.datalabmx.com'].includes(hostname)) {
                clienteCheckOptions = <Tabs>
                    <TabList>
                        {abc.map(letra => <Tab key={`clientesTab_${letra}`}>{letra}</Tab>)}
                    </TabList>

                    {abc.map(letra => {
                        let clientesInLetter = [];
                        if (letra === '#') clientesInLetter = [...apiData.cadenas].filter(c => /^\d/.test(c.nombre));
                        else clientesInLetter = [...apiData.cadenas].filter(c => c.nombre.toUpperCase().startsWith(letra));

                        let clientesInLetterActive = [];
                        let clientesOptions = clientesInLetter.map(p => {
                            if (clientesShown.includes(p.id) && availableClientes.includes(p.id)) clientesInLetterActive.push(p.id);

                            return <label className={`${styles["checkmarkContainer"]}${availableClientes.includes(p.id) ? '' : ` ${styles["disabledCheck"]}`}`} key={Math.random()}>
                                {p.nombre}
                                <input type="checkbox" name='checkmarkCliente' value={p.id} defaultChecked={clientesShown.includes(p.id) && availableClientes.includes(p.id)}></input>
                                <span className={styles["checkmark"]}></span>
                            </label>
                        });
                        let clientesPerColumn = Math.ceil(clientesOptions.length / 3);
                        let availableClientesLetter = [...clientesInLetter].filter(p => availableClientes.includes(p.id));

                        return <TabPanel key={`clienteTabPanel_${letra}`}>
                            <div className={styles["menuInfoCheckAllContainer"]}>
                                <label className={styles["checkmarkContainer"]} key={Math.random()}>
                                    {availableClientesLetter.length === clientesInLetterActive.length ? 'Deseleccionar todos' : 'Seleccionar todos'}
                                    <input type="checkbox" name='checkmarkCliente' value={`all_${letra}`} defaultChecked={availableClientesLetter.length === clientesInLetterActive.length}></input>
                                    <span className={styles["checkmark"]}></span>
                                </label>
                            </div>
                            <div className={styles["threeColumnMenu"]}>
                                <div>{clientesOptions.slice(0, clientesPerColumn)}</div>
                                <div>{clientesOptions.slice(clientesPerColumn, clientesPerColumn * 2)}</div>
                                <div>{clientesOptions.slice(clientesPerColumn * 2)}</div>
                            </div>
                        </TabPanel>
                    })}
                </Tabs>
            } else {
                clienteCheckOptions = <Tabs>
                    <TabList>
                        {apiData.cadenas.map(c => <Tab key={`clienteTab_${c.id}`}>{c.nombre}</Tab>)}
                    </TabList>

                    {apiData.cadenas.map(c => {
                        let sucursalesInCadena = [...apiData.sucursales].filter(s => s.cadena === c.nombre);
                        let sucursalesInClienteActive = [];
                        let sucursalesOptions = sucursalesInCadena.map(s => {
                            if (sucursalesShown.includes(s.id) && availableSucursales.includes(s.id)) sucursalesInClienteActive.push(s.id);

                            return <label className={`${styles["checkmarkContainer"]}${availableSucursales.includes(s.id) ? '' : ` ${styles["disabledCheck"]}`}`} key={Math.random()}>
                                {s.nombre}
                                <input type="checkbox" name='checkmarkCliente' value={s.id} defaultChecked={sucursalesShown.includes(s.id) && availableSucursales.includes(s.id)}></input>
                                <span className={styles["checkmark"]}></span>
                            </label>
                        });
                        let sucursalesPerColumn = Math.ceil(sucursalesOptions.length / 3);
                        let availableSucursalesCliente = [...sucursalesInCadena].filter(s => availableSucursales.includes(s.id));

                        return <TabPanel key={`clienteTabPanel_${c.id}`}>
                            <div className={styles["menuInfoCheckAllContainer"]}>
                                <label className={styles["checkmarkContainer"]} key={Math.random()}>
                                    {availableSucursalesCliente.length === sucursalesInClienteActive.length ? 'Deseleccionar todos' : 'Seleccionar todos'}
                                    <input type="checkbox" name='checkmarkSucursales' value={`all_${c.id}`} defaultChecked={availableSucursalesCliente.length === sucursalesInClienteActive.length}></input>
                                    <span className={styles["checkmark"]}></span>
                                </label>
                            </div>
                            <div className={styles["threeColumnMenu"]}>
                                <div>{sucursalesOptions.slice(0, sucursalesPerColumn)}</div>
                                <div>{sucursalesOptions.slice(sucursalesPerColumn, sucursalesPerColumn * 2)}</div>
                                <div>{sucursalesOptions.slice(sucursalesPerColumn * 2)}</div>
                            </div>
                        </TabPanel>
                    })}
                </Tabs>

                /* clienteCheckOptions = apiData.cadenas.map(c => {
                    return <label className={`${styles["checkmarkContainer"]}${availableClientes.includes(c.id) ? '' : ` ${styles["disabledCheck"]}`}`} key={Math.random()}>
                        {c.nombre}
                        <input type="checkbox" name='checkmarkCliente' value={c.id} defaultChecked={clientesShown.includes(c.id) && availableClientes.includes(c.id)}></input>
                        <span className={styles["checkmark"]}></span>
                    </label>
                }); */
            }
        }

        let marcasCheckOptions = [];
        if (marcasShown) {
            marcasCheckOptions = apiData.marcas.map(m => {
                return <label className={`${styles["checkmarkContainer"]}${availableMarcas.includes(m.marca) ? '' : ` ${styles["disabledCheck"]}`}`} key={Math.random()}>
                    {m.marca}
                    <input type="checkbox" name='checkmarkCliente' value={m.marca} defaultChecked={marcasShown.includes(m.marca) && availableMarcas.includes(m.marca)}></input>
                    <span className={styles["checkmark"]}></span>
                </label>
            });
        }

        let productosCheckOptions = '';
        if (productosShown) {
            productosCheckOptions = <Tabs>
                <TabList>
                    {apiData.marcas.map(m => <Tab key={`productoTab_${m.marca}`}>{m.marca}</Tab>)}
                </TabList>

                {apiData.marcas.map(m => {
                    let productosInMarca = [...apiData.productos].filter(p => p.marca === m.marca);
                    let productosInMarcaActive = [];
                    let productosOptions = productosInMarca.map(p => {
                        if (productosShown.includes(p.id) && availableProductos.includes(p.id)) productosInMarcaActive.push(p.id);

                        return <label className={`${styles["checkmarkContainer"]}${availableProductos.includes(p.id) ? '' : ` ${styles["disabledCheck"]}`}`} key={Math.random()}>
                            {p.nombre}
                            <input type="checkbox" name='checkmarkCliente' value={p.id} defaultChecked={productosShown.includes(p.id) && availableProductos.includes(p.id)}></input>
                            <span className={styles["checkmark"]}></span>
                        </label>
                    });
                    let productosPerColumn = Math.ceil(productosOptions.length / 3);
                    let availableProductosMarca = [...productosInMarca].filter(p => availableProductos.includes(p.id));

                    return <TabPanel key={`productoTabPanel_${m.marca}`}>
                        <div className={styles["menuInfoCheckAllContainer"]}>
                            <label className={styles["checkmarkContainer"]} key={Math.random()}>
                                {availableProductosMarca.length === productosInMarcaActive.length ? 'Deseleccionar todos' : 'Seleccionar todos'}
                                <input type="checkbox" name='checkmarkProducto' value={`all_${m.marca}`} defaultChecked={availableProductosMarca.length === productosInMarcaActive.length}></input>
                                <span className={styles["checkmark"]}></span>
                            </label>
                        </div>
                        <div className={styles["threeColumnMenu"]}>
                            <div>{productosOptions.slice(0, productosPerColumn)}</div>
                            <div>{productosOptions.slice(productosPerColumn, productosPerColumn * 2)}</div>
                            <div>{productosOptions.slice(productosPerColumn * 2)}</div>
                        </div>
                    </TabPanel>
                })}
            </Tabs>;
        }

        let opcionesChecks = apiData.cadenas.map(c => c.nombre);
        let ventaCheckOptions = [];
        if (shownCadenasMapa) {
            ventaCheckOptions = opcionesChecks.map((c, i) => {
                return <label className={styles["checkmarkContainer"]} key={Math.random()}>
                    {c}
                    <input type="checkbox" value={c} name='checkmarkPtoVta' defaultChecked={shownCadenasMapa.includes(c)}></input>
                    <span className={styles["checkmark"]}></span>
                </label>
            });
        }

        return (
            <div className={styles["filterContainer"]}>
                <div className={styles["fechaContainer"]}>
                    <div className={styles["sectionTitleA"]}>
                        <span className={styles["aligHelper"]}></span>
                        <img className={styles["sectionIconA"]} src='./images/iconosMenu/fecha.png' alt=''></img>
                        <span className={styles["sectionTextA"]}>Fecha</span>
                    </div>
                    <div className={styles["fechaOptionsContainer"]} style={{ gridTemplateColumns: calendarGridTemplateColumns }}>
                        {['A', 'B', 'C', 'D', 'E', 'F'].includes(tipo) ? (
                            <div className={styles[calendarioTipo === 0 ? "optionSelected" : "optionUnselected"]} onClick={() => { this.handleChangeCalendarRadio(0, 'all'); }}>Años</div>
                        ) : ''}
                        {['A', 'B'].includes(tipo) ? (
                            <div className={styles[calendarioTipo === 1 ? "optionSelected" : "optionUnselected"]} onClick={() => { this.handleChangeCalendarRadio(1, 'all'); }}>Meses</div>
                        ) : ''}
                        {['A', 'B'].includes(tipo) ? (
                            <div className={styles[calendarioTipo === 3 ? "optionSelected" : "optionUnselected"]} onClick={() => { this.handleChangeCalendarRadio(3, 'weekly'); }}>Semanal</div>
                        ) : ''}
                        {['A', 'B'].includes(tipo) ? (
                            <div className={styles[calendarioTipo === 2 ? "optionSelected" : "optionUnselected"]} onClick={() => { this.handleChangeCalendarRadio(2, 'daily'); }}>Días</div>
                        ) : ''}
                    </div>
                    <DateRangePicker
                        onChange={this.props.handleChangeCalendar}
                        value={calendarDate}
                        minDetail={calendarMinDetail}
                        maxDetail={calendarMaxDetail}
                        minDate={new Date(2021, 0, 1)}
                        maxDate={apiData.maxAvailableDate ? apiData.maxAvailableDate : new Date()}
                        className={"date-selector"}
                        clearIcon={null}
                        calendarIcon={null}
                        locale={'es-MX'}
                        format={calendarFormat}
                        calendarType={"ISO 8601"}
                        showWeekNumbers={calendarShowWeekNumbers}
                        // tileDisabled={() => true}
                        // ======================================
                        activeStartDate={activeStartDate}
                        onActiveStartDateChange={(params) => this.handleActiveStartDate(params)}
                        onClickWeekNumber={(weekNumber, date, event) => this.props.handleChangeCalendarWeek(weekNumber, date, event)}
                    />
                </div>
                {['E'].includes(tipo) ? (
                    <div className={styles["fechaContainer"]}>
                        <div className={styles["sectionTitleA"]}>
                            <span className={styles["aligHelper"]}></span>
                            <img className={styles["sectionIconA"]} src='./images/iconosMenu/rango_meses.svg' alt=''></img>
                            <span className={styles["sectionTextA"]}>Rango de Meses</span>
                        </div>
                        <div className={styles["mesesRangeContainer"]}>
                            De
                            <select className={styles["mesesRangeDropdown"]} value={mesesRange[0]} onChange={this.props.handleChangeMesesRangeA}>
                                {mesesDropdownA}
                            </select>
                            a
                            <select className={styles["mesesRangeDropdown"]} value={mesesRange[1]} onChange={this.props.handleChangeMesesRangeB}>
                                {mesesDropdownB}
                            </select>
                        </div>
                    </div>
                ) : ''}
                {['A', 'B', 'C', 'E', 'F'].includes(tipo) && ['locotequila.datalabmx.com'].includes(hostname) ? (
                    <div className={styles["toggleContainer"]} /* style={{ gridTemplateColumns: '50% 50%' }} */>
                        <div className={styles[isSellIn === 1 ? "optionSelected" : "optionUnselected"]} onClick={() => { this.props.handleChangeIsSellIn(1) }}>Sell In</div>
                        <div className={styles[isSellIn === 0 ? "optionSelected" : "optionUnselected"]} onClick={() => { this.props.handleChangeIsSellIn(0) }}>Sell Out</div>
                    </div>
                ) : ''}
                {['A', 'B', 'C', 'E', 'F'].includes(tipo) ? (
                    <div className={styles["toggleContainer"]} style={!['C', 'E', 'F'].includes(tipo) ? { gridTemplateColumns: '33% 33% 34%' } : {}}>
                        <div className={styles[volumenValor === 0 ? "optionSelected" : "optionUnselected"]} onClick={() => { this.props.handleChangeVolumenValor(0) }}>Volumen</div>
                        <div className={styles[volumenValor === 1 ? "optionSelected" : "optionUnselected"]} onClick={() => { this.props.handleChangeVolumenValor(1) }}>Valor</div>
                        {!['C', 'E', 'F'].includes(tipo) ? (
                            <div className={styles[volumenValor === 2 ? "optionSelected" : "optionUnselected"]} onClick={() => { this.props.handleChangeVolumenValor(2) }}>Ticket</div>
                        ) : ''}
                    </div>
                ) : ''}
                {['A', 'B', 'C', 'E', 'F'].includes(tipo) ? (
                    <div className={styles["canalContainer"]}>
                        <div className={styles["sectionTitleA"]} style={{ cursor: 'pointer' }} onClick={() => { this.setState({ showCanales: !showCanales }) }}>
                            <span className={styles["aligHelper"]}></span>
                            <img className={styles["sectionIconA"]} src='./images/iconosMenu/canal.png' alt=''></img>
                            <span className={styles["sectionTextA"]}>Canal</span>
                            <span className={styles["flechaMenuPlegable"]}>{showCanales ? '▲' : '▼'}</span>
                        </div>
                        <div className={styles["radioContainer"]} onChange={this.props.handleChangeRadio} style={{ display: showCanales ? 'block' : 'none' }}>
                            {canalRadioOptions}
                        </div>
                    </div>
                ) : ''}
                {[].includes(tipo) ? (
                    <div className={styles["canalContainer"]} style={{ marginTop: '1.4vh' }}>
                        <div className={styles["radioContainer"]} onChange={this.props.handleChangeRadio} style={{ marginTop: '0vh' }} >
                            {canalRadioOptions}
                        </div>
                    </div>
                ) : ''}
                {['D'].includes(tipo) ? (
                    <div className={styles["canalContainer"]}>
                        <div className={styles["sectionTitleA"]}>
                            <span className={styles["aligHelper"]}></span>
                            <img className={styles["sectionIconA"]} src='./images/iconosMenu/canal.png' alt=''></img>
                            <span className={styles["sectionTextA"]}>Puntos de Venta</span>
                        </div>
                        <div className={styles["radioContainer"]} onChange={this.props.handleChangeShownCadenasMapa}>
                            {ventaCheckOptions}
                        </div>
                    </div>
                ) : ''}
                {['A', 'B', 'C', 'E', 'F'].includes(tipo) ? (
                    <div className={styles["toggleContainer"]} style={{ gridTemplateColumns: (!['B', 'E', 'F'].includes(tipo) ? '33% 33% 34%' : '25% 25% 25% 25%') }}>
                        <div className={styles[tipoDivision === 0 ? "optionSelected" : "optionUnselected"]} onClick={() => { this.props.handleChangeTipo(0); }}>Cadenas</div>
                        {['B', 'E', 'F'].includes(tipo) ? (
                            <div className={styles[tipoDivision === 3 ? "optionSelected" : "optionUnselected"]} onClick={() => { this.props.handleChangeTipo(3); }}>Sucursal</div>
                        ) : ''}
                        <div className={styles[tipoDivision === 1 ? "optionSelected" : "optionUnselected"]} onClick={() => { this.props.handleChangeTipo(1); }}>Marca</div>
                        <div className={styles[tipoDivision === 2 ? "optionSelected" : "optionUnselected"]} onClick={() => { this.props.handleChangeTipo(2); }}>SKU</div>
                    </div>
                ) : ''}
                {['A', 'B', 'C', 'E', 'F'].includes(tipo) ? (
                    <div className={styles["menuOptionMainContainer"]}>
                        <div className={styles["menuOptionContainer"]} onClick={() => { this.setState({ showRegion: true }); }}>
                            <span className={styles["aligHelper"]}></span>
                            <img className={styles["sectionIconB"]} src='./images/iconosMenu/region.svg' alt=''></img>
                            <span className={styles["sectionTextB"]}>Regiones</span>
                            <span className={styles["menuArrow"]}>›</span>
                        </div>
                        {showRegion ? (
                            <OutsideClickHandler onOutsideClick={() => { this.setState({ showRegion: false }); }}>
                                <div className={styles["menuInfoContainer"]}>
                                    <div className={styles["menuInfoTitleContainer"]}>
                                        <div className={styles["menuInfoTitle"]}>Regiones</div>
                                        <div className={styles["menuInfoTitleExtra"]}>
                                            <div className={styles["menuInfoTitleExtraA"]}>6</div>
                                            <div className={styles["menuInfoTitleExtraB"]}>TOTALES</div>
                                        </div>
                                    </div>
                                    <div className={styles["menuInfoChecklistContainer"]} onChange={this.props.handleChangeRegion}>
                                        <div className={styles["menuInfoCheckAllContainer"]}>
                                            <label className={styles["checkmarkContainer"]} key={Math.random()}>
                                                {regionesShown.length === 7 ? 'Deseleccionar todos' : 'Seleccionar todos'}
                                                <input type="checkbox" name='checkmarkCliente' value={'all'} defaultChecked={regionesShown.length === 7}></input>
                                                <span className={styles["checkmark"]}></span>
                                            </label>
                                        </div>
                                        <div className={styles["twoColumnMenu"]}>
                                            <div>
                                                {regionesCheckOptions.slice(0, 4)}
                                            </div>
                                            <div>
                                                {regionesCheckOptions.slice(4)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </OutsideClickHandler>
                        ) : ''}
                    </div>
                ) : ''}
                {['A', 'B', 'C', 'E', 'F'].includes(tipo) ? (
                    <div className={styles["menuOptionMainContainer"]}>
                        <div className={styles["menuOptionContainer"]} onClick={() => { this.setState({ showEstado: true }); }}>
                            <span className={styles["aligHelper"]}></span>
                            <img className={styles["sectionIconB"]} src='./images/iconosMenu/estado.svg' alt=''></img>
                            <div className={styles["sectionTextB"]} style={{ display: 'inline-block' }}>Estados</div>
                            <span className={styles["menuArrow"]}>›</span>
                        </div>
                        {showEstado ? (
                            <OutsideClickHandler onOutsideClick={() => { this.setState({ showEstado: false }); }}>
                                <div className={styles["menuInfoContainer"]}>
                                    <div className={styles["menuInfoTitleContainer"]}>
                                        <div className={styles["menuInfoTitle"]}>Estados</div>
                                        <div className={styles["menuInfoTitleExtra"]}>
                                            <div className={styles["menuInfoTitleExtraA"]}>32</div>
                                            <div className={styles["menuInfoTitleExtraB"]}>TOTALES</div>
                                        </div>
                                    </div>
                                    <div className={styles["menuInfoChecklistContainer"]} style={{ width: '40vw' }} onChange={this.props.handleChangeEstado}>
                                        <div className={styles["menuInfoCheckAllContainer"]}>
                                            <label className={styles["checkmarkContainer"]} key={Math.random()}>
                                                {estadosShown.length === 33 ? 'Deseleccionar todos' : 'Seleccionar todos'}
                                                <input type="checkbox" name='checkmarkCliente' value={'all'} defaultChecked={estadosShown.length === 33}></input>
                                                <span className={styles["checkmark"]}></span>
                                            </label>
                                        </div>
                                        <div className={styles["threeColumnMenu"]}>
                                            <div>
                                                {estadosCheckOptions.slice(0, 12)}
                                            </div>
                                            <div>
                                                {estadosCheckOptions.slice(12, 24)}
                                            </div>
                                            <div>
                                                {estadosCheckOptions.slice(24)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </OutsideClickHandler>
                        ) : ''}
                    </div>
                ) : ''}
                {['A', 'B', 'C', 'E', 'F'].includes(tipo) ? (
                    <div className={styles["menuOptionMainContainer"]}>
                        <div className={styles["menuOptionContainer"]} onClick={() => { this.setState({ showCliente: true }); }}>
                            <span className={styles["aligHelper"]}></span>
                            <img className={styles["sectionIconB"]} src='./images/iconosMenu/compañia.png' alt=''></img>
                            <div className={styles["sectionTextB"]} style={{ display: 'inline-block' }}>
                                <div>Cadenas</div>
                                <div className={styles["sectionTextB_subtext"]}>{'(Sucursales)'}</div>
                            </div>
                            <span className={styles["menuArrow"]}>›</span>
                        </div>
                        {showCliente ? (
                            <OutsideClickHandler onOutsideClick={() => { this.setState({ showCliente: false }); }}>
                                <div className={styles["menuInfoContainer"]} /* style={['locotequila.datalabmx.com'].includes(hostname) ? { width: '55vw' } : {}} */ style={{ width: '55vw' }}>
                                    <div className={styles["menuInfoTitleContainer"]}>
                                        <div className={styles["menuInfoTitle"]}>Cadenas</div>
                                        <div className={styles["menuInfoTitleExtra"]}>
                                            <div className={styles["menuInfoTitleExtraA"]}>{apiData.cadenas.length}</div>
                                            <div className={styles["menuInfoTitleExtraB"]}>TOTALES</div>
                                        </div>
                                    </div>
                                    <div className={styles["menuInfoChecklistContainer"]} onChange={['locotequila.datalabmx.com'].includes(hostname) ? this.props.handleChangeCliente : this.props.handleChangeSucursal}>
                                        <div className={styles["menuInfoCheckAllContainer"]}>
                                            <label className={styles["checkmarkContainer"]} key={Math.random()}>
                                                {['locotequila.datalabmx.com'].includes(hostname) ? (
                                                    <>
                                                        {clientesShown.length === apiData.cadenas.length ? 'Deseleccionar todos' : 'Seleccionar todos'}
                                                        <input type="checkbox" name='checkmarkCliente' value={'all'} defaultChecked={clientesShown.length === apiData.cadenas.length}></input>
                                                    </>
                                                ) : (
                                                    <>
                                                        {sucursalesShown.length === apiData.sucursales.length ? 'Deseleccionar todos' : 'Seleccionar todos'}
                                                        <input type="checkbox" name='checkmarkCliente' value={'all'} defaultChecked={sucursalesShown.length === apiData.sucursales.length}></input>
                                                    </>
                                                )}
                                                <span className={styles["checkmark"]}></span>
                                            </label>
                                        </div>
                                        {['locotequila.datalabmx.com'].includes(hostname) ? (
                                            clienteCheckOptions
                                        ) : (
                                            <>
                                                {clienteCheckOptions}
                                                {/* <div className={styles["twoColumnMenu"]}>
                                                    <div>
                                                        {clienteCheckOptions.slice(0, 2)}
                                                    </div>
                                                    <div>
                                                        {clienteCheckOptions.slice(2)}
                                                    </div>
                                                </div> */}
                                            </>
                                        )}
                                    </div>
                                </div>
                            </OutsideClickHandler>
                        ) : ''}
                    </div>
                ) : ''}
                {[].includes(tipo) ? (
                    <div className={styles["menuOptionMainContainer"]}>
                        <div className={styles["menuOptionContainer"]}>
                            <span className={styles["aligHelper"]}></span>
                            <img className={styles["sectionIconB"]} src='./images/iconosMenu/categorias.png' alt=''></img>
                            <span className={styles["sectionTextB"]}>Categorías</span>
                            <span className={styles["menuArrow"]}>›</span>
                        </div>
                    </div>
                ) : ''}
                {['A', 'B', 'C', 'E', 'F'].includes(tipo) ? (
                    <div className={styles["menuOptionMainContainer"]}>
                        <div className={styles["menuOptionContainer"]} onClick={() => { this.setState({ showMarca: true }); }}>
                            <span className={styles["aligHelper"]}></span>
                            <img className={styles["sectionIconB"]} src='./images/iconosMenu/marcas.png' alt=''></img>
                            <span className={styles["sectionTextB"]}>Marca</span>
                            <span className={styles["menuArrow"]}>›</span>
                        </div>
                        {showMarca ? (
                            <OutsideClickHandler onOutsideClick={() => { this.setState({ showMarca: false }); }}>
                                <div className={styles["menuInfoContainer"]}>
                                    <div className={styles["menuInfoTitleContainer"]}>
                                        <div className={styles["menuInfoTitle"]}>Marcas</div>
                                        <div className={styles["menuInfoTitleExtra"]}>
                                            <div className={styles["menuInfoTitleExtraA"]}>{apiData.marcas.length}</div>
                                            <div className={styles["menuInfoTitleExtraB"]}>TOTALES</div>
                                        </div>
                                    </div>
                                    <div className={styles["menuInfoChecklistContainer"]} onChange={this.props.handleChangeMarca}>
                                        <div className={styles["menuInfoCheckAllContainer"]}>
                                            <label className={styles["checkmarkContainer"]} key={Math.random()}>
                                                {marcasShown.length === apiData.marcas.length ? 'Deseleccionar todos' : 'Seleccionar todos'}
                                                <input type="checkbox" name='checkmarkCliente' value={'all'} defaultChecked={marcasShown.length === apiData.marcas.length}></input>
                                                <span className={styles["checkmark"]}></span>
                                            </label>
                                        </div>
                                        <div className={styles["twoColumnMenu"]}>
                                            <div>
                                                {marcasCheckOptions.slice(0, 3)}
                                            </div>
                                            <div>
                                                {marcasCheckOptions.slice(3)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </OutsideClickHandler>
                        ) : ''}
                    </div>
                ) : ''}
                {['A', 'B', 'C', 'E', 'F'].includes(tipo) ? (
                    <div className={styles["menuOptionMainContainer"]}>
                        <div className={styles["menuOptionContainer"]} style={{ borderBottom: '1px solid black' }} onClick={() => { this.setState({ showSKU: true }); }}>
                            <span className={styles["aligHelper"]}></span>
                            <img className={styles["sectionIconB"]} src='./images/iconosMenu/sku.png' alt=''></img>
                            <span className={styles["sectionTextB"]}>SKU</span>
                            <span className={styles["menuArrow"]}>›</span>
                        </div>
                        {showSKU ? (
                            <OutsideClickHandler onOutsideClick={() => { this.setState({ showSKU: false }); }}>
                                <div className={styles["menuInfoContainer"]} style={{ width: '40vw' }}>
                                    <div className={styles["menuInfoTitleContainer"]}>
                                        <div className={styles["menuInfoTitle"]}>SKU</div>
                                        <div className={styles["menuInfoTitleExtra"]}>
                                            <div className={styles["menuInfoTitleExtraA"]}>{apiData.productos.length}</div>
                                            <div className={styles["menuInfoTitleExtraB"]}>TOTALES</div>
                                        </div>
                                    </div>
                                    <div className={styles["menuInfoChecklistContainer"]} onChange={this.props.handleChangeProducto}>
                                        <div className={styles["menuInfoCheckAllContainer"]}>
                                            <label className={styles["checkmarkContainer"]} key={Math.random()}>
                                                {productosShown.length === apiData.productos.length ? 'Deseleccionar todos' : 'Seleccionar todos'}
                                                <input type="checkbox" name='checkmarkCliente' value={'all'} defaultChecked={productosShown.length === apiData.productos.length}></input>
                                                <span className={styles["checkmark"]}></span>
                                            </label>
                                        </div>
                                        {productosCheckOptions}
                                    </div>
                                </div>
                            </OutsideClickHandler>
                        ) : ''}
                    </div>
                ) : ''}
                {['D'].includes(tipo) ? (
                    <div className={styles["capasContainer"]}>
                        <div className={styles["sectionTitleA"]}>
                            <span className={styles["aligHelper"]}></span>
                            <img className={styles["sectionIconA"]} src='./images/iconosMenu/capas.png' alt=''></img>
                            <span className={styles["sectionTextA"]}>Capas</span>
                        </div>
                        <div className={styles["capasOption"]}>
                            <div className={styles["capasOptionTitle"]}>IDSU</div>
                            <div className={styles["switchContainer"]}>
                                <Switch onChange={this.props.handleChangeIDSU} checked={checkedIDSU}
                                    checkedIcon={false} uncheckedIcon={false} />
                            </div>
                        </div>
                        <div className={styles["capasOption"]}>
                            <div className={styles["capasOptionTitle"]}>Clasificación AMAI</div>
                            <div className={styles["switchContainer"]}>
                                <Switch onChange={this.props.handleChangeAMAI} checked={checkedAMAI}
                                    checkedIcon={false} uncheckedIcon={false} />
                            </div>
                        </div>
                        {/* <div className={styles["capasOption"]}>
                            <div className={styles["capasOptionTitle"]}>Densidad Poblacional</div>
                            <div className={styles["switchContainer"]}>
                                <Switch onChange={this.props.handleChangeDensidad} checked={checkedDensidad}
                                    checkedIcon={false} uncheckedIcon={false} />
                            </div>
                        </div> */}
                    </div>
                ) : ''}
                <div className={styles["reiniciarContainer"]}>
                    <div className={styles["reiniciarButton"]} onClick={this.props.restartFilter}>Reiniciar</div>
                </div>
            </div>
        );
    }
}

export default Filtro;