import React, { Component } from "react";
import _ from 'lodash';

import styles from "./TooltipRanking.module.scss";
import { formatNumberWithCommas } from "../../scripts/helpers";

class TooltipRanking extends Component {
    constructor(props) {
        super(props);
        this.state = {
            legend: <></>,
        };
    }

    /* componentDidMount() {
        this.createLegend();
    } */

    componentDidUpdate(prevProps) {
        if (!_.isEqual(prevProps.lineGraphData, this.props.lineGraphData)) {
            this.createLegend();
        }
    }

    createLegend() {
        const { clientesShown, lineGraphData, volumenValor } = this.props;
        const { cadenas, sucursales } = this.props.apiData;

        let cadenasToShow = clientesShown.map(idCadena => {
            let cadenaObj = cadenas.find(c => c.id === idCadena);
            return { id: cadenaObj.id, nombre: cadenaObj.nombre, color: cadenaObj.color };
        });
        let lineGraphDataWithIDCadena = lineGraphData.map(d => {
            let idCadena = sucursales.find(s => s.nombre === d.name).idCadena;
            return { ...d, idCadena };
        });

        let isLeft = false;

        let legend = <div className={styles["legendContainer"]}>
            {cadenasToShow.map((c, i) => {
                if (i % 4 === 0) isLeft = !isLeft;
                let sucursalesInCadena = lineGraphDataWithIDCadena.filter(d => d.idCadena === c.id);

                return (
                    <div key={`sucursalTooltip_${i}`} className={styles["legendElement"]}>
                        <div className={styles["elementColor"]} style={{ backgroundColor: c.color }}></div>
                        <div className={styles["elementTitle"]}>{c.nombre}</div>
                        <div className={styles["tooltipContainer"]}>
                            <img className={styles["elementImage"]} src="./images/iconosMenu/info.svg" alt=""></img>
                            <div className={styles["elementTooltip"]} style={isLeft ? { left: '105%' } : { right: '105%' }}>
                                <div className={styles["tooltipSubcontainer"]}>
                                    {sucursalesInCadena.map((s, ii) => {
                                        let sucursalTotal = _.sumBy(s.data, (valor) => { return parseFloat(valor) });

                                        return (
                                            <div key={`sucursalTooltip_${i}_${ii}`} className={styles["subElementContainer"]}>
                                                <div className={styles["subElementColor"]} style={{ backgroundColor: s.color }}></div>
                                                <div className={styles["subElementTitle"]}>{s.name}</div>
                                                <div className={styles["subElementTotal"]}>{volumenValor === 0 ? '' : '$'}{formatNumberWithCommas(sucursalTotal.toFixed((sucursalTotal < 1 && sucursalTotal !== 0) ? 2 : 0))}</div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>

        this.setState({ legend });
    }

    render() {
        const { legend } = this.state;

        return (
            <div className={styles["componentContainer"]}>
                {legend}
            </div>
        );
    }
}

export default TooltipRanking;