import { format, isSameDay } from 'date-fns';
import { es } from 'date-fns/esm/locale';

export const formatNumberWithCommas = (number) => {
  if (number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else {
    return number;
  }
};

export const getHost = (request) => {
  switch (request) {
    case "data":
      return window.location.hostname === "localhost"
        ? "http://localhost:3333"
        : "http://3.15.119.20";
    case "auth":
      return "http://3.18.58.69";
    default:
      return null;
  }
};

export const getAuth = () => {
  const token = localStorage.getItem("token");
  return { Authorization: `JWT ${token}` };
};

export const getCanalSubtitle = (radioValue, apiData) => {
  const hostname = window.location.hostname;
  let value = '';

  if (['locotequila.datalabmx.com'].includes(hostname)) {
    if (radioValue === 0) value = 'Todos';
    else if (apiData.canales) value = apiData.canales.find(c => c.id == radioValue).nombre;
  } else {
    value = radioValue === 0 ? 'Todos' : (radioValue === 2 ? 'Canal Moderno' : (radioValue === 3 ? 'Telemarketing' : (radioValue === 4 ? 'E-Commerce' : '')));
  }

  return value;
};

export const getYTDText = (date, isAcumulado) => {
  let today = new Date();
  let firstDayOfYear = new Date(today.getFullYear(), 0, 1);
  let value = '';

  if ((isSameDay(date[0], firstDayOfYear)) && (today.getMonth() <= date[1].getMonth())) value = 'Year to Date (YTD)';
  else {
    let firstDateText = format(date[0], 'dd/MMMM/yyyy', { locale: es });
    let secondDateText = format(date[1], 'dd/MMMM/yyyy', { locale: es });
    let acumuladoText = (isAcumulado && (date[0].getFullYear() !== date[1].getFullYear())) ? ' (Acumulado)' : '';

    value = `Del ${firstDateText} al ${secondDateText}${acumuladoText}`;
  }

  return value;
};

export const getRegionName = (region) => {
  let regionName = '';
  switch (region) {
    case 1: regionName = 'Región I'; break;
    case 2: regionName = 'Región II'; break;
    case 3: regionName = 'Región III'; break;
    case 4: regionName = 'Región IV'; break;
    case 5: regionName = 'Región V'; break;
    case 6: regionName = 'Región VI'; break;
    case 0: regionName = 'Sin Región'; break;
    default: regionName = 'N/A';
  }

  return regionName;
}

export const romanizarNumero = (num) => {
  if (isNaN(num))
    return NaN;
  var digits = String(+num).split(""),
    key = ["", "C", "CC", "CCC", "CD", "D", "DC", "DCC", "DCCC", "CM",
      "", "X", "XX", "XXX", "XL", "L", "LX", "LXX", "LXXX", "XC",
      "", "I", "II", "III", "IV", "V", "VI", "VII", "VIII", "IX"],
    roman = "",
    i = 3;
  while (i--)
    roman = (key[+digits.pop() + (i * 10)] || "") + roman;
  return Array(+digits.join("") + 1).join("M") + roman;
}