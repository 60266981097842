import React, { Component } from "react";
import { connect } from "react-redux";
import axios from 'axios';
import _ from 'lodash';

import Header from "../header/Header";
import Filtro from "../filtro/Filtro";
// import FiltroTreemap from '../filtrotreemap/FiltroTreemap';
import styles from "./Mapa.module.scss";

import { grayMapStyle } from './extras/grayMapStyle';
import { ventilesStyle, AMAIStyle, densidadStyle, iconoPuntoVenta } from './extras/stylingFunctions';
import { formatNumberWithCommas } from "../../scripts/helpers";
import { dataAction, filterDataAction } from "../../actions";

class Mapa extends Component {
    constructor(props) {
        super(props);
        this.state = {
            simbologiaInfo: null,
            // ======================
            checkedIDSU: false,
            checkedAMAI: false,
            checkedDensidad: false,
            calendarDate: [new Date(2022, 0, 1), new Date(2022, 1, 31)],
            shownCadenasMapa: [],
            // =====================
            sucursalesMarkerArray: [],
            infoCDMXGeoJSON: null,
            sucursalElegida: null,
            infoVentasSucursales: [],
            showSimbologia: null,
        };

        this.handleChangeIDSU = this.handleChangeIDSU.bind(this);
        this.handleChangeAMAI = this.handleChangeAMAI.bind(this);
        this.handleChangeDensidad = this.handleChangeDensidad.bind(this);
        this.handleChangeCalendar = this.handleChangeCalendar.bind(this);
        this.handleChangeShownCadenasMapa = this.handleChangeShownCadenasMapa.bind(this);
        this.restartFilter = this.restartFilter.bind(this);
        this.closeSucursal = this.closeSucursal.bind(this);
        this.toggleSimbologia = this.toggleSimbologia.bind(this);
    }

    async initMap() {
        let hostname = window.location.hostname;
        let stateColor, initialCenter, initialZoom, stateURL, overlayURL, ptosURL;

        const root = document.documentElement;
        root.style.setProperty('--calendarSelectedRangeColor', '#CCCCCC');

        let shownCadenasMapa = this.props.data.cadenas.map(c => c.nombre);

        switch (hostname) {
            case 'localhost':
                stateColor = '#363A43';
                initialCenter = { lat: 23.6253, lng: -102.0054 };
                initialZoom = 6;
                stateURL = 'mexico.json';
                overlayURL = 'overlay_general.geojson';
                ptosURL = 'sucursalesMonteXanic.geojson';
                break;
            case 'corporacionrica.datalabmx.com':
                stateColor = '#333333';
                initialCenter = { lat: 23.6253, lng: -102.0054 };
                initialZoom = 6;
                stateURL = 'mexico.json';
                overlayURL = 'overlay_general.geojson';
                ptosURL = 'sucursalesMonteXanic.geojson';
                break;
            case 'locotequila.datalabmx.com':
                stateColor = '#772835';
                initialCenter = { lat: 23.6253, lng: -102.0054 };
                initialZoom = 6;
                stateURL = 'mexico.json';
                overlayURL = 'overlay_general.geojson';
                ptosURL = 'sucursalesMonteXanic.geojson';
                break;
            case 'cbrands.datalabmx.com':
                stateColor = '#001E5B';
                initialCenter = { lat: 23.6253, lng: -102.0054 };
                initialZoom = 6;
                stateURL = 'mexico.json';
                overlayURL = 'overlay_general.geojson';
                ptosURL = 'sucursalesMonteXanic.geojson';
                break;
            case 'alianza.datalabmx.com':
                stateColor = '#68476C';
                initialCenter = { lat: 19.32165, lng: -99.1599 };
                initialZoom = 11;
                stateURL = 'cdmx.geojson';
                overlayURL = 'overlay_cdmx.geojson';
                ptosURL = 'sucursalesDemo3.geojson';
                break;
            default:
                stateColor = '#68476C';
                initialCenter = { lat: 19.32165, lng: -99.1599 };
                initialZoom = 11;
                stateURL = 'cdmx.geojson';
                overlayURL = 'overlay_cdmx.geojson';
                ptosURL = 'sucursalesDemo3.geojson';
        }

        // ========================================================

        let ventasData = [...this.props.data.ventas];
        ventasData = ventasData.filter(v => v.fechaFormato >= this.state.calendarDate[0] && v.fechaFormato <= this.state.calendarDate[1]);
        ventasData = _.groupBy(ventasData, 'idSucursal');
        let infoVentasSucursales = Object.keys(ventasData).map(idSucursal => {
            let keyInfo = ventasData[idSucursal];
            let ventasMXN = _.sumBy(keyInfo, 'ventas_mxn').toFixed(0);
            let ventasUnidades = _.sumBy(keyInfo, 'ventas_unidades');

            return { idSucursal, ventasMXN, ventasUnidades }
        });

        // ========================================================

        let map = new window.google.maps.Map(document.getElementById('mapContainer'), {
            center: initialCenter,
            zoom: initialZoom,
            styles: grayMapStyle,
            gestureHandling: 'cooperative',
            fullscreenControl: false,
        });

        let promiseA = this.loadGeoJSON(`./geodata/${stateURL}`, { idPropertyName: "cvegeo" });
        promiseA.then((municipiosGeoJSON) => {
            console.log('¡Municipios Cargados!');
            municipiosGeoJSON.setStyle({
                fillOpacity: 0,
                strokeColor: stateColor,
                strokeWeight: 3,
                zIndex: 5,
                clickable: false,
            });
            municipiosGeoJSON.setMap(map);

            let promiseB = this.loadGeoJSON(`./geodata/${overlayURL}`, {});
            promiseB.then((overlayGeoJSON) => {
                console.log('¡Overlay Cargado!');
                overlayGeoJSON.setStyle({
                    fillColor: 'black',
                    fillOpacity: 0.2,
                    strokeOpacity: 0,
                    zIndex: 99,
                    clickable: false,
                });
                overlayGeoJSON.setMap(map);

                const sucursales = this.props.data.sucursales;
                let sucursalesMarkerArray = [];
                sucursales.forEach(s => {
                    let marker = new window.google.maps.Marker({
                        position: { lat: parseFloat(s.lat), lng: parseFloat(s.lng) },
                        map: map,
                        icon: {
                            url: iconoPuntoVenta(s.cadena),
                            scaledSize: new window.google.maps.Size(28, 28),
                            anchor: new window.google.maps.Point(14, 14),
                        },
                        cadena: s.cadena,
                    });
                    marker.addListener('click', event => {
                        // console.log('¡Está hecho!', s);
                        this.setState({ sucursalElegida: s });
                    });
                    sucursalesMarkerArray.push(marker);
                });

                // let promiseD = this.loadGeoJSON("./geodata/infoCDMX.geojson", {});
                let promiseD = this.loadGeoJSON("./geodata/gridConDatos.geojson", {});
                promiseD.then((infoCDMXGeoJSON) => {
                    // console.log('¡InfoCDMX Cargado!');
                    infoCDMXGeoJSON.setStyle({ visible: false });
                    infoCDMXGeoJSON.setMap(map);

                    // Guardar todos los GeoJSON's en el state
                    this.setState({ infoCDMXGeoJSON, sucursalesMarkerArray, shownCadenasMapa, infoVentasSucursales });
                });
                promiseD.catch((error) => { console.log('Ha ocurrido un error en la carga de la info de CDMX', error); });
            });
            promiseB.catch((error) => { console.log('Ha ocurrido un error en la carga del overlay', error); });
        });
        promiseA.catch((error) => { console.log('Ha ocurrido un error en la carga de los municipios', error); });
    }

    loadGeoJSON(url, options) {
        var promise = new Promise(function (resolve, reject) {
            try {
                let tmpData = new window.google.maps.Data();
                tmpData.loadGeoJson(url, options, (features) => {
                    resolve(tmpData);
                });
            } catch (e) {
                reject(e);
            }
        });

        return promise;
    }

    async componentDidMount() {
        if (_.isEmpty(this.props.data)) await this.props.dataAction();
        /* console.log('========== INFORMACIÓN EN MAPA ==========');
        console.log(this.props.data); */

        await this.initMap();
    }

    restartFilter() {
        this.setState({
            checkedIDSU: false,
            checkedAMAI: false,
            checkedDensidad: false,
            calendarDate: [new Date(2022, 0, 1), new Date(2022, 1, 31)],
            shownCadenasMapa: this.props.data.cadenas.map(c => c.nombre),
        });
    }

    closeSucursal() {
        this.setState({ sucursalElegida: null });
    }

    toggleSimbologia() {
        this.setState({ showSimbologia: !this.state.showSimbologia });
    }

    handleChangeIDSU(checkedIDSU) { this.setState({ checkedIDSU, checkedAMAI: false, checkedDensidad: false, showSimbologia: true }); }
    handleChangeAMAI(checkedAMAI) { this.setState({ checkedAMAI, checkedIDSU: false, checkedDensidad: false, showSimbologia: true }); }
    handleChangeDensidad(checkedDensidad) { this.setState({ checkedDensidad, checkedIDSU: false, checkedAMAI: false, showSimbologia: true }); }
    handleChangeCalendar(calendarDate) { this.setState({ calendarDate }); }
    handleChangeShownCadenasMapa(event) {
        const { shownCadenasMapa } = this.state;
        let cadena = event.target.value;
        let newValue = [];

        if (shownCadenasMapa.includes(cadena)) {
            newValue = [...shownCadenasMapa].filter(c => c !== cadena);
        } else {
            newValue = [...shownCadenasMapa];
            newValue.push(cadena);
        }

        this.setState({ shownCadenasMapa: newValue });
    }

    componentDidUpdate(prevProps, prevState) {
        const {
            checkedIDSU, checkedAMAI, checkedDensidad,
            shownCadenasMapa, calendarDate,
            infoCDMXGeoJSON, sucursalesMarkerArray, sucursalElegida,
        } = this.state;

        if ((prevState.checkedIDSU !== checkedIDSU) || (prevState.checkedAMAI !== checkedAMAI) || (prevState.checkedDensidad !== checkedDensidad)) {
            let simbologiaInfo = null;
            if (checkedIDSU) {
                infoCDMXGeoJSON.setStyle(f => {
                    // let ventil = f.getProperty('nvVentil_N');
                    let ventil = f.getProperty('idsu_nac');
                    let color = ventilesStyle(ventil);

                    return {
                        fillColor: color,
                        fillOpacity: 0.5,
                        strokeOpacity: 0.1,
                        strokeWeight: 1,
                        clickable: false,
                    };
                });
                simbologiaInfo = <div className={styles["simbologiaInfoContainer"]}>
                    <div className={styles["simbologiaTitle"]}>IDSU</div>
                    <div className={styles["simbologiaOption"]}>
                        <div className={styles["simbologiaIcon"]} style={{ backgroundColor: '#D7191C' }}></div>
                        <div className={styles["simbologiaText"]}>1</div>
                    </div>
                    <div className={styles["simbologiaOption"]}>
                        <div className={styles["simbologiaIcon"]} style={{ backgroundColor: '#DF382B' }}></div>
                        <div className={styles["simbologiaText"]}>2</div>
                    </div>
                    <div className={styles["simbologiaOption"]}>
                        <div className={styles["simbologiaIcon"]} style={{ backgroundColor: '#E75839' }}></div>
                        <div className={styles["simbologiaText"]}>3</div>
                    </div>
                    <div className={styles["simbologiaOption"]}>
                        <div className={styles["simbologiaIcon"]} style={{ backgroundColor: '#EF7748' }}></div>
                        <div className={styles["simbologiaText"]}>4</div>
                    </div>
                    <div className={styles["simbologiaOption"]}>
                        <div className={styles["simbologiaIcon"]} style={{ backgroundColor: '#F79656' }}></div>
                        <div className={styles["simbologiaText"]}>5</div>
                    </div>
                    <div className={styles["simbologiaOption"]}>
                        <div className={styles["simbologiaIcon"]} style={{ backgroundColor: '#FDB266' }}></div>
                        <div className={styles["simbologiaText"]}>6</div>
                    </div>
                    <div className={styles["simbologiaOption"]}>
                        <div className={styles["simbologiaIcon"]} style={{ backgroundColor: '#FEC37A' }}></div>
                        <div className={styles["simbologiaText"]}>7</div>
                    </div>
                    <div className={styles["simbologiaOption"]}>
                        <div className={styles["simbologiaIcon"]} style={{ backgroundColor: '#FED48E' }}></div>
                        <div className={styles["simbologiaText"]}>8</div>
                    </div>
                    <div className={styles["simbologiaOption"]}>
                        <div className={styles["simbologiaIcon"]} style={{ backgroundColor: '#FEE5A1' }}></div>
                        <div className={styles["simbologiaText"]}>9</div>
                    </div>
                    <div className={styles["simbologiaOption"]}>
                        <div className={styles["simbologiaIcon"]} style={{ backgroundColor: '#FFF6B5' }}></div>
                        <div className={styles["simbologiaText"]}>10</div>
                    </div>
                    <div className={styles["simbologiaOption"]}>
                        <div className={styles["simbologiaIcon"]} style={{ backgroundColor: '#F6FBC3' }}></div>
                        <div className={styles["simbologiaText"]}>11</div>
                    </div>
                    <div className={styles["simbologiaOption"]}>
                        <div className={styles["simbologiaIcon"]} style={{ backgroundColor: '#E4F3CC' }}></div>
                        <div className={styles["simbologiaText"]}>12</div>
                    </div>
                    <div className={styles["simbologiaOption"]}>
                        <div className={styles["simbologiaIcon"]} style={{ backgroundColor: '#D3EBD5' }}></div>
                        <div className={styles["simbologiaText"]}>13</div>
                    </div>
                    <div className={styles["simbologiaOption"]}>
                        <div className={styles["simbologiaIcon"]} style={{ backgroundColor: '#C1E3DE' }}></div>
                        <div className={styles["simbologiaText"]}>14</div>
                    </div>
                    <div className={styles["simbologiaOption"]}>
                        <div className={styles["simbologiaIcon"]} style={{ backgroundColor: '#AFDBE7' }}></div>
                        <div className={styles["simbologiaText"]}>15</div>
                    </div>
                    <div className={styles["simbologiaOption"]}>
                        <div className={styles["simbologiaIcon"]} style={{ backgroundColor: '#97CAE1' }}></div>
                        <div className={styles["simbologiaText"]}>16</div>
                    </div>
                    <div className={styles["simbologiaOption"]}>
                        <div className={styles["simbologiaIcon"]} style={{ backgroundColor: '#7CB6D6' }}></div>
                        <div className={styles["simbologiaText"]}>17</div>
                    </div>
                    <div className={styles["simbologiaOption"]}>
                        <div className={styles["simbologiaIcon"]} style={{ backgroundColor: '#61A3CB' }}></div>
                        <div className={styles["simbologiaText"]}>18</div>
                    </div>
                    <div className={styles["simbologiaOption"]}>
                        <div className={styles["simbologiaIcon"]} style={{ backgroundColor: '#478FC1' }}></div>
                        <div className={styles["simbologiaText"]}>19</div>
                    </div>
                    <div className={styles["simbologiaOption"]}>
                        <div className={styles["simbologiaIcon"]} style={{ backgroundColor: '#2C7BB6' }}></div>
                        <div className={styles["simbologiaText"]}>20</div>
                    </div>
                </div>
            } else if (checkedAMAI) {
                infoCDMXGeoJSON.setStyle(f => {
                    // let amai = f.getProperty('ind_AMAI_N');
                    let amai = f.getProperty('amai_nac');
                    let color = AMAIStyle(amai);

                    return {
                        fillColor: color,
                        fillOpacity: 0.5,
                        strokeOpacity: 0.1,
                        strokeWeight: 1,
                        clickable: false,
                    };
                });
                simbologiaInfo = <div className={styles["simbologiaInfoContainer"]}>
                    <div className={styles["simbologiaTitle"]}>Clasificación AMAI</div>
                    <div className={styles["simbologiaOption"]}>
                        <div className={styles["simbologiaIcon"]} style={{ backgroundColor: '#c22f5f' }}></div>
                        <div className={styles["simbologiaText"]}>A/B</div>
                    </div>
                    <div className={styles["simbologiaOption"]}>
                        <div className={styles["simbologiaIcon"]} style={{ backgroundColor: '#7241a2' }}></div>
                        <div className={styles["simbologiaText"]}>C+</div>
                    </div>
                    <div className={styles["simbologiaOption"]}>
                        <div className={styles["simbologiaIcon"]} style={{ backgroundColor: '#2d5381' }}></div>
                        <div className={styles["simbologiaText"]}>C</div>
                    </div>
                    <div className={styles["simbologiaOption"]}>
                        <div className={styles["simbologiaIcon"]} style={{ backgroundColor: '#a4bcd8' }}></div>
                        <div className={styles["simbologiaText"]}>C-</div>
                    </div>
                    <div className={styles["simbologiaOption"]}>
                        <div className={styles["simbologiaIcon"]} style={{ backgroundColor: '#508ea3' }}></div>
                        <div className={styles["simbologiaText"]}>D+</div>
                    </div>
                    <div className={styles["simbologiaOption"]}>
                        <div className={styles["simbologiaIcon"]} style={{ backgroundColor: '#a9c16f' }}></div>
                        <div className={styles["simbologiaText"]}>D</div>
                    </div>
                    <div className={styles["simbologiaOption"]}>
                        <div className={styles["simbologiaIcon"]} style={{ backgroundColor: '#fefd88' }}></div>
                        <div className={styles["simbologiaText"]}>E</div>
                    </div>
                </div>
            } else if (checkedDensidad) {
                infoCDMXGeoJSON.setStyle(f => {
                    let dp = f.getProperty('lnkm2');
                    let color = densidadStyle(dp);

                    return {
                        fillColor: color,
                        fillOpacity: 0.5,
                        strokeOpacity: 0.1,
                        strokeWeight: 1,
                        clickable: false,
                    };
                });
                simbologiaInfo = <div className={styles["simbologiaInfoContainer"]}>
                    <div className={styles["simbologiaTitle"]}>Densidad Poblacional</div>
                    <div className={styles["simbologiaOption"]}>
                        <div className={styles["simbologiaIcon"]} style={{ backgroundColor: '#ffcbb3' }}></div>
                        <div className={styles["simbologiaText"]}>0 - 1K</div>
                    </div>
                    <div className={styles["simbologiaOption"]}>
                        <div className={styles["simbologiaIcon"]} style={{ backgroundColor: '#ffb08e' }}></div>
                        <div className={styles["simbologiaText"]}>1K - 8K</div>
                    </div>
                    <div className={styles["simbologiaOption"]}>
                        <div className={styles["simbologiaIcon"]} style={{ backgroundColor: '#ff9569' }}></div>
                        <div className={styles["simbologiaText"]}>8K - 15K</div>
                    </div>
                    <div className={styles["simbologiaOption"]}>
                        <div className={styles["simbologiaIcon"]} style={{ backgroundColor: '#ff7c43' }}></div>
                        <div className={styles["simbologiaText"]}>15K - 25K</div>
                    </div>
                    <div className={styles["simbologiaOption"]}>
                        <div className={styles["simbologiaIcon"]} style={{ backgroundColor: '#bf5d31' }}></div>
                        <div className={styles["simbologiaText"]}>25K - 55K</div>
                    </div>
                    <div className={styles["simbologiaOption"]}>
                        <div className={styles["simbologiaIcon"]} style={{ backgroundColor: '#803e23' }}></div>
                        <div className={styles["simbologiaText"]}>55K - 127K</div>
                    </div>
                </div>;
            } else { infoCDMXGeoJSON.setStyle({ visible: false }); }

            this.setState({ simbologiaInfo });
        }
        if (!_.isEqual(prevState.shownCadenasMapa, shownCadenasMapa)) {
            sucursalesMarkerArray.forEach(m => {
                if (shownCadenasMapa.includes(m.cadena)) m.setVisible(true);
                else m.setVisible(false);
            });

            if (sucursalElegida && !shownCadenasMapa.includes(sucursalElegida.cadena)) this.setState({ sucursalElegida: null });

        }
        if (!_.isEqual(prevState.calendarDate, calendarDate)) {
            let ventasData = [...this.props.data.ventas];
            ventasData = ventasData.filter(v => v.fechaFormato >= calendarDate[0] && v.fechaFormato <= calendarDate[1]);
            ventasData = _.groupBy(ventasData, 'idSucursal');
            let infoVentasSucursales = Object.keys(ventasData).map(idSucursal => {
                let keyInfo = ventasData[idSucursal];
                let ventasMXN = _.sumBy(keyInfo, 'ventas_mxn').toFixed(0);
                let ventasUnidades = _.sumBy(keyInfo, 'ventas_unidades');

                return { idSucursal, ventasMXN, ventasUnidades }
            });

            this.setState({ infoVentasSucursales });
        }
    }

    render() {
        const {
            simbologiaInfo,
            checkedIDSU, checkedAMAI, checkedDensidad,
            calendarDate, shownCadenasMapa,
            sucursalElegida, showSimbologia,
            infoCDMXGeoJSON, infoVentasSucursales,
        } = this.state;

        let labelName = '';
        let labelColor = '';
        let ventasMXNSucursal = '';
        let ventasUnidadesSucursal = '';
        let fechaInLabel = '';
        if (sucursalElegida) {
            switch (sucursalElegida.cadena) {
                case 'Bodegas Alianza':
                    labelName = 'Bodegas Alianza'; labelColor = '#270f2b';
                    break;
                case 'SAMS':
                    labelName = "Sam's Club"; labelColor = '#12689a';
                    break;
                case "Sam's Club":
                    labelName = "Sam's Club"; labelColor = '#12689a';
                    break;
                case 'WAL-MART SUPERCENTER':
                    labelName = 'WalMart'; labelColor = '#1b75cf';
                    break;
                case 'La Europea':
                    labelName = 'La Europea'; labelColor = '#003468';
                    break;
                case "Liverpool":
                    labelName = "Liverpool"; labelColor = '#E3418B';
                    break;
                case "Costco":
                    labelName = "Costco"; labelColor = '#E31837';
                    break;
                case "Palacio de Hierro":
                    labelName = "Palacio de Hierro"; labelColor = '#FFC328';
                    break;
                default:
                    labelName = 'Otro'; labelColor = '#dbdce0';
            }

            let infoSucursalElegida = infoVentasSucursales.find(i => i.idSucursal === sucursalElegida.id);
            ventasMXNSucursal = infoSucursalElegida ? formatNumberWithCommas(infoSucursalElegida.ventasMXN) : '0';
            ventasUnidadesSucursal = infoSucursalElegida ? formatNumberWithCommas(infoSucursalElegida.ventasUnidades) : '0';

            let firstYear = calendarDate[0].getFullYear();
            let secondYear = calendarDate[1].getFullYear();
            fechaInLabel = firstYear !== secondYear ? `De ${firstYear} a ${secondYear}`: `${firstYear}`;
        }

        if (_.isEmpty(this.props.data)) {
            return (
                <div className={styles["loadingContainerSP"]}>
                    <span className={styles["aligHelper"]}></span>
                    <object
                        className={styles["loading"]}
                        data="./images/fact_loading_sp.svg"
                        type="image/svg+xml"
                    >
                        <img
                            src="./images/fact_loading_sp.svg"
                            alt="Cargando contenido"
                        />
                    </object>
                </div>
            );
        } else {
            return (
                <div className={styles["mainContainer"]}>
                    <Header
                        history={this.props.history}
                    />
                    <div className={styles["subContainer"]} style={{ gridTemplateColumns: sucursalElegida ? '15.625% 52.375% 32%' : '15.625% 84.375%' }}>
                        {!_.isEmpty(this.props.data) ? (
                            <Filtro
                                tipo={'D'}
                                checkedIDSU={checkedIDSU}
                                handleChangeIDSU={this.handleChangeIDSU}
                                checkedAMAI={checkedAMAI}
                                handleChangeAMAI={this.handleChangeAMAI}
                                checkedDensidad={checkedDensidad}
                                handleChangeDensidad={this.handleChangeDensidad}
                                calendarDate={calendarDate}
                                handleChangeCalendar={this.handleChangeCalendar}
                                shownCadenasMapa={shownCadenasMapa}
                                handleChangeShownCadenasMapa={this.handleChangeShownCadenasMapa}
                                // ====================================================
                                apiData={this.props.data}
                                restartFilter={this.restartFilter}
                            />
                        ) : ''}
                        <div className={styles["mapMainContainer"]}>
                            <div className={styles["loadingContainer"]} style={{ display: infoCDMXGeoJSON ? 'none' : 'block' }}>
                                <span className={styles["aligHelper"]}></span>
                                <object
                                    className={styles["loading"]}
                                    data="./images/fact_loading_sp.svg"
                                    type="image/svg+xml"
                                >
                                    <img
                                        src="./images/fact_loading_sp.svg"
                                        alt="Cargando contenido"
                                    />
                                </object>
                            </div>
                            <div id='mapContainer' className={styles["mapContainer"]} style={{ display: infoCDMXGeoJSON ? 'block' : 'none' }}></div>
                            {sucursalElegida ? (
                                <div className={styles["closeContainer"]} onClick={this.closeSucursal}>›</div>
                            ) : ''}
                        </div>
                        {sucursalElegida ? (
                            <div className={styles["infoContainer"]}>
                                <div className={styles["infoTitleMainContainer"]}>
                                    <div className={styles["infoTitleImgContainer"]}>
                                        <img className={styles["infoTitleImg"]} src='./images/iconosMenu/ubicacion.png' alt=''></img>
                                    </div>
                                    <div className={styles["infoTitleSubcontainer"]}>
                                        <div className={styles["infoTitle"]}>{sucursalElegida.nombre}</div>
                                        <div className={styles["infoSubtitle"]}>{fechaInLabel}</div>
                                    </div>
                                </div>
                                <div className={styles["infoNumberContainer"]}>
                                    <div className={styles["infoNumberSubcontainer_A"]}>
                                        <div className={styles["infoNumberSubcontainer_Title"]}>
                                            <span className={styles["aligHelper"]}></span>
                                            <img className={styles["infoNumberSubcontainer_Img"]} src='./images/iconosMenu/volumen.png' alt=''></img>
                                            <span style={{ verticalAlign: 'middle' }}>Volumen</span>
                                        </div>
                                        <div className={styles["infoNumberSubcontainer_Number"]}>{ventasUnidadesSucursal}</div>
                                    </div>
                                    <div className={styles["infoNumberSubcontainer_B"]}>
                                        <div className={styles["infoNumberSubcontainer_Title"]}>
                                            <span className={styles["aligHelper"]}></span>
                                            <img className={styles["infoNumberSubcontainer_Img"]} src='./images/iconosMenu/monto.png' alt=''></img>
                                            <span style={{ verticalAlign: 'middle' }}>Monto</span>
                                        </div>
                                        <div className={styles["infoNumberSubcontainer_Number"]}>${ventasMXNSucursal}</div>
                                    </div>
                                </div>
                                <div className={styles["infoIndicadoresContainer"]}>
                                    <div className={styles["infoIndicadoresContainer_Title"]}>Indicadores</div>
                                    {/* <div className={styles["infoIndicadoresContainer_Row"]}>
                                        <div className={styles["rowColor"]} style={{ backgroundColor: '#fa4a28' }}></div>
                                        <div className={styles["rowTitle"]}>
                                            <span className={styles["aligHelper"]}></span>
                                            <span style={{ verticalAlign: 'middle' }}>Densidad Poblacional</span>
                                        </div>
                                        <div className={styles["rowValue"]}>
                                            <span className={styles["aligHelper"]}></span>
                                            <span style={{ verticalAlign: 'middle' }}>394</span>
                                        </div>
                                    </div> */}
                                    <div className={styles["infoIndicadoresContainer_Row"]}>
                                        <div className={styles["rowColor"]} style={{ backgroundColor: AMAIStyle(sucursalElegida.amai_nac) }}></div>
                                        <div className={styles["rowTitle"]}>
                                            <span className={styles["aligHelper"]}></span>
                                            <span style={{ verticalAlign: 'middle' }}>Clasificación AMAI</span>
                                        </div>
                                        <div className={styles["rowValue"]}>
                                            <span className={styles["aligHelper"]}></span>
                                            <span style={{ verticalAlign: 'middle' }}>{sucursalElegida.amai_nac}</span>
                                        </div>
                                    </div>
                                    <div className={styles["infoIndicadoresContainer_Row"]}>
                                        <div className={styles["rowColor"]} style={{ backgroundColor: ventilesStyle(sucursalElegida.idsu_nac) }}></div>
                                        <div className={styles["rowTitle"]}>
                                            <span className={styles["aligHelper"]}></span>
                                            <span style={{ verticalAlign: 'middle' }}>IDSU</span>
                                        </div>
                                        <div className={styles["rowValue"]}>
                                            <span className={styles["aligHelper"]}></span>
                                            <span style={{ verticalAlign: 'middle' }}>{sucursalElegida.idsu_nac}</span>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className={styles["infoTitle"]}>
                                    <div>Información de Sucursal</div>
                                    <div style={{ textAlign: 'center' }}>
                                        <img src={iconoPuntoVenta(sucursalElegida.getProperty('cadena'))} alt='Ícono Sucursal' className={styles["iconoSucursal"]}></img>
                                        <div className={styles["infoTitleLabel"]} style={{ color: labelColor }}>{labelName}</div>
                                    </div>
                                </div>
                                {['localhost', 'corporacionrica.datalabmx.com', 'localhost', 'cbrands.datalabmx.com'].includes(window.location.hostname) ? (
                                    <div style={{ marginTop: '10px', textTransform: 'capitalize' }}><b>Nombre</b> — {sucursalElegida.getProperty('nombre')}</div>
                                ) : (
                                    <>
                                        <div style={{ marginTop: '10px', textTransform: 'capitalize' }}><b>Tipo</b> — {sucursalElegida.getProperty('tipo')}</div>
                                        <div style={{ marginTop: '10px', textTransform: 'capitalize' }}><b>Pto. de Venta</b> — {sucursalElegida.getProperty('nom_sucursal')}</div>
                                        <div style={{ marginTop: '10px', textTransform: 'capitalize' }}><b>Dirección</b> — {sucursalElegida.getProperty('direccion')}</div>
                                    </>
                                )}
                                {sucursalElegida.getProperty('cadena') === 'Bodegas Alianza' ? (
                                    <>
                                        <div style={{ marginTop: '10px', textTransform: 'capitalize' }}><b>Valor</b> — {`$${formatNumberWithCommas(sucursalElegida.getProperty('monto'))}`}</div>
                                        <div style={{ marginTop: '10px', textTransform: 'capitalize' }}><b>Volumen</b> — {`${formatNumberWithCommas(sucursalElegida.getProperty('volumen'))} Unidades`}</div>
                                    </>
                                ) : ''} */}
                            </div>
                        ) : ''}
                    </div>
                    {simbologiaInfo ? (
                        <div className={styles["simbologiaMainContainer"]}>
                            {showSimbologia ? simbologiaInfo : ''}
                            <div className={styles["simbologiaButton"]} onClick={this.toggleSimbologia}>
                                <span className={styles["aligHelper"]}></span>
                                <img src="./images/infoIcon.png" alt="Simbología" className={styles["simbologiaIcono"]}></img>
                            </div>
                        </div>
                    ) : ''}
                </div>
            );
        }
    }
}

function mapStateToProps(state) {
    return {
        data: state.data,
    };
}

export default connect(mapStateToProps, {
    dataAction,
    filterDataAction,
})(Mapa);