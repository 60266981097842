import React, { Component } from "react";
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import OutsideClickHandler from 'react-outside-click-handler';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Switch from "react-switch";

import styles from "./FiltroTreemap.module.scss";
import '../../extras/DateRangePicker.scss';
import '../../extras/react-tabs.scss';

class FiltroTreemap extends Component {
    constructor(props) {
        super(props);
        this.state = {
            calendarioTipo: '0',
            showCanales: false,
            canalTipo: '0',
            showCompanias: false,
            showCat: false,
            showMarca: false,
            showSKU: false,
            showPtosVentaA: false,
            showPtosVentaB: false,
        };

        this.handleChangeCalendarRadio = this.handleChangeCalendarRadio.bind(this);
        this.handleChangeCanalRadio = this.handleChangeCanalRadio.bind(this);
    }

    handleChangeCalendarRadio(event) {
        let calendarioTipo = event.target.value;
        this.setState({ calendarioTipo });
    }

    handleChangeCanalRadio(event) {
        let canalTipo = event.target.value;
        this.setState({ canalTipo });
    }

    render() {
        const { calendarioTipo, showCanales, showCompanias, showCat, showMarca, showSKU, showPtosVentaA, showPtosVentaB } = this.state;
        const { tipo, calendarDate, checkedIDSU, checkedAMAI, checkedDensidad, } = this.props;

        let calendarMaxDetail, calendarMinDetail, calendarFormat;
        switch (calendarioTipo) {
            case '0':
                calendarMaxDetail = 'decade';
                calendarMinDetail = 'decade';
                calendarFormat = 'y';
                break;
            case '1':
                calendarMaxDetail = 'year';
                calendarMinDetail = 'year';
                calendarFormat = 'MM/y';
                break;
            case '2':
                calendarMaxDetail = 'month';
                calendarFormat = 'dd/MM/y';
                break;
            default:
                calendarMaxDetail = 'decade';
                calendarMinDetail = 'year';
                calendarFormat = 'y';
        }

        return (
            <div className={styles["filterContainer"]}>
                <div className={styles["filterTitleContainer"]}>
                    <div className={styles["filterTitle"]}>FILTROS</div>
                    <div className={styles["reiniciarButton"]} /* onClick={this.props.restartFilter} */>Reset</div>
                </div>
                <div className={styles["sectionContainer"]}>
                    <div className={styles["fechaContainer"]}>
                        <div className={styles["filterSectionTitle"]}>FECHA</div>
                        <div className={styles["dateRadioTitle"]}>Seleccionar fechas por:</div>
                        <div onChange={this.handleChangeCalendarRadio}>
                            {['A', 'B', 'C', 'D'].includes(tipo) ? (
                                <label className={styles["radioButtonContainer"]} style={{ display: 'inline-block', fontSize: '12px' }}>
                                    <span className={styles["aligHelper"]}></span>
                                    <input type="radio" id="filterRadioDateA" name="filterDateOption" value={0} defaultChecked />
                                    <div className={styles["radioCheckmark"]}></div>
                                    <div className={styles["radioLabel"]}>Años</div>
                                </label>
                            ) : ''}
                            {['A', 'B'].includes(tipo) ? (
                                <label className={styles["radioButtonContainer"]} style={{ display: 'inline-block', fontSize: '12px', marginLeft: '5%' }}>
                                    <span className={styles["aligHelper"]}></span>
                                    <input type="radio" id="filterRadioDateB" name="filterDateOption" value={1} />
                                    <div className={styles["radioCheckmark"]}></div>
                                    <div className={styles["radioLabel"]}>Meses</div>
                                </label>
                            ) : ''}
                            {['B'].includes(tipo) ? (
                                <label className={styles["radioButtonContainer"]} style={{ display: 'inline-block', fontSize: '12px', marginLeft: '5%' }}>
                                    <span className={styles["aligHelper"]}></span>
                                    <input type="radio" id="filterRadioDateC" name="filterDateOption" value={2} />
                                    <div className={styles["radioCheckmark"]}></div>
                                    <div className={styles["radioLabel"]}>Días</div>
                                </label>
                            ) : ''}
                        </div>
                        <DateRangePicker
                            onChange={this.props.handleChangeCalendar}
                            value={calendarDate}
                            minDetail={calendarMinDetail}
                            maxDetail={calendarMaxDetail}
                            minDate={new Date(2011, 0, 1)}
                            maxDate={new Date(2022, 0, 31)}
                            className={"date-selector"}
                            clearIcon={null}
                            calendarIcon={null}
                            locale={'es-MX'}
                            format={calendarFormat}
                        />
                    </div>
                    {['A'].includes(tipo) ? (
                        <div className={styles["radioContainer"]}>
                            {/* <div className={styles["filterSectionTitle"]}>TIPO DE INFORMACIÓN A</div> */}
                            <div className={styles["radioSubcontainer"]} onChange={this.props.handleChangeRadio}>
                                <label className={styles["radioButtonContainer"]}>
                                    <span className={styles["aligHelper"]}></span>
                                    <input type="radio" id="filterRadioA" name="filterOption" value={0} defaultChecked />
                                    <div className={styles["radioCheckmark"]}></div>
                                    <div className={styles["radioLabel"]}>Volumen</div>
                                </label>
                                <label className={styles["radioButtonContainer"]}>
                                    <span className={styles["aligHelper"]}></span>
                                    <input type="radio" id="filterRadioB" name="filterOption" value={1} />
                                    <div className={styles["radioCheckmark"]}></div>
                                    <div className={styles["radioLabel"]}>Valor</div>
                                </label>
                                <label className={styles["radioButtonContainer"]}>
                                    <span className={styles["aligHelper"]}></span>
                                    <input type="radio" id="filterRadioC" name="filterOption" value={2} />
                                    <div className={styles["radioCheckmark"]}></div>
                                    <div className={styles["radioLabel"]}>Margen</div>
                                </label>
                            </div>
                        </div>
                    ) : ''}
                    {['C'].includes(tipo) ? (
                        <div className={styles["radioContainer"]}>
                            {/* <div className={styles["filterSectionTitle"]}>TIPO DE INFORMACIÓN A</div> */}
                            <div className={styles["radioSubcontainer"]} /* onChange={this.props.handleChangeRadio} */>
                                <label className={styles["radioButtonContainer"]}>
                                    <span className={styles["aligHelper"]}></span>
                                    <input type="radio" id="filterRadioSellA" name="filterRadioSell" value={0} defaultChecked />
                                    <div className={styles["radioCheckmark"]}></div>
                                    <div className={styles["radioLabel"]}>Sell-In</div>
                                </label>
                                <label className={styles["radioButtonContainer"]}>
                                    <span className={styles["aligHelper"]}></span>
                                    <input type="radio" id="filterRadioSellB" name="filterRadioSell" value={1} />
                                    <div className={styles["radioCheckmark"]}></div>
                                    <div className={styles["radioLabel"]}>Sell-Out</div>
                                </label>
                            </div>
                        </div>
                    ) : ''}
                    {['C'].includes(tipo) ? (
                        <div className={styles["radioContainer"]}>
                            {/* <div className={styles["filterSectionTitle"]}>TIPO DE INFORMACIÓN A</div> */}
                            <div className={styles["radioSubcontainer"]} onChange={this.props.handleChangeRadio}>
                                <label className={styles["radioButtonContainer"]}>
                                    <span className={styles["aligHelper"]}></span>
                                    <input type="radio" id="filterRadioA" name="filterOption" value={0} />
                                    <div className={styles["radioCheckmark"]}></div>
                                    <div className={styles["radioLabel"]}>Volumen</div>
                                </label>
                                <label className={styles["radioButtonContainer"]}>
                                    <span className={styles["aligHelper"]}></span>
                                    <input type="radio" id="filterRadioB" name="filterOption" value={1} defaultChecked />
                                    <div className={styles["radioCheckmark"]}></div>
                                    <div className={styles["radioLabel"]}>Valor</div>
                                </label>
                            </div>
                        </div>
                    ) : ''}
                    {['A', 'C'].includes(tipo) ? (
                        <div className={styles["radioContainer"]}>
                            <div className={styles["filterSectionTitle"]}>CANAL</div>
                            <div className={styles["radioSubcontainer"]} onChange={this.props.handleChangeRadioCanal}>
                                <label className={styles["radioButtonContainer"]}>
                                    <span className={styles["aligHelper"]}></span>
                                    <input type="radio" id="filterRadioCanalA" name="filterOptionCanal" value={0} defaultChecked />
                                    <div className={styles["radioCheckmark"]}></div>
                                    <div className={styles["radioLabel"]}>Todos</div>
                                </label>
                                <label className={styles["radioButtonContainer"]}>
                                    <span className={styles["aligHelper"]}></span>
                                    <input type="radio" id="filterRadioCanalB" name="filterOptionCanal" value={1} />
                                    <div className={styles["radioCheckmark"]}></div>
                                    <div className={styles["radioLabel"]}>Mayoreo</div>
                                </label>
                                <label className={styles["radioButtonContainer"]}>
                                    <span className={styles["aligHelper"]}></span>
                                    <input type="radio" id="filterRadioCanalC" name="filterOptionCanal" value={2} />
                                    <div className={styles["radioCheckmark"]}></div>
                                    <div className={styles["radioLabel"]}>Menudeo</div>
                                </label>
                                <label className={styles["radioButtonContainer"]}>
                                    <span className={styles["aligHelper"]}></span>
                                    <input type="radio" id="filterRadioCanalD" name="filterOptionCanal" value={3} />
                                    <div className={styles["radioCheckmark"]}></div>
                                    <div className={styles["radioLabel"]}>Telemarketing</div>
                                </label>
                                <label className={styles["radioButtonContainer"]}>
                                    <span className={styles["aligHelper"]}></span>
                                    <input type="radio" id="filterRadioCanalD" name="filterOptionCanal" value={4} />
                                    <div className={styles["radioCheckmark"]}></div>
                                    <div className={styles["radioLabel"]}>E-Commerce</div>
                                </label>
                                <label className={styles["radioButtonContainer"]}>
                                    <span className={styles["aligHelper"]}></span>
                                    <input type="radio" id="filterRadioCanalE" name="filterOptionCanal" value={5} />
                                    <div className={styles["radioCheckmark"]}></div>
                                    <div className={styles["radioLabel"]}>Off-Trade Mayoreo</div>
                                </label>
                                <label className={styles["radioButtonContainer"]}>
                                    <span className={styles["aligHelper"]}></span>
                                    <input type="radio" id="filterRadioCanalF" name="filterOptionCanal" value={6} />
                                    <div className={styles["radioCheckmark"]}></div>
                                    <div className={styles["radioLabel"]}>Off-Trade Moderno</div>
                                </label>
                                <label className={styles["radioButtonContainer"]}>
                                    <span className={styles["aligHelper"]}></span>
                                    <input type="radio" id="filterRadioCanalG" name="filterOptionCanal" value={7} />
                                    <div className={styles["radioCheckmark"]}></div>
                                    <div className={styles["radioLabel"]}>On-Trade E-Commerce</div>
                                </label>
                                <label className={styles["radioButtonContainer"]}>
                                    <span className={styles["aligHelper"]}></span>
                                    <input type="radio" id="filterRadioCanalH" name="filterOptionCanal" value={8} />
                                    <div className={styles["radioCheckmark"]}></div>
                                    <div className={styles["radioLabel"]}>Venta Directa</div>
                                </label>
                            </div>
                        </div>
                    ) : ''}
                    {['A', 'C'].includes(tipo) ? (
                        <div className={styles["radioContainer"]}>
                            {/* <div className={styles["filterSectionTitle"]}>TIPO DE INFORMACIÓN B</div> */}
                            <div className={styles["radioSubcontainer"]} onChange={this.props.handleChangeRadioAlt}>
                                <label className={styles["radioButtonContainer"]}>
                                    <span className={styles["aligHelper"]}></span>
                                    <input type="radio" id="filterRadioAltA" name="filterOptionAlt" value={0} defaultChecked />
                                    <div className={styles["radioCheckmark"]}></div>
                                    <div className={styles["radioLabel"]}>Clientes</div>
                                </label>
                                {tipo === 'A' ? (
                                    <label className={styles["radioButtonContainer"]}>
                                        <span className={styles["aligHelper"]}></span>
                                        <input type="radio" id="filterRadioAltB" name="filterOptionAlt" value={1} />
                                        <div className={styles["radioCheckmark"]}></div>
                                        <div className={styles["radioLabel"]}>Categoría</div>
                                    </label>
                                ) : ''}
                                <label className={styles["radioButtonContainer"]}>
                                    <span className={styles["aligHelper"]}></span>
                                    <input type="radio" id="filterRadioAltC" name="filterOptionAlt" value={2} />
                                    <div className={styles["radioCheckmark"]}></div>
                                    <div className={styles["radioLabel"]}>Marca</div>
                                </label>
                                <label className={styles["radioButtonContainer"]}>
                                    <span className={styles["aligHelper"]}></span>
                                    <input type="radio" id="filterRadioAltD" name="filterOptionAlt" value={3} />
                                    <div className={styles["radioCheckmark"]}></div>
                                    <div className={styles["radioLabel"]}>SKU</div>
                                </label>
                            </div>
                        </div>
                    ) : ''}
                    {[].includes(tipo) ? (
                        <div className={styles["buttonContainer"]}>
                            <div className={styles["filterSectionTitle"]}>ZONA GEOGRÁFICA</div>
                            <div className={styles["buttonArrow"]}>❯</div>
                        </div>
                    ) : ''}
                    {['C'].includes(tipo) ? (
                        <div className={styles["buttonMainContainer"]}>
                            <div className={styles["buttonContainer"]} onClick={() => { this.setState({ showCompanias: !showCompanias }) }}>
                                <div className={styles["filterSectionTitle"]}>{['alianza.datalabmx.com'].includes(window.location.hostname) ? 'COMPAÑIA' : 'CLIENTES'}</div>
                                <div className={styles["buttonArrow"]}>❯</div>
                            </div>
                            {showCompanias ? (
                                <OutsideClickHandler onOutsideClick={() => { this.setState({ showCompanias: false }); }}>
                                    <div className={styles["buttonInfoContainer"]}>
                                        <div className={styles["buttonInfoTitleContainer"]}>
                                            <div className={styles["buttonInfoTitle"]}>Clientes</div>
                                            <div className={styles["buttonInfoTitleExtra"]}>
                                                <div className={styles["buttonInfoTitleExtraA"]}>4</div>
                                                <div className={styles["buttonInfoTitleExtraB"]}>TOTALES</div>
                                            </div>
                                        </div>
                                        {['alianza.datalabmx.com'].includes(window.location.hostname) ? (
                                            <div className={styles["checkListContainer"]}>
                                                <div>
                                                    <label className={styles["checkmarkContainer"]}>
                                                        Diageo
                                                        <input type="checkbox" id='1' name='checkmarkComp' defaultChecked></input>
                                                        <span className={styles["checkmark"]}></span>
                                                    </label>
                                                    <label className={styles["checkmarkContainer"]}>
                                                        Cuervo
                                                        <input type="checkbox" id='1' name='checkmarkComp' defaultChecked></input>
                                                        <span className={styles["checkmark"]}></span>
                                                    </label>
                                                    <label className={styles["checkmarkContainer"]}>
                                                        Brown Forman
                                                        <input type="checkbox" id='1' name='checkmarkComp' defaultChecked></input>
                                                        <span className={styles["checkmark"]}></span>
                                                    </label>
                                                </div>
                                                <div>
                                                    <label className={styles["checkmarkContainer"]}>
                                                        Pernod Ricard
                                                        <input type="checkbox" id='1' name='checkmarkComp' defaultChecked></input>
                                                        <span className={styles["checkmark"]}></span>
                                                    </label>
                                                    <label className={styles["checkmarkContainer"]}>
                                                        Bacardi
                                                        <input type="checkbox" id='1' name='checkmarkComp' defaultChecked></input>
                                                        <span className={styles["checkmark"]}></span>
                                                    </label>
                                                </div>
                                                <div>
                                                    <label className={styles["checkmarkContainer"]}>
                                                        Campari
                                                        <input type="checkbox" id='1' name='checkmarkComp' defaultChecked></input>
                                                        <span className={styles["checkmark"]}></span>
                                                    </label>
                                                    <label className={styles["checkmarkContainer"]}>
                                                        Beam Global
                                                        <input type="checkbox" id='1' name='checkmarkComp' defaultChecked></input>
                                                        <span className={styles["checkmark"]}></span>
                                                    </label>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className={styles["checkListContainer"]}>
                                                <div>
                                                    <label className={styles["checkmarkContainer"]}>
                                                        Palacio de Hierro
                                                        <input type="checkbox" id='1' name='checkmarkComp' defaultChecked></input>
                                                        <span className={styles["checkmark"]}></span>
                                                    </label>
                                                    <label className={styles["checkmarkContainer"]}>
                                                        Liverpool
                                                        <input type="checkbox" id='1' name='checkmarkComp' defaultChecked></input>
                                                        <span className={styles["checkmark"]}></span>
                                                    </label>
                                                </div>
                                                <div>
                                                    <label className={styles["checkmarkContainer"]}>
                                                        Costco
                                                        <input type="checkbox" id='1' name='checkmarkComp' defaultChecked></input>
                                                        <span className={styles["checkmark"]}></span>
                                                    </label>
                                                    <label className={styles["checkmarkContainer"]}>
                                                        Sam's Club
                                                        <input type="checkbox" id='1' name='checkmarkComp' defaultChecked></input>
                                                        <span className={styles["checkmark"]}></span>
                                                    </label>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </OutsideClickHandler>
                            ) : ''}
                        </div>
                    ) : ''}
                    {[].includes(tipo) ? (
                        <div className={styles["buttonMainContainer"]}>
                            <div className={styles["buttonContainer"]} onClick={() => { this.setState({ showCat: !showCat }) }}>
                                <div className={styles["filterSectionTitle"]}>CATEGORÍAS</div>
                                <div className={styles["buttonArrow"]}>❯</div>
                            </div>
                            {showCat ? (
                                <OutsideClickHandler onOutsideClick={() => { this.setState({ showCat: false }); }}>
                                    <div className={styles["buttonInfoContainer"]}>
                                        <div className={styles["buttonInfoTitleContainer"]}>
                                            <div className={styles["buttonInfoTitle"]}>Categorías</div>
                                            <div className={styles["buttonInfoTitleExtra"]}>
                                                <div className={styles["buttonInfoTitleExtraA"]}>8</div>
                                                <div className={styles["buttonInfoTitleExtraB"]}>TOTALES</div>
                                            </div>
                                        </div>
                                        <div className={styles["checkListContainer"]}>
                                            <div>
                                                <label className={styles["checkmarkContainer"]}>
                                                    Whisky
                                                    <input type="checkbox" id='1' name='checkmarkCat' defaultChecked></input>
                                                    <span className={styles["checkmark"]}></span>
                                                </label>
                                                <label className={styles["checkmarkContainer"]}>
                                                    Ron
                                                    <input type="checkbox" id='1' name='checkmarkCat' defaultChecked></input>
                                                    <span className={styles["checkmark"]}></span>
                                                </label>
                                                <label className={styles["checkmarkContainer"]}>
                                                    Brandy
                                                    <input type="checkbox" id='1' name='checkmarkCat' defaultChecked></input>
                                                    <span className={styles["checkmark"]}></span>
                                                </label>
                                            </div>
                                            <div>
                                                <label className={styles["checkmarkContainer"]}>
                                                    Tequila
                                                    <input type="checkbox" id='1' name='checkmarkCat' defaultChecked></input>
                                                    <span className={styles["checkmark"]}></span>
                                                </label>
                                                <label className={styles["checkmarkContainer"]}>
                                                    Vodka
                                                    <input type="checkbox" id='1' name='checkmarkCat' defaultChecked></input>
                                                    <span className={styles["checkmark"]}></span>
                                                </label>
                                                <label className={styles["checkmarkContainer"]}>
                                                    Gin
                                                    <input type="checkbox" id='1' name='checkmarkCat' defaultChecked></input>
                                                    <span className={styles["checkmark"]}></span>
                                                </label>
                                            </div>
                                            <div>
                                                <label className={styles["checkmarkContainer"]}>
                                                    Mezcal
                                                    <input type="checkbox" id='1' name='checkmarkCat' defaultChecked></input>
                                                    <span className={styles["checkmark"]}></span>
                                                </label>
                                                <label className={styles["checkmarkContainer"]}>
                                                    Licores
                                                    <input type="checkbox" id='1' name='checkmarkCat' defaultChecked></input>
                                                    <span className={styles["checkmark"]}></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </OutsideClickHandler>
                            ) : ''}
                        </div>
                    ) : ''}
                    {['C'].includes(tipo) ? (
                        <div className={styles["buttonMainContainer"]}>
                            <div className={styles["buttonContainer"]} onClick={() => { this.setState({ showMarca: !showMarca }) }}>
                                <div className={styles["filterSectionTitle"]}>MARCA</div>
                                <div className={styles["buttonArrow"]}>❯</div>
                            </div>
                            {showMarca ? (
                                <OutsideClickHandler onOutsideClick={() => { this.setState({ showMarca: false }); }}>
                                    <div className={styles["buttonInfoContainer"]}>
                                        <div className={styles["buttonInfoTitleContainer"]}>
                                            <div className={styles["buttonInfoTitle"]}>Marca</div>
                                            <div className={styles["buttonInfoTitleExtra"]}>
                                                <div className={styles["buttonInfoTitleExtraA"]}>6</div>
                                                <div className={styles["buttonInfoTitleExtraB"]}>TOTALES</div>
                                            </div>
                                        </div>
                                        <div className={styles["checkListContainer"]}>
                                            <div>
                                                <label className={styles["checkmarkContainer"]}>
                                                    Buchanan's
                                                    <input type="checkbox" id='1' name='checkmarkMarca' defaultChecked></input>
                                                    <span className={styles["checkmark"]}></span>
                                                </label>
                                                <label className={styles["checkmarkContainer"]}>
                                                    Johnnie Walker
                                                    <input type="checkbox" id='1' name='checkmarkMarca' defaultChecked></input>
                                                    <span className={styles["checkmark"]}></span>
                                                </label>
                                            </div>
                                            <div>
                                                <label className={styles["checkmarkContainer"]}>
                                                    {'Black & White'}
                                                    <input type="checkbox" id='1' name='checkmarkMarca' defaultChecked></input>
                                                    <span className={styles["checkmark"]}></span>
                                                </label>
                                                <label className={styles["checkmarkContainer"]}>
                                                    Old Parr
                                                    <input type="checkbox" id='1' name='checkmarkMarca' defaultChecked></input>
                                                    <span className={styles["checkmark"]}></span>
                                                </label>
                                            </div>
                                            <div>
                                                <label className={styles["checkmarkContainer"]}>
                                                    Bulleit
                                                    <input type="checkbox" id='1' name='checkmarkMarca' defaultChecked></input>
                                                    <span className={styles["checkmark"]}></span>
                                                </label>
                                                <label className={styles["checkmarkContainer"]}>
                                                    {'J&B'}
                                                    <input type="checkbox" id='1' name='checkmarkMarca' defaultChecked></input>
                                                    <span className={styles["checkmark"]}></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </OutsideClickHandler>
                            ) : ''}
                        </div>
                    ) : ''}
                    {['C'].includes(tipo) ? (
                        <div className={styles["buttonMainContainer"]}>
                            <div className={styles["buttonContainer"]} onClick={() => { this.setState({ showSKU: !showSKU }) }}>
                                <div className={styles["filterSectionTitle"]}>SKU</div>
                                <div className={styles["buttonArrow"]}>❯</div>
                            </div>
                            {showSKU ? (
                                <OutsideClickHandler onOutsideClick={() => { this.setState({ showSKU: false }); }}>
                                    <div className={styles["buttonInfoContainer"]}>
                                        <div className={styles["buttonInfoTitleContainer"]}>
                                            <div className={styles["buttonInfoTitle"]}>SKU</div>
                                            <div className={styles["buttonInfoTitleExtra"]}>
                                                <div className={styles["buttonInfoTitleExtraA"]}>6</div>
                                                <div className={styles["buttonInfoTitleExtraB"]}>TOTALES</div>
                                            </div>
                                        </div>
                                        <div className={styles["checkListContainer"]}>
                                            <div>
                                                <label className={styles["checkmarkContainer"]}>
                                                    Deluxe 12 Años
                                                    <input type="checkbox" id='1' name='checkmarkMarca' defaultChecked></input>
                                                    <span className={styles["checkmark"]}></span>
                                                </label>
                                                <label className={styles["checkmarkContainer"]}>
                                                    Select 15 Años
                                                    <input type="checkbox" id='1' name='checkmarkMarca' defaultChecked></input>
                                                    <span className={styles["checkmark"]}></span>
                                                </label>
                                            </div>
                                            <div>
                                                <label className={styles["checkmarkContainer"]}>
                                                    Master
                                                    <input type="checkbox" id='1' name='checkmarkMarca' defaultChecked></input>
                                                    <span className={styles["checkmark"]}></span>
                                                </label>
                                                <label className={styles["checkmarkContainer"]}>
                                                    2 Souls
                                                    <input type="checkbox" id='1' name='checkmarkMarca' defaultChecked></input>
                                                    <span className={styles["checkmark"]}></span>
                                                </label>
                                            </div>
                                            <div>
                                                <label className={styles["checkmarkContainer"]}>
                                                    Reserve 18 Años
                                                    <input type="checkbox" id='1' name='checkmarkMarca' defaultChecked></input>
                                                    <span className={styles["checkmark"]}></span>
                                                </label>
                                                <label className={styles["checkmarkContainer"]}>
                                                    Red Seal
                                                    <input type="checkbox" id='1' name='checkmarkMarca' defaultChecked></input>
                                                    <span className={styles["checkmark"]}></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </OutsideClickHandler>
                            ) : ''}
                        </div>
                    ) : ''}
                    {[].includes(tipo) ? (
                        <div className={styles["buttonMainContainer"]}>
                            <div className={styles["buttonContainer"]} onClick={() => { this.setState({ showCanales: !showCanales }) }}>
                                <div className={styles["filterSectionTitle"]}>CANAL</div>
                                <div className={styles["buttonArrow"]}>❯</div>
                            </div>
                            {showCanales ? (
                                <OutsideClickHandler onOutsideClick={() => { this.setState({ showCanales: false }); }}>
                                    <div className={styles["buttonInfoContainer"]}>
                                        <div className={styles["buttonInfoTitleContainer"]}>
                                            <div className={styles["buttonInfoTitle"]}>Canal</div>
                                        </div>
                                        <div className={styles["dateRadioTitle"]} style={{ fontFamily: 'DDINExpBold' }}>Tipo de Canal:</div>
                                        <div onChange={this.handleChangeCanalRadio}>
                                            <label className={styles["radioButtonContainer"]} style={{ display: 'inline-block', fontSize: '12px' }}>
                                                <span className={styles["aligHelper"]}></span>
                                                <input type="radio" id="filterRadioCanalA" name="filterCanalOption" value={0} defaultChecked />
                                                <div className={styles["radioCheckmark"]}></div>
                                                <div className={styles["radioLabel"]}>Todos</div>
                                            </label>
                                            <label className={styles["radioButtonContainer"]} style={{ display: 'inline-block', fontSize: '12px', marginLeft: '3%' }}>
                                                <span className={styles["aligHelper"]}></span>
                                                <input type="radio" id="filterRadioCanalB" name="filterCanalOption" value={1} />
                                                <div className={styles["radioCheckmark"]}></div>
                                                <div className={styles["radioLabel"]}>Menudeo</div>
                                            </label>
                                            <label className={styles["radioButtonContainer"]} style={{ display: 'inline-block', fontSize: '12px', marginLeft: '3%' }}>
                                                <span className={styles["aligHelper"]}></span>
                                                <input type="radio" id="filterRadioCanalC" name="filterCanalOption" value={2} />
                                                <div className={styles["radioCheckmark"]}></div>
                                                <div className={styles["radioLabel"]}>Mayoreo</div>
                                            </label>
                                            <label className={styles["radioButtonContainer"]} style={{ display: 'inline-block', fontSize: '12px', marginLeft: '3%' }}>
                                                <span className={styles["aligHelper"]}></span>
                                                <input type="radio" id="filterRadioCanalD" name="filterCanalOption" value={3} />
                                                <div className={styles["radioCheckmark"]}></div>
                                                <div className={styles["radioLabel"]}>Telemarketing</div>
                                            </label>
                                        </div>
                                        <div className={styles["dateRadioTitle"]} style={{ fontFamily: 'DDINExpBold' }}>Región:</div>
                                        <Tabs>
                                            <TabList>
                                                <Tab>Norte</Tab>
                                                <Tab>Occidente</Tab>
                                                <Tab>Oriente</Tab>
                                                <Tab>Centro</Tab>
                                                <Tab>Sur</Tab>
                                            </TabList>

                                            <TabPanel>
                                                <div className={styles["checkListContainer"]}>
                                                    <div>
                                                        <label className={styles["checkmarkContainer"]}>
                                                            Sucursal Tijuana
                                                            <input type="checkbox" id='1' name='checkmarkCanal' defaultChecked></input>
                                                            <span className={styles["checkmark"]}></span>
                                                        </label>
                                                        <label className={styles["checkmarkContainer"]}>
                                                            Sucursal La Paz
                                                            <input type="checkbox" id='1' name='checkmarkCanal' defaultChecked></input>
                                                            <span className={styles["checkmark"]}></span>
                                                        </label>
                                                        <label className={styles["checkmarkContainer"]}>
                                                            Sucursal Los Cabos
                                                            <input type="checkbox" id='1' name='checkmarkCanal' defaultChecked></input>
                                                            <span className={styles["checkmark"]}></span>
                                                        </label>
                                                    </div>
                                                    <div>
                                                        <label className={styles["checkmarkContainer"]}>
                                                            Sucursal Hermosillo
                                                            <input type="checkbox" id='1' name='checkmarkCanal' defaultChecked></input>
                                                            <span className={styles["checkmark"]}></span>
                                                        </label>
                                                        <label className={styles["checkmarkContainer"]}>
                                                            Sucursal Ciudad Juárez
                                                            <input type="checkbox" id='1' name='checkmarkCanal' defaultChecked></input>
                                                            <span className={styles["checkmark"]}></span>
                                                        </label>
                                                        <label className={styles["checkmarkContainer"]}>
                                                            Sucursal Nogales
                                                            <input type="checkbox" id='1' name='checkmarkCanal' defaultChecked></input>
                                                            <span className={styles["checkmark"]}></span>
                                                        </label>
                                                    </div>
                                                    <div>
                                                        <label className={styles["checkmarkContainer"]}>
                                                            Sucursal Ciudad Victoria
                                                            <input type="checkbox" id='1' name='checkmarkCanal' defaultChecked></input>
                                                            <span className={styles["checkmark"]}></span>
                                                        </label>
                                                        <label className={styles["checkmarkContainer"]}>
                                                            Sucursal Monterrey
                                                            <input type="checkbox" id='1' name='checkmarkCanal' defaultChecked></input>
                                                            <span className={styles["checkmark"]}></span>
                                                        </label>
                                                        <label className={styles["checkmarkContainer"]}>
                                                            Sucursal Nuevo Laredo
                                                            <input type="checkbox" id='1' name='checkmarkCanal' defaultChecked></input>
                                                            <span className={styles["checkmark"]}></span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </TabPanel>
                                            {/* ================== */}
                                            <TabPanel>
                                                <div className={styles["checkListContainer"]}>
                                                    <div>
                                                        <label className={styles["checkmarkContainer"]}>
                                                            Sucursal Culiacán
                                                            <input type="checkbox" id='1' name='checkmarkCanal' defaultChecked></input>
                                                            <span className={styles["checkmark"]}></span>
                                                        </label>
                                                        <label className={styles["checkmarkContainer"]}>
                                                            Sucursal Mazatlán
                                                            <input type="checkbox" id='1' name='checkmarkCanal' defaultChecked></input>
                                                            <span className={styles["checkmark"]}></span>
                                                        </label>
                                                        <label className={styles["checkmarkContainer"]}>
                                                            Sucursal Puerto Vallarta
                                                            <input type="checkbox" id='1' name='checkmarkCanal' defaultChecked></input>
                                                            <span className={styles["checkmark"]}></span>
                                                        </label>
                                                    </div>
                                                    <div>
                                                        <label className={styles["checkmarkContainer"]}>
                                                            Sucursal Guadalajara
                                                            <input type="checkbox" id='1' name='checkmarkCanal' defaultChecked></input>
                                                            <span className={styles["checkmark"]}></span>
                                                        </label>
                                                        <label className={styles["checkmarkContainer"]}>
                                                            Sucursal Tepic
                                                            <input type="checkbox" id='1' name='checkmarkCanal' defaultChecked></input>
                                                            <span className={styles["checkmark"]}></span>
                                                        </label>
                                                        <label className={styles["checkmarkContainer"]}>
                                                            Sucursal Manzanillo
                                                            <input type="checkbox" id='1' name='checkmarkCanal' defaultChecked></input>
                                                            <span className={styles["checkmark"]}></span>
                                                        </label>
                                                    </div>
                                                    <div>
                                                        <label className={styles["checkmarkContainer"]}>
                                                            Sucursal Colima
                                                            <input type="checkbox" id='1' name='checkmarkCanal' defaultChecked></input>
                                                            <span className={styles["checkmark"]}></span>
                                                        </label>
                                                        <label className={styles["checkmarkContainer"]}>
                                                            Sucursal Durango
                                                            <input type="checkbox" id='1' name='checkmarkCanal' defaultChecked></input>
                                                            <span className={styles["checkmark"]}></span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </TabPanel>
                                            {/* ================== */}
                                            <TabPanel>
                                                <div className={styles["checkListContainer"]}>
                                                    <div>
                                                        <label className={styles["checkmarkContainer"]}>
                                                            Sucursal Tampico
                                                            <input type="checkbox" id='1' name='checkmarkCanal' defaultChecked></input>
                                                            <span className={styles["checkmark"]}></span>
                                                        </label>
                                                        <label className={styles["checkmarkContainer"]}>
                                                            Sucursal Poza Rica
                                                            <input type="checkbox" id='1' name='checkmarkCanal' defaultChecked></input>
                                                            <span className={styles["checkmark"]}></span>
                                                        </label>
                                                        <label className={styles["checkmarkContainer"]}>
                                                            Sucursal Xalapa
                                                            <input type="checkbox" id='1' name='checkmarkCanal' defaultChecked></input>
                                                            <span className={styles["checkmark"]}></span>
                                                        </label>
                                                    </div>
                                                    <div>
                                                        <label className={styles["checkmarkContainer"]}>
                                                            Sucursal Veracruz
                                                            <input type="checkbox" id='1' name='checkmarkCanal' defaultChecked></input>
                                                            <span className={styles["checkmark"]}></span>
                                                        </label>
                                                        <label className={styles["checkmarkContainer"]}>
                                                            Sucursal Coatzacoalcos
                                                            <input type="checkbox" id='1' name='checkmarkCanal' defaultChecked></input>
                                                            <span className={styles["checkmark"]}></span>
                                                        </label>
                                                        <label className={styles["checkmarkContainer"]}>
                                                            Sucursal Villahermosa
                                                            <input type="checkbox" id='1' name='checkmarkCanal' defaultChecked></input>
                                                            <span className={styles["checkmark"]}></span>
                                                        </label>
                                                    </div>
                                                    <div>
                                                        <label className={styles["checkmarkContainer"]}>
                                                            Sucursal Orizaba
                                                            <input type="checkbox" id='1' name='checkmarkCanal' defaultChecked></input>
                                                            <span className={styles["checkmark"]}></span>
                                                        </label>
                                                        <label className={styles["checkmarkContainer"]}>
                                                            Sucursal Mérida
                                                            <input type="checkbox" id='1' name='checkmarkCanal' defaultChecked></input>
                                                            <span className={styles["checkmark"]}></span>
                                                        </label>
                                                        <label className={styles["checkmarkContainer"]}>
                                                            Sucursal Ciudad del Carmen
                                                            <input type="checkbox" id='1' name='checkmarkCanal' defaultChecked></input>
                                                            <span className={styles["checkmark"]}></span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </TabPanel>
                                            {/* ================== */}
                                            <TabPanel>
                                                <div className={styles["checkListContainer"]}>
                                                    <div>
                                                        <label className={styles["checkmarkContainer"]}>
                                                            Sucursal Ciudad de México
                                                            <input type="checkbox" id='1' name='checkmarkCanal' defaultChecked></input>
                                                            <span className={styles["checkmark"]}></span>
                                                        </label>
                                                        <label className={styles["checkmarkContainer"]}>
                                                            Sucursal Toluca
                                                            <input type="checkbox" id='1' name='checkmarkCanal' defaultChecked></input>
                                                            <span className={styles["checkmark"]}></span>
                                                        </label>
                                                        <label className={styles["checkmarkContainer"]}>
                                                            Sucursal Pachuca
                                                            <input type="checkbox" id='1' name='checkmarkCanal' defaultChecked></input>
                                                            <span className={styles["checkmark"]}></span>
                                                        </label>
                                                    </div>
                                                    <div>
                                                        <label className={styles["checkmarkContainer"]}>
                                                            Sucursal Puebla
                                                            <input type="checkbox" id='1' name='checkmarkCanal' defaultChecked></input>
                                                            <span className={styles["checkmark"]}></span>
                                                        </label>
                                                        <label className={styles["checkmarkContainer"]}>
                                                            Sucursal Querétaro
                                                            <input type="checkbox" id='1' name='checkmarkCanal' defaultChecked></input>
                                                            <span className={styles["checkmark"]}></span>
                                                        </label>
                                                        <label className={styles["checkmarkContainer"]}>
                                                            Sucursal Morelia
                                                            <input type="checkbox" id='1' name='checkmarkCanal' defaultChecked></input>
                                                            <span className={styles["checkmark"]}></span>
                                                        </label>
                                                    </div>
                                                    <div>
                                                        <label className={styles["checkmarkContainer"]}>
                                                            Sucursal Cuernavaca
                                                            <input type="checkbox" id='1' name='checkmarkCanal' defaultChecked></input>
                                                            <span className={styles["checkmark"]}></span>
                                                        </label>
                                                        <label className={styles["checkmarkContainer"]}>
                                                            Sucursal Tepotzotlán
                                                            <input type="checkbox" id='1' name='checkmarkCanal' defaultChecked></input>
                                                            <span className={styles["checkmark"]}></span>
                                                        </label>
                                                        <label className={styles["checkmarkContainer"]}>
                                                            Sucursal Texcoco
                                                            <input type="checkbox" id='1' name='checkmarkCanal' defaultChecked></input>
                                                            <span className={styles["checkmark"]}></span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </TabPanel>
                                            {/* ================== */}
                                            <TabPanel>
                                                <div className={styles["checkListContainer"]}>
                                                    <div>
                                                        <label className={styles["checkmarkContainer"]}>
                                                            Sucursal Acapulco
                                                            <input type="checkbox" id='1' name='checkmarkCanal' defaultChecked></input>
                                                            <span className={styles["checkmark"]}></span>
                                                        </label>
                                                        <label className={styles["checkmarkContainer"]}>
                                                            Sucursal Oaxaca
                                                            <input type="checkbox" id='1' name='checkmarkCanal' defaultChecked></input>
                                                            <span className={styles["checkmark"]}></span>
                                                        </label>
                                                        <label className={styles["checkmarkContainer"]}>
                                                            Sucursal Chilpancingo
                                                            <input type="checkbox" id='1' name='checkmarkCanal' defaultChecked></input>
                                                            <span className={styles["checkmark"]}></span>
                                                        </label>
                                                    </div>
                                                    <div>
                                                        <label className={styles["checkmarkContainer"]}>
                                                            Sucursal Tuxtla Gutiérrez
                                                            <input type="checkbox" id='1' name='checkmarkCanal' defaultChecked></input>
                                                            <span className={styles["checkmark"]}></span>
                                                        </label>
                                                        <label className={styles["checkmarkContainer"]}>
                                                            Sucursal San Cristóbal de las Casas
                                                            <input type="checkbox" id='1' name='checkmarkCanal' defaultChecked></input>
                                                            <span className={styles["checkmark"]}></span>
                                                        </label>
                                                        <label className={styles["checkmarkContainer"]}>
                                                            Sucursal Tapachula
                                                            <input type="checkbox" id='1' name='checkmarkCanal' defaultChecked></input>
                                                            <span className={styles["checkmark"]}></span>
                                                        </label>
                                                    </div>
                                                    <div>
                                                        <label className={styles["checkmarkContainer"]}>
                                                            Sucursal Zihuatanejo
                                                            <input type="checkbox" id='1' name='checkmarkCanal' defaultChecked></input>
                                                            <span className={styles["checkmark"]}></span>
                                                        </label>
                                                        <label className={styles["checkmarkContainer"]}>
                                                            Sucursal Comitán
                                                            <input type="checkbox" id='1' name='checkmarkCanal' defaultChecked></input>
                                                            <span className={styles["checkmark"]}></span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </TabPanel>
                                        </Tabs>
                                    </div>
                                </OutsideClickHandler>
                            ) : ''}
                        </div>
                    ) : ''}
                    {[].includes(tipo) ? (
                        <div className={styles["buttonContainer"]}>
                            <div className={styles["filterSectionTitle"]}>PROVEEDOR</div>
                            <div className={styles["buttonArrow"]}>❯</div>
                        </div>
                    ) : ''}
                    {[].includes(tipo) ? (
                        <div className={styles["buttonContainer"]}>
                            <div className={styles["filterSectionTitle"]}>GRUPO</div>
                            <div className={styles["buttonArrow"]}>❯</div>
                        </div>
                    ) : ''}
                    {['B'].includes(tipo) ? (
                        <div className={styles["radioContainer"]}>
                            {/* <div className={styles["filterSectionTitle"]}>TIPO DE INFORMACIÓN</div> */}
                            <div className={styles["radioSubcontainer"]} onChange={this.props.handleChangeRadio}>
                                <label className={styles["radioButtonContainer"]}>
                                    <span className={styles["aligHelper"]}></span>
                                    <input type="radio" id="filterRadioA" name="filterOption" value={0} defaultChecked />
                                    <div className={styles["radioCheckmark"]}></div>
                                    <div className={styles["radioLabel"]}>Sucursales</div>
                                </label>
                                <label className={styles["radioButtonContainer"]}>
                                    <span className={styles["aligHelper"]}></span>
                                    <input type="radio" id="filterRadioB" name="filterOption" value={1} />
                                    <div className={styles["radioCheckmark"]}></div>
                                    <div className={styles["radioLabel"]}>Clientes</div>
                                </label>
                                <label className={styles["radioButtonContainer"]}>
                                    <span className={styles["aligHelper"]}></span>
                                    <input type="radio" id="filterRadioC" name="filterOption" value={2} />
                                    <div className={styles["radioCheckmark"]}></div>
                                    <div className={styles["radioLabel"]}>Categoría</div>
                                </label>
                                <label className={styles["radioButtonContainer"]}>
                                    <span className={styles["aligHelper"]}></span>
                                    <input type="radio" id="filterRadioD" name="filterOption" value={3} />
                                    <div className={styles["radioCheckmark"]}></div>
                                    <div className={styles["radioLabel"]}>Marca</div>
                                </label>
                                <label className={styles["radioButtonContainer"]}>
                                    <span className={styles["aligHelper"]}></span>
                                    <input type="radio" id="filterRadioE" name="filterOption" value={4} />
                                    <div className={styles["radioCheckmark"]}></div>
                                    <div className={styles["radioLabel"]}>SKU</div>
                                </label>
                            </div>
                        </div>
                    ) : ''}
                    {[].includes(tipo) ? (
                        <div className={styles["buttonMainContainer"]}>
                            <div className={styles["buttonContainer"]} onClick={() => { this.setState({ showPtosVentaA: !showPtosVentaA }) }}>
                                <div className={styles["filterSectionTitle"]}>PUNTOS DE VENTA ALIANZA</div>
                                <div className={styles["buttonArrow"]}>❯</div>
                            </div>
                            {showPtosVentaA ? (
                                <OutsideClickHandler onOutsideClick={() => { this.setState({ showPtosVentaA: false }); }}>
                                    <div className={styles["buttonInfoContainer"]} style={{ right: '-365px', width: '340px' }}>
                                        <div className={styles["buttonInfoTitleContainer"]} style={{ gridTemplateColumns: '100%' }}>
                                            <div className={styles["buttonInfoTitle"]}>Puntos de Venta Aliazna</div>
                                        </div>
                                        <div /* onChange={this.handleChangeCanalRadio} */>
                                            <label className={styles["radioButtonContainer"]} style={{ display: 'inline-block', fontSize: '12px' }}>
                                                <span className={styles["aligHelper"]}></span>
                                                <input type="radio" id="filterRadioPtoVtaA_A" name="filterRadioPtoVtaA" value={0} defaultChecked />
                                                <div className={styles["radioCheckmark"]}></div>
                                                <div className={styles["radioLabel"]}>Todos</div>
                                            </label>
                                            <label className={styles["radioButtonContainer"]} style={{ display: 'inline-block', fontSize: '12px', marginLeft: '3%' }}>
                                                <span className={styles["aligHelper"]}></span>
                                                <input type="radio" id="filterRadioPtoVtaA_B" name="filterRadioPtoVtaA" value={1} />
                                                <div className={styles["radioCheckmark"]}></div>
                                                <div className={styles["radioLabel"]}>Bodegas</div>
                                            </label>
                                            <label className={styles["radioButtonContainer"]} style={{ display: 'inline-block', fontSize: '12px', marginLeft: '3%' }}>
                                                <span className={styles["aligHelper"]}></span>
                                                <input type="radio" id="filterRadioPtoVtaA_C" name="filterRadioPtoVtaA" value={2} />
                                                <div className={styles["radioCheckmark"]}></div>
                                                <div className={styles["radioLabel"]}>Tiendas</div>
                                            </label>
                                        </div>
                                    </div>
                                </OutsideClickHandler>
                            ) : ''}
                        </div>
                    ) : ''}
                    {[].includes(tipo) ? (
                        <div className={styles["buttonMainContainer"]}>
                            <div className={styles["buttonContainer"]} onClick={() => { this.setState({ showPtosVentaB: !showPtosVentaB }) }}>
                                <div className={styles["filterSectionTitle"]}>PUNTOS DE VENTA COMPETENCIA</div>
                                <div className={styles["buttonArrow"]}>❯</div>
                            </div>
                            {showPtosVentaB ? (
                                <OutsideClickHandler onOutsideClick={() => { this.setState({ showPtosVentaB: false }); }}>
                                    <div className={styles["buttonInfoContainer"]} style={{ right: '-455px', width: '430px' }}>
                                        <div className={styles["buttonInfoTitleContainer"]} style={{ gridTemplateColumns: '100%' }}>
                                            <div className={styles["buttonInfoTitle"]}>Puntos de Venta Competencia</div>
                                        </div>
                                        <div /* onChange={this.handleChangeCanalRadio} */>
                                            <label className={styles["radioButtonContainer"]} style={{ display: 'inline-block', fontSize: '12px' }}>
                                                <span className={styles["aligHelper"]}></span>
                                                <input type="radio" id="filterRadioPtoVtaB_A" name="filterRadioPtoVtaB" value={0} defaultChecked />
                                                <div className={styles["radioCheckmark"]}></div>
                                                <div className={styles["radioLabel"]}>Todos</div>
                                            </label>
                                            <label className={styles["radioButtonContainer"]} style={{ display: 'inline-block', fontSize: '12px', marginLeft: '3%' }}>
                                                <span className={styles["aligHelper"]}></span>
                                                <input type="radio" id="filterRadioPtoVtaB_B" name="filterRadioPtoVtaB" value={1} />
                                                <div className={styles["radioCheckmark"]}></div>
                                                <div className={styles["radioLabel"]}>Sam's Club</div>
                                            </label>
                                            <label className={styles["radioButtonContainer"]} style={{ display: 'inline-block', fontSize: '12px', marginLeft: '3%' }}>
                                                <span className={styles["aligHelper"]}></span>
                                                <input type="radio" id="filterRadioPtoVtaB_C" name="filterRadioPtoVtaB" value={2} />
                                                <div className={styles["radioCheckmark"]}></div>
                                                <div className={styles["radioLabel"]}>Walmart</div>
                                            </label>
                                            <label className={styles["radioButtonContainer"]} style={{ display: 'inline-block', fontSize: '12px', marginLeft: '3%' }}>
                                                <span className={styles["aligHelper"]}></span>
                                                <input type="radio" id="filterRadioPtoVtaB_C" name="filterRadioPtoVtaB" value={2} />
                                                <div className={styles["radioCheckmark"]}></div>
                                                <div className={styles["radioLabel"]}>La Europea</div>
                                            </label>
                                        </div>
                                    </div>
                                </OutsideClickHandler>
                            ) : ''}
                        </div>
                    ) : ''}
                    {['D'].includes(tipo) ? (
                        <div className={styles["checkListContainer"]} style={{ display: 'block', borderBottom: '1px solid black' }}>
                            <div className={styles["filterSectionTitle"]} style={{ margin: '5px 0px' }}>PUNTOS DE VENTA</div>
                            {['localhost', 'corporacionrica.datalabmx.com', 'localhost', 'cbrands.datalabmx.com'].includes(window.location.hostname) ? (
                                <>
                                    <label className={styles["checkmarkContainer"]}>
                                        Palacio de Hierro
                                        <input type="checkbox" id='checkmarkPtoVenta_1' name='checkmarkPtoVta' defaultChecked></input>
                                        <span className={styles["checkmark"]}></span>
                                    </label>
                                    <label className={styles["checkmarkContainer"]}>
                                        Liverpool
                                        <input type="checkbox" id='checkmarkPtoVenta_2' name='checkmarkPtoVta' defaultChecked></input>
                                        <span className={styles["checkmark"]}></span>
                                    </label>
                                    <label className={styles["checkmarkContainer"]}>
                                        Costco
                                        <input type="checkbox" id='checkmarkPtoVenta_3' name='checkmarkPtoVta' defaultChecked></input>
                                        <span className={styles["checkmark"]}></span>
                                    </label>
                                    <label className={styles["checkmarkContainer"]}>
                                        Sam's Club
                                        <input type="checkbox" id='checkmarkPtoVenta_4' name='checkmarkPtoVta' defaultChecked></input>
                                        <span className={styles["checkmark"]}></span>
                                    </label>
                                </>
                            ) : (
                                <>
                                    <label className={styles["checkmarkContainer"]}>
                                        Grupo Alianza
                                        <input type="checkbox" id='checkmarkPtoVenta_1' name='checkmarkPtoVta' defaultChecked></input>
                                        <span className={styles["checkmark"]}></span>
                                    </label>
                                    <label className={styles["checkmarkContainer"]}>
                                        Bodegas Alianza
                                        <input type="checkbox" id='checkmarkPtoVenta_2' name='checkmarkPtoVta' defaultChecked></input>
                                        <span className={styles["checkmark"]}></span>
                                    </label>
                                    <label className={styles["checkmarkContainer"]}>
                                        Tiendas Alianza
                                        <input type="checkbox" id='checkmarkPtoVenta_3' name='checkmarkPtoVta' defaultChecked></input>
                                        <span className={styles["checkmark"]}></span>
                                    </label>
                                    <label className={styles["checkmarkContainer"]}>
                                        Sam's Club
                                        <input type="checkbox" id='checkmarkPtoVenta_4' name='checkmarkPtoVta' defaultChecked></input>
                                        <span className={styles["checkmark"]}></span>
                                    </label>
                                    <label className={styles["checkmarkContainer"]}>
                                        Walmart
                                        <input type="checkbox" id='checkmarkPtoVenta_5' name='checkmarkPtoVta' defaultChecked></input>
                                        <span className={styles["checkmark"]}></span>
                                    </label>
                                    <label className={styles["checkmarkContainer"]}>
                                        La Europea
                                        <input type="checkbox" id='checkmarkPtoVenta_6' name='checkmarkPtoVta' defaultChecked></input>
                                        <span className={styles["checkmark"]}></span>
                                    </label>
                                </>
                            )}
                        </div>
                    ) : ''}
                    {['D'].includes(tipo) ? (
                        <div className={styles["capasContainer"]}>
                            <div className={styles["capasTitle"]}>CAPAS</div>
                            <div className={styles["capasOption"]}>
                                <div className={styles["capasOptionTitle"]}>IDSU</div>
                                <div className={styles["switchContainer"]}>
                                    <Switch onChange={this.props.handleChangeIDSU} checked={checkedIDSU}
                                        checkedIcon={false} uncheckedIcon={false} height={12} width={30} onColor={'#000000'} />
                                </div>
                            </div>
                            <div className={styles["capasOption"]}>
                                <div className={styles["capasOptionTitle"]}>Clasificación AMAI</div>
                                <div className={styles["switchContainer"]}>
                                    <Switch onChange={this.props.handleChangeAMAI} checked={checkedAMAI}
                                        checkedIcon={false} uncheckedIcon={false} height={12} width={30} onColor={'#000000'} />
                                </div>
                            </div>
                            {/* <div className={styles["capasOption"]}>
                                <div className={styles["capasOptionTitle"]}>Densidad Poblacional</div>
                                <div className={styles["switchContainer"]}>
                                    <Switch onChange={this.props.handleChangeDensidad} checked={checkedDensidad}
                                        checkedIcon={false} uncheckedIcon={false} height={12} width={30} onColor={'#000000'} />
                                </div>
                            </div> */}
                        </div>
                    ) : ''}
                </div>
            </div>
        );
    }
}

export default FiltroTreemap;