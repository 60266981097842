import React, { Component } from 'react'
import { Route, Redirect } from "react-router-dom";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

class PrivateRoute extends Component {
    render() {
        let { component: Component, ...rest } = this.props;
        return (
            <Route
                {...rest}
                render={props =>
                    this.props.auth ? (
                        <Component {...props} />
                    ) : (
                        <Redirect
                            to={{
                                pathname: "/login",
                                state: { from: this.props.location }
                            }}
                        />
                    )
                }
            />
        )
    }
}

function mapStateToProps(state) {
    return { auth: state.auth.authenticated }
}

PrivateRoute.propTypes = {
    auth: PropTypes.bool,
    location: PropTypes.object,
    Component: PropTypes.element
}


export default connect(mapStateToProps, {})(PrivateRoute);