import React, { Component } from "react";
import { connect } from "react-redux";
import ReactECharts from 'echarts-for-react';
// import Switch from "react-switch";
import _ from 'lodash';

import Header from "../header/Header";
import Filtro from "../filtro/Filtro";
import Alcance from "../alcance/Alcance";
import FiltrosAplicados from "../alcance/FiltrosAplicados";
// import PseudoBreadcrumb from "../pseudobreadcrumb/PseudoBreadcrumb";
// mport FiltroTreemap from "../filtrotreemap/FiltroTreemap";
import styles from "./Vista5.module.scss";

import { formatNumberWithCommas, getCanalSubtitle, getYTDText, romanizarNumero } from "../../scripts/helpers";
import { dataAction, filterDataAction } from "../../actions";
import { startOfMonth, endOfMonth, endOfISOWeek } from "date-fns";

class Vista5 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nombresA: [], valoresA: [],
            nombresB: [], valoresB: [],
            barGraphA: [], barGraphB: [],
            // ==================================
            graphColor: '',
            graphOptionsA: {},
            graphOptionsB: {},
            graphOptionsC: {},
            // ==================================
            calendarDate: [new Date(2021, 0, 1), new Date(2022, 11, 31)],
            calendarSPFilter: 'all',
            mesesRange: [0, 6],
            tipoDivision: 0,
            volumenValor: 0,
            radioValue: 0,
            isSellIn: 0,
            regionesShown: [],
            estadosShown: [],
            clientesShown: [],
            sucursalesShown: [],
            marcasShown: [],
            productosShown: [],
            // ================================================
            availableRegiones: [],
            availableEstados: [],
            availableClientes: [],
            availableSucursales: [],
            availableMarcas: [],
            availableProductos: [],
        };

        this.handleChangeCalendar = this.handleChangeCalendar.bind(this);
        this.handleChangeCalendarWeek = this.handleChangeCalendarWeek.bind(this);
        this.handleChanceCalendarSPFilter = this.handleChanceCalendarSPFilter.bind(this);
        this.handleChangeMesesRangeA = this.handleChangeMesesRangeA.bind(this);
        this.handleChangeMesesRangeB = this.handleChangeMesesRangeB.bind(this);
        this.handleChangeTipo = this.handleChangeTipo.bind(this);
        this.handleChangeVolumenValor = this.handleChangeVolumenValor.bind(this);
        this.handleChangeIsSellIn = this.handleChangeIsSellIn.bind(this);
        this.handleChangeRadio = this.handleChangeRadio.bind(this);
        this.handleChangeRegion = this.handleChangeRegion.bind(this);
        this.handleChangeEstado = this.handleChangeEstado.bind(this);
        this.handleChangeCliente = this.handleChangeCliente.bind(this);
        this.handleChangeSucursal = this.handleChangeSucursal.bind(this);
        this.handleChangeMarca = this.handleChangeMarca.bind(this);
        this.handleChangeProducto = this.handleChangeProducto.bind(this);
        this.restartFilter = this.restartFilter.bind(this);
    }

    handleChangeCalendar(calendarDate) { this.setState({ calendarDate }); }
    handleChangeCalendarWeek(weekNumber, date, event) {
        let endOfWeek = endOfISOWeek(date);
        let calendarDate = [date, endOfWeek];

        this.setState({ calendarDate });
    }
    handleChanceCalendarSPFilter(newCalendarSPFilter) {
        const { calendarSPFilter, calendarDate } = this.state;

        let newCalendarDate = calendarDate;
        if (['daily', 'weekly'].includes(calendarSPFilter) && newCalendarSPFilter === 'all') {
            newCalendarDate = [startOfMonth(calendarDate[0]), endOfMonth(calendarDate[1])];
        }

        this.setState({ calendarSPFilter: newCalendarSPFilter, calendarDate: newCalendarDate });
    }
    handleChangeMesesRangeA(event) {
        const { mesesRange } = this.state;
        let newMonth = parseInt(event.target.value);
        let newMesesRange = [newMonth, mesesRange[1]];

        this.setState({ mesesRange: newMesesRange });
    }
    handleChangeMesesRangeB(event) {
        const { mesesRange } = this.state;
        let newMonth = parseInt(event.target.value);
        let newMesesRange = [mesesRange[0], newMonth];

        this.setState({ mesesRange: newMesesRange });
    }
    handleChangeTipo(tipoDivision) { this.setState({ tipoDivision }); }
    handleChangeVolumenValor(volumenValor) { this.setState({ volumenValor }) }
    handleChangeIsSellIn(isSellIn) { this.setState({ isSellIn }) }
    handleChangeRadio(event) {
        let radioValue = parseInt(event.target.value);
        this.setState({ radioValue });
    }
    handleChangeRegion(event) {
        const { regionesShown } = this.state;
        let region = event.target.value;
        let newRegionesShown = [];
        let newSucursalesShown = [];
        let newEstadosShown = [];
        let newClientesShown = [];

        if (region === 'all') {
            if (regionesShown.length !== 7) {
                newRegionesShown = [0, 1, 2, 3, 4, 5, 6];
                newSucursalesShown = this.props.data.sucursales.map(s => s.id);
                newEstadosShown = this.props.data.estados.map(e => e.estado);
                newClientesShown = this.props.data.cadenas.map(c => c.id);
            }
        } else {
            region = parseInt(region);
            if (regionesShown.includes(region)) {
                newRegionesShown = [...regionesShown].filter(r => r !== region);
            } else {
                newRegionesShown = [...regionesShown];
                newRegionesShown.push(region);
            }

            newSucursalesShown = _.uniq([...this.props.data.sucursales].filter(s => newRegionesShown.includes(parseInt(s.region))).map(s => s.id));
            newEstadosShown = this.props.data.estados.filter(e => newRegionesShown.includes(parseInt(e.region))).map(e => e.estado);
            newClientesShown = _.uniq([...this.props.data.sucursales].filter(s => newSucursalesShown.includes(s.id)).map(s => s.idCadena));
        }

        this.setState({ regionesShown: newRegionesShown, sucursalesShown: newSucursalesShown, estadosShown: newEstadosShown, clientesShown: newClientesShown });
    }
    handleChangeEstado(event) {
        const { estados, sucursales } = this.props.data;
        const { estadosShown } = this.state;
        let estado = event.target.value;
        let newEstadosShown = [];
        let newSucursalesShown = [];
        let newRegionesShown = [];
        let newClientesShown = [];

        if (estado === 'all') {
            if (estadosShown.length !== 33) {
                newEstadosShown = estados.map(e => e.estado);
                newSucursalesShown = sucursales.map(s => s.id);
                newRegionesShown = [0, 1, 2, 3, 4, 5, 6];
                newClientesShown = this.props.data.cadenas.map(c => c.id);
            }
        } else {
            if (estadosShown.includes(estado)) {
                newEstadosShown = [...estadosShown].filter(e => e !== estado);
            } else {
                newEstadosShown = [...estadosShown];
                newEstadosShown.push(estado);
            }

            newSucursalesShown = _.uniq([...sucursales].filter(s => newEstadosShown.includes(s.estado)).map(s => s.id));
            newRegionesShown = _.uniq([...estados].filter(e => newEstadosShown.includes(e.estado)).map(e => parseInt(e.region)));
            newClientesShown = _.uniq([...sucursales].filter(s => newSucursalesShown.includes(s.id)).map(s => s.idCadena));
        }

        this.setState({ estadosShown: newEstadosShown, sucursalesShown: newSucursalesShown, regionesShown: newRegionesShown, clientesShown: newClientesShown });
    }
    handleChangeSucursal(event) {
        const { sucursalesShown, availableSucursales } = this.state;
        let idSucursal = event.target.value;
        let newSucursalesShown = [];
        let newRegionesShown = [];
        let newEstadosShown = [];
        let newClientesShown = [];

        if (idSucursal === 'all') {
            if (sucursalesShown.length !== this.props.data.sucursales.length) {
                newSucursalesShown = this.props.data.sucursales.map(s => s.id);
                newRegionesShown = [0, 1, 2, 3, 4, 5, 6];
                newEstadosShown = this.props.data.estados.map(e => e.estado);
                newClientesShown = this.props.data.cadenas.map(c => c.id);
            }
        } else if (idSucursal.includes('all')) {
            let idCadena = idSucursal.split('_')[1];

            let allSucursalesInRegion = [...this.props.data.sucursales].filter(s => s.idCadena === idCadena).map(s => s.id);
            if (!allSucursalesInRegion.some(s => sucursalesShown.includes(s))) {
                newSucursalesShown = [...sucursalesShown].concat(allSucursalesInRegion);
            } else {
                newSucursalesShown = [...sucursalesShown].filter(s => !allSucursalesInRegion.includes(s));
            }

            let tmpObject = [...this.props.data.sucursales].filter(s => newSucursalesShown.includes(s.id));
            newRegionesShown = _.uniq(tmpObject.map(s => parseInt(s.region)));
            newEstadosShown = _.uniq(tmpObject.map(s => s.estado));
            newClientesShown = _.uniq(tmpObject.map(s => s.idCadena));
        } else {
            if (sucursalesShown.includes(idSucursal)) {
                newSucursalesShown = [...sucursalesShown].filter(s => s !== idSucursal);
            } else {
                newSucursalesShown = [...sucursalesShown];
                newSucursalesShown.push(idSucursal);
            }
            newSucursalesShown = newSucursalesShown.filter(s => availableSucursales.includes(s));

            let tmpObject = [...this.props.data.sucursales].filter(s => newSucursalesShown.includes(s.id));
            newRegionesShown = _.uniq(tmpObject.map(s => parseInt(s.region)));
            newEstadosShown = _.uniq(tmpObject.map(s => s.estado));
            newClientesShown = _.uniq(tmpObject.map(s => s.idCadena));
        }

        this.setState({ sucursalesShown: newSucursalesShown, regionesShown: newRegionesShown, estadosShown: newEstadosShown, clientesShown: newClientesShown });
    }
    handleChangeCliente(event) {
        const { clientesShown } = this.state;
        let idCadena = event.target.value;
        let newClientesShown = [];

        if (idCadena === 'all') {
            if (clientesShown.length !== this.props.data.cadenas.length) {
                newClientesShown = this.props.data.cadenas.map(c => c.id);
            }
        } else if (idCadena.includes('all')) {
            let letra = idCadena.split('_')[1];

            let allClientesInLetter = [];
            if (letra === '#') allClientesInLetter = [...this.props.data.cadenas].filter(c => /^\d/.test(c.nombre)).map(c => c.id);
            else allClientesInLetter = [...this.props.data.cadenas].filter(c => c.nombre.toUpperCase().startsWith(letra)).map(c => c.id);

            if (!allClientesInLetter.some(p => clientesShown.includes(p))) {
                newClientesShown = [...clientesShown].concat(allClientesInLetter);
            } else {
                newClientesShown = [...clientesShown].filter(p => !allClientesInLetter.includes(p));
            }
        } else {
            if (clientesShown.includes(idCadena)) {
                newClientesShown = [...clientesShown].filter(c => c !== idCadena);
            } else {
                newClientesShown = [...clientesShown];
                newClientesShown.push(idCadena);
            }
        }

        this.setState({ clientesShown: newClientesShown });
    }
    handleChangeMarca(event) {
        const { marcasShown } = this.state;
        let marca = event.target.value;
        let newMarcasShown = [];
        let newproductosShown = [];

        if (marca === 'all') {
            if (marcasShown.length !== this.props.data.marcas.length) {
                newMarcasShown = this.props.data.marcas.map(m => m.marca);
                newproductosShown = this.props.data.productos.map(c => c.id);
            }
        } else {
            if (marcasShown.includes(marca)) {
                newMarcasShown = [...marcasShown].filter(m => m !== marca);
            } else {
                newMarcasShown = [...marcasShown];
                newMarcasShown.push(marca);
            }
            newproductosShown = [...this.props.data.productos].filter(p => newMarcasShown.includes(p.marca)).map(p => p.id);
        }

        this.setState({ marcasShown: newMarcasShown, productosShown: newproductosShown });
    }
    handleChangeProducto(event) {
        const { productosShown, availableProductos } = this.state;
        let idProducto = event.target.value;
        let newproductosShown = [];
        let newMarcasShown = [];

        if (idProducto === 'all') {
            if (productosShown.length !== this.props.data.productos.length) {
                newproductosShown = this.props.data.productos.map(c => c.id);
                newMarcasShown = this.props.data.marcas.map(m => m.marca);
            }
        } else if (idProducto.includes('all')) {
            let marca = idProducto.split('_')[1];
            let allProductsInMarca = [...this.props.data.productos].filter(p => p.marca === marca).map(p => p.id);

            if (!allProductsInMarca.some(p => productosShown.includes(p))) {
                newproductosShown = [...productosShown].concat(allProductsInMarca);
            } else {
                newproductosShown = [...productosShown].filter(p => !allProductsInMarca.includes(p));
            }

            newMarcasShown = _.uniq([...this.props.data.productos].filter(p => newproductosShown.includes(p.id)).map(p => p.marca));

        } else {
            if (productosShown.includes(idProducto)) {
                newproductosShown = [...productosShown].filter(c => c !== idProducto);
            } else {
                newproductosShown = [...productosShown];
                newproductosShown.push(idProducto);
            }
            newproductosShown = newproductosShown.filter(p => availableProductos.includes(p));

            newMarcasShown = _.uniq([...this.props.data.productos].filter(p => newproductosShown.includes(p.id)).map(p => p.marca));
        }

        this.setState({ productosShown: newproductosShown, marcasShown: newMarcasShown });
    }

    async componentDidMount() {
        const hostname = window.location.hostname;
        let graphColor;

        /* const root = document.documentElement;
        root.style.setProperty('--calendarSelectedRangeColor', '#FFFFFF'); */

        switch (hostname) {
            case '3.12.19.179':
                graphColor = '#515662';
                break;
            case 'demo.datalabmx.com':
                graphColor = '#575756';
                break;
            case '3.17.121.27':
                graphColor = '#515662';
                break;
            case 'corporacionrica.datalabmx.com':
                graphColor = '#333333';
                break;
            case 'locotequila.datalabmx.com':
                graphColor = '#772835';
                break;
            case 'cbrands.datalabmx.com':
                graphColor = '#001E5B';
                break;
            default:
                graphColor = '#68476C';
        }

        this.setState({ graphColor });

        // ====================================================================

        if (_.isEmpty(this.props.data)) await this.props.dataAction();
        /* console.log('========== INFORMACIÓN EN COMPARATIVO ==========');
        console.log(this.props.data); */

        let regionesShown = [0, 1, 2, 3, 4, 5, 6];
        let estadosShown = this.props.data.estados.map(e => e.estado);
        let clientesShown = this.props.data.cadenas.map(c => c.id);
        let sucursalesShown = this.props.data.sucursales.map(s => s.id);
        let marcasShown = this.props.data.marcas.map(m => m.marca);
        let productosShown = this.props.data.productos.map(p => p.id);

        let isSellIn = ['locotequila.datalabmx.com'].includes(hostname) ? 1 : 0;

        this.setState({ regionesShown, estadosShown, clientesShown, marcasShown, sucursalesShown, productosShown, isSellIn }, () => { this.buildGraphData(); });
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            (prevState.volumenValor !== this.state.volumenValor) || (!_.isEqual(prevState.calendarDate, this.state.calendarDate)) || (prevState.calendarSPFilter !== this.state.calendarSPFilter) || (prevState.tipoDivision !== this.state.tipoDivision) ||
            (!_.isEqual(prevState.mesesRange, this.state.mesesRange)) || (prevState.radioValue !== this.state.radioValue) || (prevState.isSellIn !== this.state.isSellIn) ||
            (!_.isEqual(prevState.regionesShown, this.state.regionesShown)) || (!_.isEqual(prevState.estadosShown, this.state.estadosShown)) || (!_.isEqual(prevState.clientesShown, this.state.clientesShown)) || (!_.isEqual(prevState.marcasShown, this.state.marcasShown)) ||
            (!_.isEqual(prevState.productosShown, this.state.productosShown)) || (!_.isEqual(prevState.sucursalesShown, this.state.sucursalesShown))
        ) {
            this.buildGraphData();
        } else if (
            (!_.isEqual(prevState.nombresA, this.state.nombresA)) || (!_.isEqual(prevState.valoresA, this.state.valoresA)) ||
            (!_.isEqual(prevState.nombresB, this.state.nombresB)) || (!_.isEqual(prevState.valoresB, this.state.valoresB))
        ) {
            this.updateGraphOptions();
        }
    }

    buildGraphData() {
        const { calendarDate, calendarSPFilter, mesesRange, tipoDivision, volumenValor, radioValue, isSellIn, regionesShown, estadosShown, clientesShown, marcasShown, sucursalesShown, productosShown, graphColor } = this.state;
        const { ventas, cadenas, marcas } = this.props.data;
        const hostname = window.location.hostname;

        let mainGroupBy = tipoDivision === 0 ? 'cadena' : (tipoDivision === 1 ? 'marca' : (tipoDivision === 2 ? 'nombreProducto' : 'sucursal'));
        let valuesToSum = volumenValor === 0 ? 'ventas_unidades' : 'ventas_mxn';

        let ventasData = [...ventas];
        if (calendarSPFilter === 'daily') {
            ventasData = ventasData.filter(v => ['1', '2', '3', '5', '13', '14'].includes(v.idCadena));
        } else if (calendarSPFilter === 'weekly') {
            ventasData = ventasData.filter(v => ['1', '2', '3', '4', '5', '13', '14'].includes(v.idCadena));
        }
        ventasData = ventasData.filter(v => v.fechaFormato >= calendarDate[0] && v.fechaFormato <= calendarDate[1]);
        ventasData = ventasData.filter(v => v.fechaFormato.getMonth() >= mesesRange[0] && v.fechaFormato.getMonth() <= mesesRange[1]);
        ventasData = ventasData.filter(v => v.isSellIn === isSellIn);

        let availableRegiones = _.uniq(ventasData.map(v => v.region));
        let availableEstados = _.uniq(ventasData.map(v => v.estado));
        let availableClientes = _.uniq(ventasData.map(v => v.idCadena));
        let availableSucursales = _.uniq(ventasData.map(v => v.idSucursal));
        let availableMarcas = _.uniq(ventasData.map(v => v.marca));
        let availableProductos = _.uniq(ventasData.map(v => v.idProducto));

        ventasData = ventasData.filter(v => regionesShown.includes(v.region));
        ventasData = ventasData.filter(v => estadosShown.includes(v.estado));
        ventasData = ventasData.filter(v => clientesShown.includes(v.idCadena));
        ventasData = ventasData.filter(v => sucursalesShown.includes(v.idSucursal));
        ventasData = ventasData.filter(v => marcasShown.includes(v.marca));
        ventasData = ventasData.filter(v => productosShown.includes(v.idProducto));

        if (['locotequila.datalabmx.com'].includes(hostname)) {
            if (radioValue !== 0) ventasData = ventasData.filter(v => v.idCanal === radioValue);
        } else {
            let ventasPorTelefono = ['23', '149', '3484', '6046'];
            let ventasPorInternet = ['22', '148', '190', '693', '3478', '4928', '5262', '4856', '4990'];
            let ventasConjuntas = ventasPorTelefono.concat(ventasPorInternet);

            let cadenasMayoristas = ['5', '6', '9', '10', '11', '12', '13', '14', '16', '19', '20'];

            switch (radioValue) {
                case 0:
                    // Todos
                    break;
                case 1:
                    // Mayoreo
                    ventasData = ventasData.filter(v => !ventasConjuntas.includes(v.idSucursal) && cadenasMayoristas.includes(v.idCadena));
                    break;
                case 2:
                    // Menudeo
                    ventasData = ventasData.filter(v => !ventasConjuntas.includes(v.idSucursal) && !cadenasMayoristas.includes(v.idCadena));
                    break;
                case 3:
                    // Telemarketing
                    ventasData = ventasData.filter(v => ventasPorTelefono.includes(v.idSucursal));
                    break;
                case 4:
                    // E-Commerce
                    ventasData = ventasData.filter(v => ventasPorInternet.includes(v.idSucursal));
                    break;
                default:
                    console.log('Opción no encontrada');
            }
        }

        let firstYearGroup = [...ventasData].filter(v => v.fechaFormato.getFullYear() === calendarDate[0].getFullYear());

        let firstYearGroupAlt = _.groupBy(firstYearGroup, mainGroupBy);
        firstYearGroupAlt = Object.keys(firstYearGroupAlt).map(key => {
            let keyInfo = firstYearGroupAlt[key];
            let value = _.sumBy(keyInfo, (v) => { return v[valuesToSum]; });
            value = parseFloat(value.toFixed(value > 1 ? 0 : 2));

            return { key, value, keyInfo }
        });

        let top10A = _.orderBy(firstYearGroupAlt, 'value', 'desc').slice(0, 10);
        let nombresA = top10A.map(v => v.key);
        let coloresA = tipoDivision === 2 ? this.obtainSKUColors(top10A) : (tipoDivision === 3 ? this.obtainSucursalesColors(top10A) : []);
        let valoresA = top10A.map((v, i) => {
            let color = 'red';
            if (mainGroupBy === 'cadena') color = cadenas.find(c => c.nombre === nombresA[i]).color;
            else if (mainGroupBy === 'marca') color = marcas.find(m => m.marca === nombresA[i]).colorMarca;
            else if (mainGroupBy === 'nombreProducto' || mainGroupBy === 'sucursal') color = coloresA[i];

            return { value: v.value, itemStyle: { color } }
        });

        let infoByMonthA = _.groupBy(firstYearGroup, 'mesVenta');
        let arrayDataA = [];
        for (let i = 0; i <= 11; i++) {
            let monthValue = infoByMonthA[i];
            if (!monthValue) {
                arrayDataA.push(0);
                continue;
            }
            let suma = _.sumBy(monthValue, valuesToSum);
            arrayDataA.push(suma.toFixed(suma > 1 ? 0 : 2));
        }
        let barGraphA = {
            name: calendarDate[0].getFullYear(),
            type: 'bar',
            color: '#939598',
            data: arrayDataA,
        }

        let secondYearGroup = [...ventasData].filter(v => v.fechaFormato.getFullYear() === calendarDate[1].getFullYear());

        let secondYearGroupAlt = _.groupBy(secondYearGroup, mainGroupBy);
        secondYearGroupAlt = Object.keys(secondYearGroupAlt).map(key => {
            let keyInfo = secondYearGroupAlt[key];
            let value = _.sumBy(keyInfo, (v) => { return v[valuesToSum]; });
            value = parseFloat(value.toFixed(value > 1 ? 0 : 2));

            let change = 100;
            let firstGroupObject = firstYearGroupAlt.find(g => g.key === key);
            if (firstGroupObject) change = parseFloat(((value - firstGroupObject.value) / firstGroupObject.value * 100).toFixed(2));

            return { key, value, change, keyInfo }
        });

        let top10B = _.orderBy(secondYearGroupAlt, 'value', 'desc').slice(0, 10);
        let nombresB = top10B.map(v => v.key);
        let coloresB = tipoDivision === 2 ? this.obtainSKUColors(top10B) : (tipoDivision === 3 ? this.obtainSucursalesColors(top10B) : []);
        let valoresB = top10B.map((v, i) => {
            let color = 'red';
            if (mainGroupBy === 'cadena') color = cadenas.find(c => c.nombre === nombresB[i]).color;
            else if (mainGroupBy === 'marca') color = marcas.find(m => m.marca === nombresB[i]).colorMarca;
            else if (mainGroupBy === 'nombreProducto' || mainGroupBy === 'sucursal') color = coloresB[i];

            return { value: [v.value, i, v.change], itemStyle: { color } };
        });

        let infoByMonthB = _.groupBy(secondYearGroup, 'mesVenta');
        let arrayDataB = [];
        for (let i = 0; i <= 11; i++) {
            let suma = 0;
            let previousMonthValue = arrayDataA[i];
            let change = previousMonthValue > 0 ? -100 : 0;

            let monthValue = infoByMonthB[i];
            if (monthValue) {
                suma = _.sumBy(monthValue, valuesToSum);
                suma = parseFloat(suma.toFixed(suma > 1 ? 0 : 2));

                if (previousMonthValue > 0) change = parseFloat(((suma - previousMonthValue) / previousMonthValue * 100).toFixed(2));
                else change = 100;
            }

            let finalArray = [i, suma, change];
            arrayDataB.push(finalArray);
        }
        let barGraphB = {
            name: calendarDate[1].getFullYear(),
            color: '#414042',
            type: 'bar',
            label: {
                show: true,
                position: 'top',
                formatter: (s) => {
                    if (s.data[1] === 0) return '';
                    else return `{${s.data[2] < 0 ? 'porcentaje_rojo' : 'porcentaje_verde'}|${s.data[2]}%}`;
                },
                rich: {
                    porcentaje_rojo: { color: 'red', fontSize: '12px', fontFamily: '#a70303' },
                    porcentaje_verde: { color: 'green', fontSize: '12px', fontFamily: '#1f8131' },
                },
            },
            data: arrayDataB,
        }

        this.setState({ nombresA, valoresA, nombresB, valoresB, barGraphA, barGraphB, availableRegiones, availableEstados, availableClientes, availableSucursales, availableMarcas, availableProductos });
    }

    updateGraphOptions() {
        const {
            nombresA, valoresA, nombresB, valoresB, barGraphA, barGraphB,
            calendarDate, volumenValor, graphColor, tipoDivision,
        } = this.state;

        const graphOptionsA = {
            grid: {
                top: 35,
                bottom: 20,
                right: '10%',
                left: '5%',
                containLabel: true,
            },
            title: {
                text: `Top 10 – ${calendarDate[0].getFullYear()}`,
                top: 'top',
                left: '5%',
                textStyle: {
                    fontSize: '25px',
                    fontFamily: 'AvenirNextDemiBold',
                    color: '#040415',
                },
            },
            yAxis: {
                type: 'category',
                inverse: true,
                axisLabel: {
                    color: '#58595b',
                    fontFamily: 'AvenirHeavy',
                    fontSize: '10px'
                },
                axisLine: {
                    lineStyle: {
                        width: 2,
                        color: '#D0D0D0'
                    },
                },
                axisTick: { show: false },
                data: nombresA,
            },
            xAxis: {
                type: 'value',
                axisLabel: {
                    color: '#d1d3d4',
                    fontFamily: 'AvenirNextRegular',
                    fontSize: '10px',
                    formatter: `${volumenValor === 0 ? '' : '$'}{value}`,
                },
                splitLine: {
                    lineStyle: {
                        color: '#000',
                        type: 'dashed',
                        opacity: 0.25,
                    },
                },
            },
            series: [
                {
                    type: 'bar',
                    data: valoresA,
                    label: {
                        show: true,
                        distance: 5,
                        position: 'right',
                        color: '#939598',
                        fontFamily: 'AvenirHeavy',
                        fontSize: 10,
                        formatter: (s) => { return `${volumenValor !== 0 ? '$' : ''}${formatNumberWithCommas(s.value)}`; }
                    }
                }
            ],
            // color: graphColor,
        };

        const graphOptionsB = {
            grid: {
                top: 35,
                bottom: 20,
                right: '10%',
                left: '5%',
                containLabel: true,
            },
            title: {
                text: `Top 10 – ${calendarDate[1].getFullYear()}`,
                top: 'top',
                left: '5%',
                textStyle: {
                    fontSize: '25px',
                    fontFamily: 'AvenirNextDemiBold',
                    color: '#040415',
                },
            },
            yAxis: {
                type: 'category',
                inverse: true,
                axisLabel: {
                    color: '#58595b',
                    fontFamily: 'AvenirHeavy',
                    fontSize: '10px'
                },
                axisLine: {
                    lineStyle: {
                        width: 2,
                        color: '#D0D0D0'
                    },
                },
                axisTick: { show: false },
                data: nombresB,
            },
            xAxis: {
                type: 'value',
                axisLabel: {
                    color: '#d1d3d4',
                    fontFamily: 'AvenirNextRegular',
                    fontSize: '10px',
                    formatter: `${volumenValor === 0 ? '' : '$'}{value}`,
                },
                splitLine: {
                    lineStyle: {
                        color: '#000',
                        type: 'dashed',
                        opacity: 0.25,
                    },
                },
            },
            series: [
                {
                    type: 'bar',
                    data: valoresB,
                    label: {
                        show: true,
                        // distance: 40,
                        position: 'right',
                        align: 'left',
                        formatter: (s) => {
                            return [
                                `{cantidad|${volumenValor !== 0 ? '$' : ''}${formatNumberWithCommas(s.value[0])}}`,
                                `{${s.value[2] < 0 ? 'porcentaje_rojo' : 'porcentaje_verde'}|${s.value[2]}%}`
                            ].join('\n');
                        },
                        rich: {
                            cantidad: { fontFamily: 'AvenirHeavy', fontSize: '10px', color: '#939598' },
                            porcentaje_rojo: { fontFamily: 'AvenirHeavy', fontSize: '14px', color: '#a70303' },
                            porcentaje_verde: { fontFamily: 'AvenirHeavy', fontSize: '14px', color: '#1f8131' },
                        },
                    }
                }
            ],
            // color: graphColor,
        };

        const graphOptionsC = {
            grid: {
                top: 70,
                bottom: 55,
                right: '3%',
                left: '5%',
            },
            title: {
                text: `${volumenValor === 0 ? 'Volumen' : 'Valor'} Mensual`,
                top: 25,
                left: '2%',
                textStyle: {
                    fontSize: '25px',
                    fontFamily: 'AvenirNextDemiBold',
                    color: '#040415',
                },
            },
            legend: {
                itemWidth: 15,
                itemHeight: 15,
                right: '2%',
                icon: 'rect',
                top: 30,
                textStyle: {
                    fontFamily: 'AvenirHeavy', color: '#58595b',
                    fontSize: '10px',
                },
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    lineStyle: {
                        color: '#414042',
                        type: 'solid',
                        width: 1,
                    },
                },
                formatter: (series) => {
                    let tooltipRow = series.map(d => {
                        let value = typeof d.data === 'object' ? d.data[1] : d.data;
                        return `
                            <div style="margin: 0.24vh 0px; font-size: 1.17vh;">
                                <div style="display: inline-block; background-color: ${d.color}; width: 0.65vw; height: 0.65vw;"></div>
                                <div style="display: inline-block; vertical-align: text-top; margin-top: -0.4vh; width: 6.2vw; padding-left: 0.2vw; white-space: initial; font-family: AvenirHeavy; color: #58595b;">${d.seriesName}</div>
                                <div style="display: inline-block; vertical-align: text-top; margin-top: -0.4vh; width: 5.42vw; text-align: center; font-family: AvenirMedium; color: #939598;">${volumenValor === 0 ? '' : '$'}${formatNumberWithCommas(value)}</div>
                            </div>
                        `;
                    });

                    tooltipRow.unshift(`
                        <div style="font-size: 0.96vh; font-family: AvenirNextMedium; color: #d1d3d4;">
                            <div style="display: inline-block; background-color: white; width: 0.65vw; height: 0.65vw;"></div>
                            <div style="display: inline-block; width: 6.2vw; padding-left: 0.2vw;">Año</div>
                            <div style="display: inline-block; width: 5.42vw; text-align: center;">${volumenValor === 0 ? 'Cajas (9L)' : '$'}</div>
                        </div>
                    `);

                    return tooltipRow.join('');
                },
            },
            xAxis: {
                type: 'category',
                boundaryGap: true,
                axisLabel: {
                    color: '#58595b',
                    fontFamily: 'AvenirHeavy',
                },
                axisLine: {
                    lineStyle: {
                        width: 2,
                        color: '#707070'
                    },
                },
                axisTick: { show: false },
                data: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
            },
            yAxis: {
                type: 'value',
                axisLabel: {
                    color: '#d1d3d4',
                    fontFamily: 'AvenirNextRegular',
                    formatter: `${volumenValor === 0 ? '' : '$'}{value}`,
                },
                axisLine: { show: false },
                axisTick: { show: false },
                splitLine: {
                    lineStyle: {
                        color: '#000',
                        type: 'dashed',
                        opacity: 0.15,
                    },
                },
                min: 0,
            },
            series: [barGraphA, barGraphB],
        };

        this.setState({ graphOptionsA, graphOptionsB, graphOptionsC });
    }

    restartFilter() {
        const hostname = window.location.hostname;
        let isSellIn = ['locotequila.datalabmx.com'].includes(hostname) ? 1 : 0;

        this.setState({
            calendarDate: [new Date(2021, 0, 1), new Date(2022, 11, 31)],
            calendarSPFilter: 'all',
            tipoDivision: 0,
            volumenValor: 0,
            radioValue: 0,
            isSellIn,
            regionesShown: [0, 1, 2, 3, 4, 5, 6],
            estadosShown: this.props.data.estados.map(e => e.estado),
            clientesShown: this.props.data.cadenas.map(c => c.id),
            sucursalesShown: this.props.data.sucursales.map(s => s.id),
            marcasShown: this.props.data.marcas.map(m => m.marca),
            productosShown: this.props.data.productos.map(p => p.id),
        });
    }

    obtainSKUColors(top10Array) {
        const { marcas } = this.props.data;
        let productColorArray = [];

        let marcasAvailable = [...top10Array].map(v => { return { marca: v.keyInfo[0].marca }; });
        let marcasCount = _.countBy(marcasAvailable, 'marca');
        let marcasFollowUp = {};
        let marcasColors = Object.keys(marcasCount).map(marca => {
            marcasFollowUp[marca] = 0;
            let gradienteMarca = marcas.find(m => m.marca === marca).gradiente;
            let coloresMarca = gradienteMarca.colors(marcasCount[marca]);
            return { marca, coloresMarca };
        });

        marcasAvailable.forEach(marca => {
            let colorArray = marcasColors.find(m => m.marca === marca.marca).coloresMarca;
            productColorArray.push(colorArray[marcasFollowUp[marca.marca]]);

            marcasFollowUp[marca.marca]++;
        });

        return productColorArray;
    }

    obtainSucursalesColors(top10Array) {
        const { cadenas } = this.props.data;
        let sucursalesColorArray = [];

        let cadenasAvailable = [...top10Array].map(v => { return { cadena: v.keyInfo[0].cadena }; });
        let cadenasCount = _.countBy(cadenasAvailable, 'cadena');
        let cadenasFollowUp = {};
        let cadenasColors = Object.keys(cadenasCount).map(cadena => {
            cadenasFollowUp[cadena] = 0;
            let gradienteCadena = cadenas.find(c => c.nombre === cadena).gradiente;
            let coloresCadena = gradienteCadena.colors(cadenasCount[cadena]);
            return { cadena, coloresCadena };
        });

        cadenasAvailable.forEach(cadena => {
            let colorArray = cadenasColors.find(c => c.cadena === cadena.cadena).coloresCadena;
            sucursalesColorArray.push(colorArray[cadenasFollowUp[cadena.cadena]]);

            cadenasFollowUp[cadena.cadena]++;
        });

        return sucursalesColorArray;
    }

    render() {
        const {
            graphOptionsA, graphOptionsB, graphOptionsC,
            nombresA, nombresB,
            calendarDate, calendarSPFilter, mesesRange, tipoDivision, volumenValor, radioValue, isSellIn,
            regionesShown, estadosShown, clientesShown, sucursalesShown, marcasShown, productosShown,
            availableRegiones, availableEstados, availableClientes, availableSucursales, availableMarcas, availableProductos
        } = this.state;
        const apiData = this.props.data;

        let subtitlePartA = volumenValor === 0 ? 'Volumen' : 'Valor';
        let subtitlePartB = getCanalSubtitle(radioValue, apiData);
        let subtitlePartC = tipoDivision === 0 ? 'Cadenas' : (tipoDivision === 1 ? 'Marca' : (tipoDivision === 2 ? 'SKU' : 'Sucursal'));
        let infoSubtitle = ` (${subtitlePartA} / ${subtitlePartB} / ${subtitlePartC})`;

        if (_.isEmpty(apiData)) {
            return (
                <div className={styles["loadingContainer"]}>
                    <span className={styles["aligHelper"]}></span>
                    <object
                        className={styles["loading"]}
                        data="./images/fact_loading_sp.svg"
                        type="image/svg+xml"
                    >
                        <img
                            src="./images/fact_loading_sp.svg"
                            alt="Cargando contenido"
                        />
                    </object>
                </div>
            );
        } else {
            return (
                <div className={styles["mainContainer"]}>
                    <Header
                        history={this.props.history}
                    />
                    <div className={styles["subContainer"]}>
                        {!_.isEmpty(apiData) ? (
                            <Filtro
                                tipo={'E'}
                                calendarDate={calendarDate}
                                handleChangeCalendar={this.handleChangeCalendar}
                                handleChangeCalendarWeek={this.handleChangeCalendarWeek}
                                calendarSPFilter={calendarSPFilter}
                                handleChanceCalendarSPFilter={this.handleChanceCalendarSPFilter}
                                mesesRange={mesesRange}
                                handleChangeMesesRangeA={this.handleChangeMesesRangeA}
                                handleChangeMesesRangeB={this.handleChangeMesesRangeB}
                                tipoDivision={tipoDivision}
                                handleChangeTipo={this.handleChangeTipo}
                                volumenValor={volumenValor}
                                handleChangeVolumenValor={this.handleChangeVolumenValor}
                                radioValue={radioValue}
                                handleChangeRadio={this.handleChangeRadio}
                                isSellIn={isSellIn}
                                handleChangeIsSellIn={this.handleChangeIsSellIn}
                                regionesShown={regionesShown}
                                handleChangeRegion={this.handleChangeRegion}
                                estadosShown={estadosShown}
                                handleChangeEstado={this.handleChangeEstado}
                                clientesShown={clientesShown}
                                handleChangeCliente={this.handleChangeCliente}
                                sucursalesShown={sucursalesShown}
                                handleChangeSucursal={this.handleChangeSucursal}
                                marcasShown={marcasShown}
                                handleChangeMarca={this.handleChangeMarca}
                                productosShown={productosShown}
                                handleChangeProducto={this.handleChangeProducto}
                                // ====================================================
                                availableRegiones={availableRegiones}
                                availableEstados={availableEstados}
                                availableClientes={availableClientes}
                                availableSucursales={availableSucursales}
                                availableMarcas={availableMarcas}
                                availableProductos={availableProductos}
                                // ====================================================
                                apiData={apiData}
                                restartFilter={this.restartFilter}
                            />
                        ) : ''}
                        <div className={styles["infoContainer"]}>
                            <div className={styles["alcanceContainer"]}>
                                <Alcance apiData={apiData} />
                                <FiltrosAplicados
                                    apiData={apiData}
                                    tipoDivision={tipoDivision} volumenValor={volumenValor} radioValue={radioValue}
                                    regionesShown={regionesShown} estadosShown={estadosShown}
                                    clientesShown={clientesShown} marcasShown={marcasShown} productosShown={productosShown}
                                />
                            </div>
                            {(nombresA.length === 0) && (nombresB.length === 0) ? (
                                <div className={styles["noDataContainer"]}>No existen datos correspondientes a este filtrado</div>
                            ) : ''}
                            <div className={styles["infoTitle"]}>
                                Comparativo Anual
                                {/* <span className={styles["infoSubtitle"]}>{infoSubtitle}</span> */}
                                {/* <PseudoBreadcrumb
                                    apiData={apiData} tipoDivision={tipoDivision}
                                    regionesShown={regionesShown} clientesShown={clientesShown}
                                    marcasShown={marcasShown} productosShown={productosShown}
                                /> */}
                                <div className={styles["ytdContainer"]}>{getYTDText(calendarDate, false)}</div>
                            </div>
                            <div className={styles["chartContainerA"]}>
                                <ReactECharts
                                    option={graphOptionsA}
                                    style={{ height: '100%' }}
                                />
                                <ReactECharts
                                    option={graphOptionsB}
                                    style={{ height: '100%' }}
                                />
                                {/* <div className={styles["switchContainer"]}>
                                    <span className={styles["aligHelper"]}></span>
                                    <div className={styles["switchLabels"]}>Volúmenes</div>
                                    <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
                                        <Switch onChange={this.handleChangeSwitch} checked={checkedSwitch}
                                            checkedIcon={false} uncheckedIcon={false} height={15} width={40} />
                                    </div>
                                    <div className={styles["switchLabels"]}>Valores</div>
                                </div> */}
                            </div>
                            <div className={styles["chartContainerB"]}>
                                <ReactECharts
                                    option={graphOptionsC}
                                    style={{ height: '100%' }}
                                    onEvents={{
                                        selectchanged: () => { console.log(''); }, // Añadir esto arregló el problema de encimados, y no sé porqué
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

function mapStateToProps(state) {
    return {
        data: state.data,
    };
}

export default connect(mapStateToProps, {
    dataAction,
    filterDataAction,
})(Vista5);