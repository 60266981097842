import axios from "axios";
import demoData from './demoData.json';

export const GET_DATA = "get_data";
export const FILTER_DATA = "filter_data";

export function dataAction() {
  return async (dispatch) => {
    const hostname = window.location.hostname;
    if (['3.12.19.179', 'locotequila.datalabmx.com', '3.140.175.113', 'demo.datalabmx.com', '3.17.121.27'].includes(hostname)) {
      const params = new URLSearchParams();

      let phpFile = '';
      switch (hostname) {
        case '3.12.19.179': phpFile = 'datosMonteXanic.php'; break;
        case '3.140.175.113': phpFile = 'datosMonteXanic.php'; break;
        case 'demo.datalabmx.com': phpFile = 'datosMonteXanic.php'; break;
        case '3.17.121.27': phpFile = 'datosMonteXanic.php'; break;
        case 'locotequila.datalabmx.com': phpFile = 'datosTequilaLoco.php'; break;
        default: phpFile = 'datosMonteXanic.php'; break;
      }

      const res = await axios.get(`./php/${phpFile}`, {
        params: params,
      });
      // console.log('¿Qué sale de esto?', res);
      // console.log(JSON.stringify(res.data));

      dispatch({
        type: GET_DATA,
        payload: res.data,
      });
    } else {
      dispatch({
        type: GET_DATA,
        payload: demoData,
      });
    }
  };
}

// this action emulates the previous one but omits the async request
export function filterDataAction() {
  return (dispatch, getState) => {
    const { data, filterStatus } = getState();
    dispatch({
      type: FILTER_DATA,
      payload: { data, filterStatus },
    });
  };
}
