import React, { Component } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";

import LogIn from "../../views/login/LogIn";
import Home from "../../views/home/Home";
import Vista1 from "../vista1/Vista1";
import Vista2 from "../vista2/Vista2";
import Vista3 from "../vista3/Vista3";
import Mapa from "../mapa/Mapa";
import Vista4 from "../vista4/Vista4";
import Vista5 from "../vista5/Vista5";
import Vista6 from "../vista6/Vista6";

class Routes extends Component {
    render() {
        return (
            <Switch>
                <Route path={"/login"} component={LogIn} />
                <PrivateRoute path={"/treemap"} component={Vista1} />
                <PrivateRoute path={"/top10"} component={Vista2} />
                <PrivateRoute path={"/sininfo_a"} component={Vista3} />
                <PrivateRoute path={"/sininfo_b"} component={Mapa} />
                <PrivateRoute path={"/sellout"} component={Vista4} />
                <PrivateRoute path={"/comparativo"} component={Vista5} />
                <PrivateRoute path={"/ranking"} component={Vista6} />
                <PrivateRoute path={"/"} component={Home} />
            </Switch>
        );
    }
}

export default withRouter(Routes);