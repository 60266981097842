export function ventilesStyle(valor) {
    let color;
    switch (valor) {
        case ('1'):
            color = '#D7191C';
            break;
        case ('2'):
            color = '#DF382B';
            break;
        case ('3'):
            color = '#E75839';
            break;
        case ('4'):
            color = '#EF7748';
            break;
        case ('5'):
            color = '#F79656';
            break;
        case ('6'):
            color = '#FDB266';
            break;
        case ('7'):
            color = '#FEC37A';
            break;
        case ('8'):
            color = '#FED48E';
            break;
        case ('9'):
            color = '#FEE5A1';
            break;
        case ('10'):
            color = '#FFF6B5';
            break;
        case ('11'):
            color = '#F6FBC3';
            break;
        case ('12'):
            color = '#E4F3CC';
            break;
        case ('13'):
            color = '#D3EBD5';
            break;
        case ('14'):
            color = '#C1E3DE';
            break;
        case ('15'):
            color = '#AFDBE7';
            break;
        case ('16'):
            color = '#97CAE1';
            break;
        case ('17'):
            color = '#7CB6D6';
            break;
        case ('18'):
            color = '#61A3CB';
            break;
        case ('19'):
            color = '#478FC1';
            break;
        case ('20'):
            color = '#2C7BB6';
            break;
        default:
            color = '#808080';
    }

    return color;
}

export function AMAIStyle(valor) {
    let color;

    switch (valor) {
        case 'A/B':
            color = '#c22f5f';
            break;
        case 'C+':
            color = '#7241a2';
            break;
        case 'C':
            color = '#2d5381';
            break;
        case 'C-':
            color = '#a4bcd8';
            break;
        case 'D+':
            color = '#508ea3';
            break;
        case 'D':
            color = '#a9c16f';
            break;
        case 'E':
            color = '#fefd88';
            break;
        default:
            color = '#808080';
    }

    return color;
}

export function densidadStyle(valor) {
    let color;

    switch (true) {
        case (valor < 1000):
            color = '#ffcbb3';
            break;
        case (valor >= 1000 && valor < 8000):
            color = '#ffb08e';
            break;
        case (valor >= 8000 && valor < 15000):
            color = '#ff9569';
            break;
        case (valor >= 15000 && valor < 25000):
            color = '#ff7c43';
            break;
        case (valor >= 25000 && valor < 55000):
            color = '#bf5d31';
            break;
        case (valor >= 55000):
            color = '#803e23';
            break;
        default:
            color = '#808080';
    }

    return color;
}

export function iconoPuntoVenta(cadena) {
    let url

    switch (cadena) {
        case 'Bodegas Alianza':
            url = './images/iconos/alianza.png';
            break;
        case 'BODEGA AURRERA':
            url = './images/iconos/ba.png';
            break;
        case 'CHEDRAUI':
            url = './images/iconos/chedraui.png';
            break;
        case 'COMERCIAL CITY FRESKO':
            url = './images/iconos/fresko.png';
            break;
        case 'Costco':
            url = './images/iconos/costco.png';
            break;
        case 'LIVERPOOL':
            url = './images/iconos/liverpool.png';
            break;
        case 'Liverpool':
            url = './images/iconos/liverpool.png';
            break;
        case 'SAMS':
            url = './images/iconos/sams.png';
            break;
        case "Sam's Club":
            url = './images/iconos/sams.png';
            break;
        case 'HIPER SORIANA':
            url = './images/iconos/soriana.png';
            break;
        case 'WAL-MART SUPERCENTER':
            url = './images/iconos/walmart.png';
            break;
        case 'La Europea':
            url = './images/iconos/europea.png';
            break;
        case 'Palacio de Hierro':
            url = './images/iconos/palacio_hierro.png';
            break;
        default:
            url = './images/iconos/alianza.png';
    }

    return url
}