import React, { Component } from "react";
import _ from 'lodash';

import styles from "./FiltrosAplicados.module.scss";
import { romanizarNumero } from "../../scripts/helpers";


const mesesAlcance = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

class FiltrosAplicados extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tooltipElements: [],
        };
    }

    componentDidMount() {
        const { apiData, data, color, nombre } = this.props;
        // console.log("data de alcance linea 18", data)
        // console.log("apiData de alcancde", apiData)

        let ventasData = [...apiData.ventas];
        let ventasByCadena = _.groupBy(ventasData, 'cadena');
        ventasByCadena = Object.keys(ventasByCadena).map(cadena => {
            let cadenaInfo = ventasByCadena[cadena];
            let maxDate = _.maxBy(cadenaInfo, 'fechaFormato').fechaFormato;

            let day = maxDate.getDate() + '';
            day = day.length === 1 ? `0${day}` : day
            let month = mesesAlcance[maxDate.getMonth()];
            let year = maxDate.getFullYear();
            let maxDateFormat = `${day !== '01' ? `${day} de ` : ''}${month} ${year}`;

            return { cadena, maxDateFormat };
        });
        ventasByCadena = _.sortBy(ventasByCadena, 'cadena');
        // console.log("ventasByCadena", ventasByCadena)

        let tooltipElements = ventasByCadena.map((c, i) => {
            return (<div className={styles["alcanceTooltipRow"]} key={`alcance_${i}`}>
                <span className={styles["alcanceTooltipBold"]} style={{ color: '#000', fontWeight: '900' }}>{`${c.cadena}: `}</span>
                {`${c.maxDateFormat}`}
            </div>);
        });

        this.setState({ tooltipElements });
    }

    render() {
        const { tooltipElements } = this.state;
        const { tipoDivision, volumenValor, radioValue, regionesShown, estadosShown, clientesShown, marcasShown, productosShown, apiData } = this.props;

        //     let TooltipElementsSucursales = data.map((c, i)=>{
        //         return (

        //             <>
        //         <div className={styles["groupName"]}>
        //     <div className={styles["alcanceTooltipBold2"]} style={{width: "10%", height: "1.85vh", backgroundColor: `${c.itemStyle.color}`, marginRight:'10px'}}></div>
        //     <span className={styles["alcanceTooltipBold3"]} style={{color: '#000', fontWeight:'900'}}>{`${c.name} `}</span>
        //     <span className={styles["alcanceTooltipBold2"]} style={{color: '#000', fontWeight:'900'}}>{`${c.value} `}</span>

        //         </div>

        //     {/* {`${c.maxDateFormat}`} */}
        //         </>
        // );

        // });

        let FiltrosAplicados = <div className={styles["infoBreadcrumb"]}>
            <div className={styles["breadcrumbSubcontainer"]}>
                <div >
                    <span style={{ color: '#a7a7a7', marginRight: '10px' }}><b style={{ color: '#000000' }}>Tipo</b>: {volumenValor === 0 ? 'Volumen' : (volumenValor === 1 ? 'Valor' : 'Ticket')}</span>
                    <span style={{ color: '#a7a7a7', marginRight: '10px' }}><b style={{ color: '#000000' }}>Canal</b>: {radioValue === 0 ? 'Todos los Canales' : (radioValue === 1 ? 'Canal Tradicional / Mayoristas' : (radioValue === 2 ? 'Canal Moderno' : (radioValue === 3 ? 'Telemarketing' : 'E-Commerce')))}</span>
                    <span style={{ color: '#a7a7a7', marginRight: '10px' }}><b style={{ color: '#000000' }}>Profundidad</b>: {tipoDivision === 0 ? 'Cadenas' : (tipoDivision === 1 ? 'Marcas' : (tipoDivision === 2 ? 'SKU' : 'Sucursales'))}</span>
                </div>
                <span style={{ color: '#a7a7a7' }}><b style={{ color: '#000000' }}>Regiones</b>: {regionesShown.map(r => r === 0 ? 'Sin Región' : `Región ${romanizarNumero(r)}`).join(', ')}</span> <br />
                <span style={{ color: '#a7a7a7' }}><b style={{ color: '#000000' }}>Estados</b>: {estadosShown.slice(0, 3).join(', ') + (estadosShown.length > 3 ? ', …' : '')}</span>
                {tipoDivision !== 0 ? <div style={{ color: '#a7a7a7' }}><b style={{ color: '#000000' }}>Cadenas</b>: {[...apiData.cadenas].filter(c => clientesShown.includes(c.id)).slice(0, (clientesShown.length > 3 ? 3 : clientesShown.length)).map(c => c.nombre).join(', ') + (clientesShown.length > 3 ? ', …' : '')}</div> : ''}
                {tipoDivision !== 1 ? <div style={{ color: '#a7a7a7' }}><b style={{ color: '#000000' }}>Marcas</b>: {[...apiData.marcas].filter(c => marcasShown.includes(c.marca)).map(c => c.marca).join(', ')}</div> : ''}
                {tipoDivision !== 2 ? <div style={{ color: '#a7a7a7' }}><b style={{ color: '#000000' }}>SKU</b>: {[...apiData.productos].filter(c => productosShown.includes(c.id)).slice(0, (productosShown.length > 3 ? 3 : productosShown.length)).map(c => c.nombre).join(', ') + (productosShown.length > 3 ? ', …' : '')}</div> : ''}
            </div>
        </div>

        return (
            <div className={styles["alcanceSubcontainer"]}>
                <span className={styles["aligHelper"]}></span>
                <div className={styles["alcanceSubtext"]}>Filtros Aplicados</div>
                <div className={styles["tooltipContainer"]}>
                    <img className={styles["alcanceIcon"]} src="./images/iconosMenu/info.svg" alt=""></img>
                    <div className={styles["alcanceTooltip"]}>
                        {FiltrosAplicados}
                    </div>
                </div>
            </div>
        );
    }
}

export default FiltrosAplicados;