import React from "react";
import styles from './BreadcrumbTreemap.module.scss'

const BreadcrumbTreemap = ({ breadcrumb }) => {
    // console.log("breadcrumb de data", breadcrumb)
    return (
        <div className={styles["breadcrumb"]}>
            {/* <a className="breadcrumb__step breadcrumb__step--active" href="#">
                {tipo === 0 ? "Clientes" : (tipo === 1 ? "Marca" : "Sku")}
            </a> */}
            {
                breadcrumb && breadcrumb.map((e, i) => {

                    let nombre = e.slice(0, e.length / 2)

                    return (
                        <a className={`${styles["breadcrumb__step"]} ${styles['breadcrumb__step--active']}`} key={`breadcrumb_${i}`} href="#" >
                            <span style={{ color: 'transparent' }}>{nombre}</span>
                            {
                                breadcrumb.length !== i + 1 ? null : (
                                    <div className={styles["breadcrumb_simbolo"]}></div>
                                )
                            }
                            <span style={{ color: 'transparent' }}>{nombre}</span>
                        </a>
                    )

                })
            }
            {/* 
            <a className="breadcrumb__step" href="#">
                Node.js
            </a>
            <a className="breadcrumb__step" href="#">
                Linux
            </a> */}

        </div>
    );
};

export default BreadcrumbTreemap;