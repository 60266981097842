import React from 'react'
import _ from 'lodash';
import { formatNumberWithCommas } from "../../scripts/helpers";

import styles from "./IndicadorTotales.module.scss";

const IndicadorTotales = ({ tipo, infoVolumen, infoValor, volumenValor }) => {
    if (tipo === 'A' && !(_.isEmpty(infoVolumen) && _.isEmpty(infoValor))) {
        let valor = _.sumBy(infoValor.series[0].data, 'value').toFixed(0);
        let volumen = _.sumBy(infoVolumen.series[0].data, 'value').toFixed(0);

        return (
            <div className={styles["containerIndicador"]}>
                <div className={styles["volumenContainer"]}>
                    <div className={styles["sectionTitle"]}>Total de Volumen</div>
                    <div className={styles["sectionValue"]}>{formatNumberWithCommas(volumen)}</div>
                </div>
                <div className={styles["valorContainer"]}>
                    <div className={styles["sectionTitle"]}>Total de Valor</div>
                    <div className={styles["sectionValue"]}>${formatNumberWithCommas(valor)}</div>
                </div>
            </div>
        )
    } else if (tipo === 'B' && !_.isEmpty(infoVolumen)) {
        let cantidad = volumenValor !== 2 ? _.sumBy(infoVolumen, 'value').toFixed(0) : _.meanBy(infoVolumen, 'value').toFixed(2);

        return (
            <div className={styles["containerIndicador"]}>
                <div className={styles["volumenContainer"]}>
                </div>
                <div className={styles["valorContainer"]}>
                    <div className={styles["sectionTitle"]}>{volumenValor === 0 ? 'Total de Cajas 9L (Global)' : (volumenValor === 1 ? 'Valor Total (Global)' : 'Ticket Promedio (Global)')}</div>
                    <div className={styles["sectionValue"]}>{volumenValor === 0 ? '' : '$'}{formatNumberWithCommas(cantidad)}</div>
                </div>
            </div>
        )
    } else return '';
}

export default IndicadorTotales;