import React, { Component } from "react";
import ReactECharts from 'echarts-for-react';
import Switch from "react-switch";

import Header from "../header/Header";
import FiltroTreemap from "../filtrotreemap/FiltroTreemap";
import styles from "./Vista3.module.scss";

import { formatNumberWithCommas } from "../../scripts/helpers";

class Vista3 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            calendarDate: [new Date(2022, 0, 1), new Date(2022, 1, 31)],
            valorRadio: '0',
            checkedSwitch: false
        };

        this.handleChangeCalendar = this.handleChangeCalendar.bind(this);
        this.handleChangeRadio = this.handleChangeRadio.bind(this);
        this.handleChangeSwitch = this.handleChangeSwitch.bind(this);
    }

    handleChangeCalendar(calendarDate) { this.setState({ calendarDate }); }
    handleChangeRadio(event) {
        let valorRadio = event.target.value;
        this.setState({ valorRadio });
    }
    handleChangeSwitch(checkedSwitch) { this.setState({ checkedSwitch }); }

    render() {
        const {
            calendarDate, valorRadio, checkedSwitch
        } = this.state;

        const optionsA = {
            grid: {
                top: 35,
                bottom: 20,
                right: '10%',
                left: '16%',
            },
            title: {
                text: 'TOP 10 DE VALOR',
                top: 'top',
                left: '5%',
                textStyle: {
                    fontFamily: 'SFHeavy',
                    color: 'gray',
                },
            },
            yAxis: {
                type: 'category',
                inverse: true,
                axisLabel: {
                    color: 'black',
                    fontFamily: 'DDINExp',
                    fontSize: '10px'
                },
                axisLine: { show: false },
                axisTick: { show: false },
                data: ['Del Valle', 'Portales', 'Polanco', 'Santa Fe', 'Taxqueña', "Insurgentes", 'Narvarte', 'Mixcoac', 'Observatorio', 'Anzures'],
            },
            xAxis: {
                type: 'value',
                axisLabel: {
                    color: 'black',
                    fontFamily: 'DDINExp',
                    fontSize: '10px'
                },
                splitLine: {
                    lineStyle: {
                        color: '#000',
                        type: 'dashed',
                        opacity: 0.25,
                    },
                },
            },
            series: [
                {
                    type: 'bar',
                    data: [432872.45, 394127.22, 357922.87, 304128.36, 278912.12, 213563.73, 201101.02, 193102.37, 176345.41, 154012.54],
                    label: {
                        show: true,
                        distance: 10,
                        position: 'right',
                        color: '#270f2b',
                        fontFamily: 'DDINExp',
                        fontSize: '12px',
                        formatter: (s) => { return `$${formatNumberWithCommas(s.data)}`; }
                    }
                }
            ],
            color: '#270f2b',
        };

        const optionsB = {
            grid: {
                top: 35,
                bottom: 20,
                right: '3%',
                left: '16%',
            },
            title: {
                text: 'TOP 10 DE VOLUMEN',
                top: 'top',
                left: '5%',
                textStyle: {
                    fontFamily: 'SFHeavy',
                    color: 'gray',
                },
            },
            yAxis: {
                type: 'category',
                inverse: true,
                axisLabel: {
                    color: 'black',
                    fontFamily: 'DDINExp',
                    fontSize: '10px'
                },
                axisLine: { show: false },
                axisTick: { show: false },
                data: ['Del Valle', 'Portales', 'Polanco', 'Santa Fe', 'Taxqueña', "Insurgentes", 'Narvarte', 'Mixcoac', 'Observatorio', 'Anzures'],
            },
            xAxis: {
                type: 'value',
                axisLabel: {
                    color: 'black',
                    fontFamily: 'DDINExp',
                    fontSize: '10px'
                },
                splitLine: {
                    lineStyle: {
                        color: '#000',
                        type: 'dashed',
                        opacity: 0.25,
                    },
                },
            },
            series: [
                {
                    type: 'bar',
                    data: [4302, 3802, 3504, 3297, 3183, 2914, 2674, 2498, 2202, 1997],
                    label: {
                        show: true,
                        distance: 10,
                        position: 'right',
                        color: '#270f2b',
                        fontFamily: 'DDINExp',
                        fontSize: '12px',
                        formatter: (s) => { return `${formatNumberWithCommas(s.data)}`; }
                    }
                }
            ],
            color: '#270f2b',
        };

        const maxLimit = !checkedSwitch ? 5000 : 1000000;
        const minLimit = !checkedSwitch ? 4000 : 800000;
        const optionsC = {
            grid: {
                top: 70,
                bottom: 55,
                right: '3%',
                left: '5%',
            },
            title: {
                text: `${!checkedSwitch ? 'VOLÚMENES' : 'VALORES'} POR MES`,
                top: 25,
                left: '2%',
                textStyle: {
                    fontFamily: 'SFHeavy',
                    color: 'gray',
                },
            },
            legend: {
                top: 30,
                data: ['Del Valle', 'Portales', 'Polanco', 'Santa Fe', 'Taxqueña', "Insurgentes", 'Narvarte', 'Mixcoac', 'Observatorio', 'Anzures'],
            },
            tooltip: {
                trigger: 'axis',
                valueFormatter: (value) => {
                    if (checkedSwitch) return `$${formatNumberWithCommas(value)}`;
                    else return `${formatNumberWithCommas(value)}`;
                },
            },
            xAxis: {
                type: 'category',
                boundaryGap: false,
                axisLabel: {
                    color: 'black',
                    fontFamily: 'DDINExp',
                },
                axisLine: { show: true },
                axisTick: { show: false },
                data: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
            },
            yAxis: {
                type: 'value',
                axisLabel: {
                    color: 'black',
                    fontFamily: 'DDINExp',
                },
                axisLine: {
                    show: true,
                    lineStyle: { width: 1 },
                },
                axisTick: {
                    show: true,
                    lineStyle: { width: 1 },
                },
                splitLine: {
                    lineStyle: {
                        color: '#000',
                        type: 'dashed',
                        opacity: 0.15,
                    },
                },
            },
            series: [
                {
                    name: 'Del Valle',
                    type: 'line',
                    symbol: 'none',
                    lineStyle: { width: 3 },
                    data: Array.from({ length: 12 }, () => Math.floor(Math.random() * ((maxLimit * 1) - (minLimit * 1) + 1) + (minLimit * 1)))
                },
                {
                    name: 'Portales',
                    type: 'line',
                    symbol: 'none',
                    lineStyle: { width: 3 },
                    data: Array.from({ length: 12 }, () => Math.floor(Math.random() * ((maxLimit * 0.9) - (minLimit * 0.9) + 1) + (minLimit * 0.9)))
                },
                {
                    name: 'Polanco',
                    type: 'line',
                    symbol: 'none',
                    lineStyle: { width: 3 },
                    data: Array.from({ length: 12 }, () => Math.floor(Math.random() * ((maxLimit * 0.8) - (minLimit * 0.8) + 1) + (minLimit * 0.8)))
                },
                {
                    name: 'Santa Fe',
                    type: 'line',
                    symbol: 'none',
                    lineStyle: { width: 3 },
                    data: Array.from({ length: 12 }, () => Math.floor(Math.random() * ((maxLimit * 0.7) - (minLimit * 0.7) + 1) + (minLimit * 0.7)))
                },
                {
                    name: 'Taxqueña',
                    type: 'line',
                    symbol: 'none',
                    lineStyle: { width: 3 },
                    data: Array.from({ length: 12 }, () => Math.floor(Math.random() * ((maxLimit * 0.6) - (minLimit * 0.6) + 1) + (minLimit * 0.6)))
                },
                {
                    name: "Insurgentes",
                    type: 'line',
                    symbol: 'none',
                    lineStyle: { width: 3 },
                    data: Array.from({ length: 12 }, () => Math.floor(Math.random() * ((maxLimit * 0.5) - (minLimit * 0.5) + 1) + (minLimit * 0.5)))
                },
                {
                    name: 'Narvarte',
                    type: 'line',
                    symbol: 'none',
                    lineStyle: { width: 3 },
                    data: Array.from({ length: 12 }, () => Math.floor(Math.random() * ((maxLimit * 0.4) - (minLimit * 0.4) + 1) + (minLimit * 0.4)))
                },
                {
                    name: 'Mixcoac',
                    type: 'line',
                    symbol: 'none',
                    lineStyle: { width: 3 },
                    data: Array.from({ length: 12 }, () => Math.floor(Math.random() * ((maxLimit * 0.3) - (minLimit * 0.3) + 1) + (minLimit * 0.3)))
                },
                {
                    name: 'Observatorio',
                    type: 'line',
                    symbol: 'none',
                    lineStyle: { width: 3 },
                    data: Array.from({ length: 12 }, () => Math.floor(Math.random() * ((maxLimit * 0.2) - (minLimit * 0.2) + 1) + (minLimit * 0.2)))
                },
                {
                    name: 'Anzures',
                    type: 'line',
                    symbol: 'none',
                    lineStyle: { width: 3 },
                    data: Array.from({ length: 12 }, () => Math.floor(Math.random() * ((maxLimit * 0.1) - (minLimit * 0.1) + 1) + (minLimit * 0.1)))
                }
            ]
        }

        return (
            <div className={styles["mainContainer"]}>
                <Header
                    history={this.props.history}
                />
                <div className={styles["subContainer"]}>
                    <FiltroTreemap
                        tipo={'B'}
                        calendarDate={calendarDate}
                        handleChangeCalendar={this.handleChangeCalendar}
                        valorRadio={valorRadio}
                        handleChangeRadio={this.handleChangeRadio}
                    />
                    <div className={styles["infoContainer"]}>
                        <div className={styles["infoTitle"]}>Ranking de Puntos de Venta</div>
                        <div className={styles["chartContainerA"]}>
                            <ReactECharts
                                option={optionsB}
                                style={{ height: '100%' }}
                            />
                            <ReactECharts
                                option={optionsA}
                                style={{ height: '100%' }}
                            />
                        </div>
                        <div className={styles["chartContainerB"]}>
                            <div className={styles["switchContainer"]}>
                                <span className={styles["aligHelper"]}></span>
                                <div className={styles["switchLabels"]}>Volúmenes</div>
                                <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
                                    <Switch onChange={this.handleChangeSwitch} checked={checkedSwitch}
                                        checkedIcon={false} uncheckedIcon={false} height={15} width={40} />
                                </div>
                                <div className={styles["switchLabels"]}>Valores</div>
                            </div>
                            <ReactECharts
                                option={optionsC}
                                style={{ height: '100%' }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Vista3;