import axios from 'axios';
import { getHost } from "../scripts/helpers";

export const AUTHENTICATED = 'authenticated_user';
export const UNAUTHENTICATED = 'unauthenticated_user';
export const AUTHENTICATION_ERROR = 'authentication_error';

const URL = getHost("auth");

export function logInAction({
  email,
  password,
  token
}, history) {
  return async (dispatch) => {
    try {

      const res = await axios.post(`${URL}/auth`, {
        "username": email,
        "password": password + '_' + token,
      });

      const hostname = window.location.hostname;
      if (['3.12.19.179', 'locotequila.datalabmx.com', '3.140.175.113', 'demo.datalabmx.com', '3.17.121.27'].includes(hostname)) {
        let phpFile = '';
        switch (hostname) {
          case '3.12.19.179': phpFile = 'allowedUsersMonteXanic.php'; break;
          case '3.140.175.113': phpFile = 'allowedUsersMonteXanic.php'; break;
          case 'demo.datalabmx.com': phpFile = 'allowedUsersMonteXanic.php'; break;
          case '3.17.121.27': phpFile = 'allowedUsersMonteXanic.php'; break;
          case 'locotequila.datalabmx.com': phpFile = 'allowedUsersLocoTequila.php'; break;
          default: phpFile = 'allowedUsersMonteXanic.php'; break;
        }

        const params = new URLSearchParams();
        params.append('correo', email);
        axios({
          method: 'post',
          url: `./php/${phpFile}`,
          data: params
        }).then(resAuth => {
          if (resAuth.data.testResult === '1') {
            localStorage.setItem('token', res.data.access_token);

            dispatch({
              type: AUTHENTICATED
            });
            history.push('/');
          } else {
            dispatch({
              type: AUTHENTICATION_ERROR,
              payload: 'Usuario no Autorizado'
            });
          }
        }).catch(error => {
          dispatch({
            type: AUTHENTICATION_ERROR,
            payload: 'Error de Autenticación'
          });
        });
      } else {
        dispatch({
          type: AUTHENTICATED
        });
        history.push('/');
      }
    } catch (error) {
      dispatch({
        type: AUTHENTICATION_ERROR,
        payload: 'Credenciales Inválidas'
      });
    }
  };
}
