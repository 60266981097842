import React, { Component } from "react";
import { connect } from "react-redux";
import ReactECharts from 'echarts-for-react';
import _ from 'lodash';

import Header from "../header/Header";
import Filtro from "../filtro/Filtro";
import IndicadorTotales from "../indicadortotales/IndicadorTotales";
import Alcance from "../alcance/Alcance";
import FiltrosAplicados from "../alcance/FiltrosAplicados";
import BreadcrumbTreemap from "./BreacrumbTreemap";
// import PseudoBreadcrumb from "../pseudobreadcrumb/PseudoBreadcrumb";
// import FiltroTreemap from "../filtrotreemap/FiltroTreemap";
import styles from "./Vista1.module.scss";

import { formatNumberWithCommas, getCanalSubtitle, getYTDText } from "../../scripts/helpers";
import { dataAction, filterDataAction } from "../../actions";
import { startOfMonth, endOfMonth, endOfISOWeek } from "date-fns";

class Vista1 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            graphData: [],
            breadcrumb: [],
            // ================================================
            graphOptions: {},
            // ===================
            calendarDate: [new Date(2022, 0, 1), new Date(2022, 11, 31)],
            calendarSPFilter: 'all',
            tipoDivision: 0,
            volumenValor: 0,
            radioValue: 0,
            isSellIn: 0,
            regionesShown: [],
            estadosShown: [],
            clientesShown: [],
            sucursalesShown: [],
            marcasShown: [],
            productosShown: [],
            // ================================================
            availableRegiones: [],
            availableEstados: [],
            availableClientes: [],
            availableSucursales: [],
            availableMarcas: [],
            availableProductos: [],
        };

        this.handleChangeCalendar = this.handleChangeCalendar.bind(this);
        this.handleChangeCalendarWeek = this.handleChangeCalendarWeek.bind(this);
        this.handleChanceCalendarSPFilter = this.handleChanceCalendarSPFilter.bind(this);
        this.handleChangeTipo = this.handleChangeTipo.bind(this);
        this.handleChangeVolumenValor = this.handleChangeVolumenValor.bind(this);
        this.handleChangeRadio = this.handleChangeRadio.bind(this);
        this.handleChangeIsSellIn = this.handleChangeIsSellIn.bind(this);
        this.handleChangeRegion = this.handleChangeRegion.bind(this);
        this.handleChangeEstado = this.handleChangeEstado.bind(this);
        this.handleChangeCliente = this.handleChangeCliente.bind(this);
        this.handleChangeSucursal = this.handleChangeSucursal.bind(this);
        this.handleChangeMarca = this.handleChangeMarca.bind(this);
        this.handleChangeProducto = this.handleChangeProducto.bind(this);
        this.restartFilter = this.restartFilter.bind(this);
    }

    handleChangeCalendar(calendarDate) { this.setState({ calendarDate }); }
    handleChanceCalendarSPFilter(newCalendarSPFilter) {
        const { calendarSPFilter, calendarDate } = this.state;

        let newCalendarDate = calendarDate;
        if (['daily', 'weekly'].includes(calendarSPFilter) && newCalendarSPFilter === 'all') {
            newCalendarDate = [startOfMonth(calendarDate[0]), endOfMonth(calendarDate[1])];
        }

        this.setState({ calendarSPFilter: newCalendarSPFilter, calendarDate: newCalendarDate });
    }
    handleChangeCalendarWeek(weekNumber, date, event) {
        let endOfWeek = endOfISOWeek(date);
        let calendarDate = [date, endOfWeek];

        this.setState({ calendarDate });
    }
    handleChangeTipo(tipoDivision) { this.setState({ tipoDivision }); }
    handleChangeVolumenValor(volumenValor) { this.setState({ volumenValor }) }
    handleChangeIsSellIn(isSellIn) { this.setState({ isSellIn }) }
    handleChangeRadio(event) {
        let radioValue = parseInt(event.target.value);
        this.setState({ radioValue });
    }
    handleChangeRegion(event) {
        const { regionesShown } = this.state;
        let region = event.target.value;
        let newRegionesShown = [];
        let newSucursalesShown = [];
        let newEstadosShown = [];
        let newClientesShown = [];

        if (region === 'all') {
            if (regionesShown.length !== 7) {
                newRegionesShown = [0, 1, 2, 3, 4, 5, 6];
                newSucursalesShown = this.props.data.sucursales.map(s => s.id);
                newEstadosShown = this.props.data.estados.map(e => e.estado);
                newClientesShown = this.props.data.cadenas.map(c => c.id);
            }
        } else {
            region = parseInt(region);
            if (regionesShown.includes(region)) {
                newRegionesShown = [...regionesShown].filter(r => r !== region);
            } else {
                newRegionesShown = [...regionesShown];
                newRegionesShown.push(region);
            }

            newSucursalesShown = _.uniq([...this.props.data.sucursales].filter(s => newRegionesShown.includes(parseInt(s.region))).map(s => s.id));
            newEstadosShown = this.props.data.estados.filter(e => newRegionesShown.includes(parseInt(e.region))).map(e => e.estado);
            newClientesShown = _.uniq([...this.props.data.sucursales].filter(s => newSucursalesShown.includes(s.id)).map(s => s.idCadena));
        }

        this.setState({ regionesShown: newRegionesShown, sucursalesShown: newSucursalesShown, estadosShown: newEstadosShown, clientesShown: newClientesShown });
    }
    handleChangeEstado(event) {
        const { estados, sucursales } = this.props.data;
        const { estadosShown } = this.state;
        let estado = event.target.value;
        let newEstadosShown = [];
        let newSucursalesShown = [];
        let newRegionesShown = [];
        let newClientesShown = [];

        if (estado === 'all') {
            if (estadosShown.length !== 33) {
                newEstadosShown = estados.map(e => e.estado);
                newSucursalesShown = sucursales.map(s => s.id);
                newRegionesShown = [0, 1, 2, 3, 4, 5, 6];
                newClientesShown = this.props.data.cadenas.map(c => c.id);
            }
        } else {
            if (estadosShown.includes(estado)) {
                newEstadosShown = [...estadosShown].filter(e => e !== estado);
            } else {
                newEstadosShown = [...estadosShown];
                newEstadosShown.push(estado);
            }

            newSucursalesShown = _.uniq([...sucursales].filter(s => newEstadosShown.includes(s.estado)).map(s => s.id));
            newRegionesShown = _.uniq([...estados].filter(e => newEstadosShown.includes(e.estado)).map(e => parseInt(e.region)));
            newClientesShown = _.uniq([...sucursales].filter(s => newSucursalesShown.includes(s.id)).map(s => s.idCadena));
        }

        this.setState({ estadosShown: newEstadosShown, sucursalesShown: newSucursalesShown, regionesShown: newRegionesShown, clientesShown: newClientesShown });
    }
    handleChangeSucursal(event) {
        const { sucursalesShown, availableSucursales } = this.state;
        let idSucursal = event.target.value;
        let newSucursalesShown = [];
        let newRegionesShown = [];
        let newEstadosShown = [];
        let newClientesShown = [];

        if (idSucursal === 'all') {
            if (sucursalesShown.length !== this.props.data.sucursales.length) {
                newSucursalesShown = this.props.data.sucursales.map(s => s.id);
                newRegionesShown = [0, 1, 2, 3, 4, 5, 6];
                newEstadosShown = this.props.data.estados.map(e => e.estado);
                newClientesShown = this.props.data.cadenas.map(c => c.id);
            }
        } else if (idSucursal.includes('all')) {
            let idCadena = idSucursal.split('_')[1];

            let allSucursalesInRegion = [...this.props.data.sucursales].filter(s => s.idCadena === idCadena).map(s => s.id);
            if (!allSucursalesInRegion.some(s => sucursalesShown.includes(s))) {
                newSucursalesShown = [...sucursalesShown].concat(allSucursalesInRegion);
            } else {
                newSucursalesShown = [...sucursalesShown].filter(s => !allSucursalesInRegion.includes(s));
            }

            let tmpObject = [...this.props.data.sucursales].filter(s => newSucursalesShown.includes(s.id));
            newRegionesShown = _.uniq(tmpObject.map(s => parseInt(s.region)));
            newEstadosShown = _.uniq(tmpObject.map(s => s.estado));
            newClientesShown = _.uniq(tmpObject.map(s => s.idCadena));
        } else {
            if (sucursalesShown.includes(idSucursal)) {
                newSucursalesShown = [...sucursalesShown].filter(s => s !== idSucursal);
            } else {
                newSucursalesShown = [...sucursalesShown];
                newSucursalesShown.push(idSucursal);
            }
            newSucursalesShown = newSucursalesShown.filter(s => availableSucursales.includes(s));

            let tmpObject = [...this.props.data.sucursales].filter(s => newSucursalesShown.includes(s.id));
            newRegionesShown = _.uniq(tmpObject.map(s => parseInt(s.region)));
            newEstadosShown = _.uniq(tmpObject.map(s => s.estado));
            newClientesShown = _.uniq(tmpObject.map(s => s.idCadena));
        }

        this.setState({ sucursalesShown: newSucursalesShown, regionesShown: newRegionesShown, estadosShown: newEstadosShown, clientesShown: newClientesShown });
    }
    handleChangeCliente(event) {
        const { clientesShown } = this.state;
        let idCadena = event.target.value;
        let newClientesShown = [];

        if (idCadena === 'all') {
            if (clientesShown.length !== this.props.data.cadenas.length) {
                newClientesShown = this.props.data.cadenas.map(c => c.id);
            }
        } else if (idCadena.includes('all')) {
            let letra = idCadena.split('_')[1];

            let allClientesInLetter = [];
            if (letra === '#') allClientesInLetter = [...this.props.data.cadenas].filter(c => /^\d/.test(c.nombre)).map(c => c.id);
            else allClientesInLetter = [...this.props.data.cadenas].filter(c => c.nombre.toUpperCase().startsWith(letra)).map(c => c.id);

            if (!allClientesInLetter.some(p => clientesShown.includes(p))) {
                newClientesShown = [...clientesShown].concat(allClientesInLetter);
            } else {
                newClientesShown = [...clientesShown].filter(p => !allClientesInLetter.includes(p));
            }
        } else {
            if (clientesShown.includes(idCadena)) {
                newClientesShown = [...clientesShown].filter(c => c !== idCadena);
            } else {
                newClientesShown = [...clientesShown];
                newClientesShown.push(idCadena);
            }
        }

        this.setState({ clientesShown: newClientesShown });
    }
    handleChangeMarca(event) {
        const { marcasShown } = this.state;
        let marca = event.target.value;
        let newMarcasShown = [];
        let newproductosShown = [];

        if (marca === 'all') {
            if (marcasShown.length !== this.props.data.marcas.length) {
                newMarcasShown = this.props.data.marcas.map(m => m.marca);
                newproductosShown = this.props.data.productos.map(c => c.id);
            }
        } else {
            if (marcasShown.includes(marca)) {
                newMarcasShown = [...marcasShown].filter(m => m !== marca);
            } else {
                newMarcasShown = [...marcasShown];
                newMarcasShown.push(marca);
            }
            newproductosShown = [...this.props.data.productos].filter(p => newMarcasShown.includes(p.marca)).map(p => p.id);
        }

        this.setState({ marcasShown: newMarcasShown, productosShown: newproductosShown });
    }
    handleChangeProducto(event) {
        const { productosShown, availableProductos } = this.state;
        let idProducto = event.target.value;
        let newproductosShown = [];
        let newMarcasShown = [];

        if (idProducto === 'all') {
            if (productosShown.length !== this.props.data.productos.length) {
                newproductosShown = this.props.data.productos.map(c => c.id);
                newMarcasShown = this.props.data.marcas.map(m => m.marca);
            }
        } else if (idProducto.includes('all')) {
            let marca = idProducto.split('_')[1];
            let allProductsInMarca = [...this.props.data.productos].filter(p => p.marca === marca).map(p => p.id);

            if (!allProductsInMarca.some(p => productosShown.includes(p))) {
                newproductosShown = [...productosShown].concat(allProductsInMarca);
            } else {
                newproductosShown = [...productosShown].filter(p => !allProductsInMarca.includes(p));
            }

            newMarcasShown = _.uniq([...this.props.data.productos].filter(p => newproductosShown.includes(p.id)).map(p => p.marca));

        } else {
            if (productosShown.includes(idProducto)) {
                newproductosShown = [...productosShown].filter(c => c !== idProducto);
            } else {
                newproductosShown = [...productosShown];
                newproductosShown.push(idProducto);
            }
            newproductosShown = newproductosShown.filter(p => availableProductos.includes(p));

            newMarcasShown = _.uniq([...this.props.data.productos].filter(p => newproductosShown.includes(p.id)).map(p => p.marca));
        }

        this.setState({ productosShown: newproductosShown, marcasShown: newMarcasShown });
    }

    async componentDidMount() {
        const hostname = window.location.hostname;

        const root = document.documentElement;
        root.style.setProperty('--calendarSelectedRangeColor', '#CCCCCC');

        if (_.isEmpty(this.props.data)) await this.props.dataAction();
        /* console.log('========== INFORMACIÓN EN TREEMAP ==========');
        console.log(this.props.data); */

        let regionesShown = [0, 1, 2, 3, 4, 5, 6];
        let estadosShown = this.props.data.estados.map(e => e.estado);
        let clientesShown = this.props.data.cadenas.map(c => c.id);
        let sucursalesShown = this.props.data.sucursales.map(s => s.id);
        let marcasShown = this.props.data.marcas.map(m => m.marca);
        let productosShown = this.props.data.productos.map(p => p.id);

        let isSellIn = ['locotequila.datalabmx.com'].includes(hostname) ? 1 : 0;
        this.setState({ regionesShown, estadosShown, clientesShown, marcasShown, sucursalesShown, productosShown, isSellIn }, () => { this.buildGraphData(); });
    }

    componentDidUpdate(prevProps, prevState) {
        /* console.log('Antes', prevState)
        console.log('Ahora', this.state); */

        if (
            (prevState.volumenValor !== this.state.volumenValor) || (!_.isEqual(prevState.calendarDate, this.state.calendarDate)) || (prevState.calendarSPFilter !== this.state.calendarSPFilter) || (prevState.isSellIn !== this.state.isSellIn) ||
            (prevState.tipoDivision !== this.state.tipoDivision) || (!_.isEqual(prevState.estadosShown, this.state.estadosShown)) || (prevState.radioValue !== this.state.radioValue) ||
            (!_.isEqual(prevState.regionesShown, this.state.regionesShown)) || (!_.isEqual(prevState.clientesShown, this.state.clientesShown)) || (!_.isEqual(prevState.marcasShown, this.state.marcasShown)) ||
            (!_.isEqual(prevState.productosShown, this.state.productosShown)) || (!_.isEqual(prevState.sucursalesShown, this.state.sucursalesShown))
        ) {
            this.buildGraphData();
        } else if (!_.isEqual(prevState.graphData, this.state.graphData)) {
            this.updateGraphOptions();
        }
    }

    buildGraphData() {
        const { calendarDate, calendarSPFilter, tipoDivision, volumenValor, radioValue, isSellIn, regionesShown, estadosShown, clientesShown, marcasShown, sucursalesShown, productosShown } = this.state;
        const { ventas, cadenas, marcas } = this.props.data;
        const hostname = window.location.hostname;

        let mainGroupBy = tipoDivision === 0 ? 'cadena' : (tipoDivision === 1 ? 'marca' : 'nombreProducto');
        let valuesToSum = volumenValor === 0 ? 'ventas_unidades' : (volumenValor === 1 ? 'ventas_mxn' : 'ticket');

        let ventasData = [...ventas];
        if (calendarSPFilter === 'daily') {
            ventasData = ventasData.filter(v => ['1', '2', '3', '5', '13', '14'].includes(v.idCadena));
        } else if (calendarSPFilter === 'weekly') {
            ventasData = ventasData.filter(v => ['1', '2', '3', '4', '5', '13', '14'].includes(v.idCadena));
        }
        ventasData = ventasData.filter(v => v.fechaFormato >= calendarDate[0] && v.fechaFormato <= calendarDate[1]);
        ventasData = ventasData.filter(v => v.isSellIn === isSellIn);

        let availableRegiones = _.uniq(ventasData.map(v => v.region));
        let availableEstados = _.uniq(ventasData.map(v => v.estado));
        let availableClientes = _.uniq(ventasData.map(v => v.idCadena));
        let availableSucursales = _.uniq(ventasData.map(v => v.idSucursal));
        let availableMarcas = _.uniq(ventasData.map(v => v.marca));
        let availableProductos = _.uniq(ventasData.map(v => v.idProducto));

        ventasData = ventasData.filter(v => regionesShown.includes(v.region));
        ventasData = ventasData.filter(v => estadosShown.includes(v.estado));
        ventasData = ventasData.filter(v => clientesShown.includes(v.idCadena));
        ventasData = ventasData.filter(v => sucursalesShown.includes(v.idSucursal));
        ventasData = ventasData.filter(v => marcasShown.includes(v.marca));
        ventasData = ventasData.filter(v => productosShown.includes(v.idProducto));

        if (['locotequila.datalabmx.com'].includes(hostname)) {
            if (radioValue !== 0) ventasData = ventasData.filter(v => v.idCanal === radioValue);
        } else {
            let ventasPorTelefono = ['23', '149', '3484', '6046'];
            let ventasPorInternet = ['22', '148', '190', '693', '3478', '4928', '5262', '4856', '4990'];
            let ventasConjuntas = ventasPorTelefono.concat(ventasPorInternet);

            let cadenasMayoristas = ['5', '6', '9', '10', '11', '12', '13', '14', '16', '19', '20'];

            switch (radioValue) {
                case 0:
                    // Todos
                    break;
                case 1:
                    // Mayoreo
                    ventasData = ventasData.filter(v => !ventasConjuntas.includes(v.idSucursal) && cadenasMayoristas.includes(v.idCadena));
                    break;
                case 2:
                    // Menudeo
                    ventasData = ventasData.filter(v => !ventasConjuntas.includes(v.idSucursal) && !cadenasMayoristas.includes(v.idCadena));
                    break;
                case 3:
                    // Telemarketing
                    ventasData = ventasData.filter(v => ventasPorTelefono.includes(v.idSucursal));
                    break;
                case 4:
                    // E-Commerce
                    ventasData = ventasData.filter(v => ventasPorInternet.includes(v.idSucursal));
                    break;
                default:
                    console.log('Opción no encontrada');
            }
        }

        const trueTotal = volumenValor !== 2 ? _.sumBy(ventasData, valuesToSum) : 1;

        ventasData = _.groupBy(ventasData, mainGroupBy);

        let marcasFollowUp = {};
        let marcasColors = [];
        if (tipoDivision === 2) {
            let marcasAvailable = Object.keys(ventasData).map(key => { return { marca: ventasData[key][0].marca }; });

            let marcasCount = _.countBy(marcasAvailable, 'marca');

            marcasColors = Object.keys(marcasCount).map(marca => {
                marcasFollowUp[marca] = 0;
                let gradienteMarca = marcas.find(m => m.marca === marca).gradiente;
                let coloresMarca = gradienteMarca.colors(marcasCount[marca]);
                return { marca, coloresMarca };
            });
        }

        let graphData = Object.keys(ventasData).map(key => {
            let keyInfo = ventasData[key];

            let mainTotal = volumenValor !== 2 ? _.sumBy(keyInfo, valuesToSum) : _.meanBy(keyInfo, valuesToSum);
            let mainPercentage = mainTotal / trueTotal * 100;
            mainPercentage = parseFloat(mainPercentage.toFixed(mainPercentage > 0.01 ? 2 : 4));

            let subkeyGroupBy = tipoDivision === 0 ? 'marca' : 'cadena';
            let infoBySubkey = _.groupBy(keyInfo, subkeyGroupBy);
            let mainChildren = Object.keys(infoBySubkey).map(subkey => {
                let subkeyInfo = infoBySubkey[subkey];

                let subkeyTotal = volumenValor !== 2 ? _.sumBy(subkeyInfo, valuesToSum) : _.meanBy(subkeyInfo, valuesToSum);
                let subkeyPercentage = subkeyTotal / mainTotal * 100;
                subkeyPercentage = parseFloat(subkeyPercentage.toFixed(subkeyPercentage > 0.01 ? 2 : 4));

                let thirdKeyGroupBy = tipoDivision === 0 ? 'nombreProducto' : (tipoDivision === 1 ? 'nombreProducto' : 'tmp');
                let infoByThirdKey = _.groupBy(subkeyInfo, thirdKeyGroupBy);
                let subkeyChildren = Object.keys(infoByThirdKey).map(thirdKey => {
                    let thirdKeyInfo = infoByThirdKey[thirdKey];

                    let thirdKeyTotal = volumenValor !== 2 ? _.sumBy(thirdKeyInfo, valuesToSum) : _.meanBy(thirdKeyInfo, valuesToSum);
                    let thirdKeyPercentage = thirdKeyTotal / subkeyTotal * 100;
                    thirdKeyPercentage = parseFloat(thirdKeyPercentage.toFixed(thirdKeyPercentage > 0.01 ? 2 : 4));

                    let thirdKeyObject = { name: thirdKey, value: thirdKeyTotal, percentage: thirdKeyPercentage, children: [] };
                    return thirdKeyObject;
                });

                let subkeyObject = { 'name': subkey, value: subkeyTotal, percentage: subkeyPercentage, children: subkeyChildren };
                return subkeyObject;
            });

            let finalObject = { 'name': key, value: mainTotal, percentage: mainPercentage, children: mainChildren };
            if (['demo.datalabmx.com', '3.17.121.27', '3.12.19.179'].includes(hostname) && ['cadena', 'marca', 'nombreProducto'].includes(mainGroupBy)) {
                let colorCadena = '';
                if (mainGroupBy === 'cadena') colorCadena = cadenas.find(c => c.nombre === key).color;
                else if (mainGroupBy === 'marca') colorCadena = marcas.find(c => c.marca === key).colorMarca;
                else if (mainGroupBy === 'nombreProducto') {
                    let marca = keyInfo[0].marca
                    let colorArray = marcasColors.find(m => m.marca === marca).coloresMarca;
                    colorCadena = colorArray[marcasFollowUp[marca]]

                    marcasFollowUp[marca]++;
                }

                finalObject['itemStyle'] = { color: colorCadena };
            }

            return finalObject;
        });
        /* console.log('=========== INFORMACIÓN TREEMAP ===========');
        console.log(graphData); */

        this.setState({ graphData, availableRegiones, availableEstados, availableClientes, availableSucursales, availableMarcas, availableProductos });
    }

    updateGraphOptions() {
        const {
            graphData,
            tipoDivision, volumenValor
        } = this.state;

        const graphOptions = {
            tooltip: {
                show: true,
                formatter: (params) => {
                    let d = params.data;
                    let color = d.itemStyle ? d.itemStyle.color : '#ffffff';

                    return `
                    <div style="display: flex; flex-direction: column">
                        <div>
                            <div style="display: inline-block; width: 1.85vh; height: 1.85vh; background-color: ${color};"></div>
                            <span style="font-size: 1.8vh; font-family: AvenirHeavy; color: #58595b;">${d.name}: </span>
                        </div>
                        <span style="font-size: 2.29vh; font-family: AvenirHeavy; margin-left: 1.5vw; color: #58595b;">
                            ${volumenValor === 0 ? "" : "$"}${formatNumberWithCommas(d.value.toFixed(d.value > 1 ? 0 : 2))}
                            <span style="font-size: 1.04vh; font-family: AvenirMedium; color: #939598;"> ${volumenValor === 0 ? " Cajas (9L)" : " "}</span>
                        </span>
                        ${volumenValor !== 2 ? (
                            `<span style="font-size: 1.46vh; font-family: AvenirMedium; margin-left: 1.5vw; color: #939598;">
                                ${d.percentage ? d.percentage : 100}%
                            </span>`
                        ) : ''}
                    </div>`;
                },
            },
            series: [
                {
                    name: tipoDivision === 0 ? 'Clientes' : (tipoDivision === 1 ? 'Marca' : 'SKU'),
                    type: 'treemap',
                    leafDepth: 1,
                    roam: 'move',
                    drillDownIcon: '',
                    top: '2%',
                    left: '4%',
                    right: '4%',
                    bottom: '5%',
                    colorSaturation: [0.35, 0.5],
                    label: {
                        show: true,
                        position: ['5%', '5%'],
                        formatter: (params) => {
                            return [
                                `{nombre|${params.name}}`,
                                `{cantidad|${volumenValor === 0 ? '' : '$ '}${formatNumberWithCommas(params.value.toFixed(params.value > 1 ? 0 : 2))}}`,
                                (volumenValor !== 2 ? `{porcentaje|(${params.data.percentage}%)}` : '')
                            ].join('\n');
                        },
                        rich: {
                            nombre: {
                                color: 'white',
                                fontFamily: 'AvenirMedium',
                                fontSize: 16,
                                align: 'left',
                                verticalAlign: 'bottom',
                            },
                            cantidad: {
                                color: 'white',
                                fontFamily: 'AvenirBlack',
                                fontSize: 30,
                                lineHeight: 40,
                                align: 'left',
                                verticalAlign: 'bottom',
                            },
                            porcentaje: {
                                color: 'white',
                                fontFamily: 'AvenirBook',
                                fontSize: 16,
                                lineHeight: 24,
                                align: 'left',
                                verticalAlign: 'bottom',
                            },
                        },
                    },
                    upperLabel: {
                        show: true,
                        formatter: '{b}',
                        fontFamily: 'AvenirNextDemiBold',
                        // position: 'insideLeft',
                        height: 32,
                        fontSize: 32,
                    },
                    itemStyle: {
                        gapWidth: 5,
                    },
                    breadcrumb: {
                        itemStyle: {
                            color: '#000000',
                            textStyle: {
                                color: '#ffffff',
                                fontFamily: 'AvenirHeavy',
                            },
                        },
                    },
                    select: {
                        upperLabel: {
                            formatter: (params) => {
                                let serie = params.treeAncestors.map(e => e.name)
                                this.setState({ breadcrumb: serie })
                                return serie
                            },
                        },
                    },
                    data: graphData,
                }
            ]
        };

        this.setState({ graphOptions });
    }

    restartFilter() {
        const hostname = window.location.hostname;
        let isSellIn = ['locotequila.datalabmx.com'].includes(hostname) ? 1 : 0;

        this.setState({
            calendarDate: [new Date(2022, 0, 1), new Date(2022, 11, 31)],
            calendarSPFilter: 'all',
            tipoDivision: 0,
            volumenValor: 0,
            radioValue: 0,
            isSellIn,
            regionesShown: [0, 1, 2, 3, 4, 5, 6],
            estadosShown: this.props.data.estados.map(e => e.estado),
            clientesShown: this.props.data.cadenas.map(c => c.id),
            sucursalesShown: this.props.data.sucursales.map(s => s.id),
            marcasShown: this.props.data.marcas.map(m => m.marca),
            productosShown: this.props.data.productos.map(p => p.id),
        });
    }

    render() {
        const {
            graphOptions, graphData, breadcrumb,
            calendarDate, calendarSPFilter, tipoDivision, volumenValor, radioValue, isSellIn,
            regionesShown, estadosShown, clientesShown, sucursalesShown, marcasShown, productosShown,
            availableRegiones, availableEstados, availableClientes, availableSucursales, availableMarcas, availableProductos
        } = this.state;
        const apiData = this.props.data;

        // let etiquetaTitulo = tipoDivision === 0 ? 'Cliente' : (tipoDivision === 1 ? 'Marca' : 'Producto');
        let subtitlePartA = volumenValor === 0 ? 'Volumen' : (volumenValor === 1 ? 'Valor' : 'Ticket');
        let subtitlePartB = getCanalSubtitle(radioValue, apiData);
        let subtitlePartC = tipoDivision === 0 ? 'Cadenas' : (tipoDivision === 1 ? 'Marca' : 'SKU');
        let infoSubtitle = ` (${subtitlePartA} / ${subtitlePartB} / ${subtitlePartC})`;

        if (_.isEmpty(apiData)) {
            return (
                <div className={styles["loadingContainer"]}>
                    <span className={styles["aligHelper"]}></span>
                    <object
                        className={styles["loading"]}
                        data="./images/fact_loading_sp.svg"
                        type="image/svg+xml"
                    >
                        <img
                            src="./images/fact_loading_sp.svg"
                            alt="Cargando contenido"
                        />
                    </object>
                </div>
            );
        } else {
            return (
                <div className={styles["mainContainer"]}>
                    <Header
                        history={this.props.history}
                    />
                    <div className={styles["subContainer"]}>
                        {!_.isEmpty(apiData) ? (
                            <Filtro
                                tipo={'A'}
                                calendarDate={calendarDate}
                                handleChangeCalendar={this.handleChangeCalendar}
                                handleChangeCalendarWeek={this.handleChangeCalendarWeek}
                                calendarSPFilter={calendarSPFilter}
                                handleChanceCalendarSPFilter={this.handleChanceCalendarSPFilter}
                                tipoDivision={tipoDivision}
                                handleChangeTipo={this.handleChangeTipo}
                                volumenValor={volumenValor}
                                handleChangeVolumenValor={this.handleChangeVolumenValor}
                                radioValue={radioValue}
                                handleChangeRadio={this.handleChangeRadio}
                                isSellIn={isSellIn}
                                handleChangeIsSellIn={this.handleChangeIsSellIn}
                                regionesShown={regionesShown}
                                handleChangeRegion={this.handleChangeRegion}
                                estadosShown={estadosShown}
                                handleChangeEstado={this.handleChangeEstado}
                                clientesShown={clientesShown}
                                handleChangeCliente={this.handleChangeCliente}
                                sucursalesShown={sucursalesShown}
                                handleChangeSucursal={this.handleChangeSucursal}
                                marcasShown={marcasShown}
                                handleChangeMarca={this.handleChangeMarca}
                                productosShown={productosShown}
                                handleChangeProducto={this.handleChangeProducto}
                                // ====================================================
                                availableRegiones={availableRegiones}
                                availableEstados={availableEstados}
                                availableClientes={availableClientes}
                                availableSucursales={availableSucursales}
                                availableMarcas={availableMarcas}
                                availableProductos={availableProductos}
                                // ====================================================
                                apiData={apiData}
                                restartFilter={this.restartFilter}
                            />
                        ) : ''}
                        <div className={styles["infoContainer"]}>
                            {graphData.length === 0 ? (
                                <div className={styles["noDataContainer"]}>No existen datos correspondientes a este filtrado</div>
                            ) : ''}
                            <div className={styles["alcanceContainer"]}>
                                <Alcance apiData={apiData} />
                                <FiltrosAplicados
                                    apiData={apiData}
                                    tipoDivision={tipoDivision} volumenValor={volumenValor} radioValue={radioValue}
                                    regionesShown={regionesShown} estadosShown={estadosShown}
                                    clientesShown={clientesShown} marcasShown={marcasShown} productosShown={productosShown}
                                />
                            </div>
                            <IndicadorTotales tipo={'B'} infoVolumen={graphData} infoValor={{}} volumenValor={volumenValor} />
                            <div className={styles["infoTitle"]}>
                                Treemap de Ventas
                                {/* <span className={styles["infoSubtitle"]}>{infoSubtitle}</span> */}
                                {/* <PseudoBreadcrumb
                                    apiData={apiData} tipoDivision={tipoDivision}
                                    regionesShown={regionesShown} clientesShown={clientesShown}
                                    marcasShown={marcasShown} productosShown={productosShown}
                                /> */}
                                <div className={styles["ytdContainer"]}>{getYTDText(calendarDate, true)}</div>
                            </div>
                            <div className={styles["chartContainer"]}>
                                {!_.isEmpty(graphOptions) ? (
                                    <>
                                        <ReactECharts
                                            option={graphOptions}
                                            style={{ height: '95%' }}
                                        />
                                        <BreadcrumbTreemap breadcrumb={breadcrumb} />
                                    </>
                                ) : ''}
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

function mapStateToProps(state) {
    return {
        data: state.data,
    };
}

export default connect(mapStateToProps, {
    dataAction,
    filterDataAction,
})(Vista1);