import React, { Component } from "react";
import { Helmet } from 'react-helmet';

import styles from "./Header.module.scss";

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            logoURL: '',
            circleColor: '',
            selectedColor: '',
            // =================
            pageTitle: '',
            favicon: '',
        };

        this.logOut = this.logOut.bind(this);
        this.reroute = this.reroute.bind(this);
    }

    reroute(option) {
        this.props.history.push(`/${option}`);
    }

    logOut() {
        localStorage.removeItem("token");
        this.props.history.push("/login");
        window.location.reload();
    }

    componentDidMount() {
        let hostname = window.location.hostname;
        let logoURL, circleColor, selectedColor, pageTitle, favicon;
        const root = document.documentElement;
        root.style.setProperty('--calendarSelectedRangeColor', '#CCCCCC');

        switch (hostname) {
            case '3.12.19.179':
                logoURL = 'monte_xanic_logo_calado.svg';
                circleColor = '#363A43';
                selectedColor = '#515662';
                pageTitle = 'Monte Xanic';
                favicon = 'favicon_montexanic.png';
                root.style.setProperty('--headerColor', '#363A43');
                root.style.setProperty('--calendarMainColor', '#515662');
                root.style.setProperty('--calendarLightColor', '#6F7480');
                break;
            case 'demo.datalabmx.com':
                logoURL = 'datalabmx_blanco.svg';
                circleColor = '#575756';
                selectedColor = '#888886';
                pageTitle = 'Dashboard BI DataLabMX';
                favicon = 'favicon_datalabmx.png';
                root.style.setProperty('--headerColor', '#575756');
                root.style.setProperty('--calendarMainColor', '#575756');
                root.style.setProperty('--calendarLightColor', '#B9B9B6');
                break;
            case '3.17.121.27':
                logoURL = 'monte_xanic_logo_calado.svg';
                circleColor = '#363A43';
                selectedColor = '#515662';
                pageTitle = 'Monte Xanic';
                favicon = 'favicon_montexanic.png';
                root.style.setProperty('--headerColor', '#363A43');
                root.style.setProperty('--calendarMainColor', '#515662');
                root.style.setProperty('--calendarLightColor', '#6F7480');
                break;
            case 'corporacionrica.datalabmx.com':
                logoURL = 'corporica_logo_blanco.svg';
                circleColor = '#ed1b2f';
                selectedColor = '#F74758';
                pageTitle = 'Corporación RICA';
                favicon = 'favicon_corporica.png';
                root.style.setProperty('--headerColor', '#ed1b2f');
                root.style.setProperty('--calendarMainColor', '#ed1b2f');
                root.style.setProperty('--calendarLightColor', '#F9707D');
                break;
            case 'locotequila.datalabmx.com':
                logoURL = 'locotequila_logo_calado.svg';
                circleColor = '#772835';
                selectedColor = '#934450';
                pageTitle = 'Loco Tequila';
                favicon = 'favicon_locotequila.png';
                root.style.setProperty('--headerColor', '#772835');
                root.style.setProperty('--calendarMainColor', '#772835');
                root.style.setProperty('--calendarLightColor', '#B76A76');
                break;
            case 'cbrands.datalabmx.com':
                logoURL = 'cbrands_logo_calado.svg';
                circleColor = '#001E5B';
                selectedColor = '#002878';
                pageTitle = 'Constellation Brands';
                favicon = 'favicon_cbrands.png';
                root.style.setProperty('--headerColor', '#001E5B');
                root.style.setProperty('--calendarMainColor', '#002878');
                root.style.setProperty('--calendarLightColor', '#013396');
                break;
            case 'alianza.datalabmx.com':
                logoURL = 'alianza_logo.svg';
                circleColor = '#270f2b';
                selectedColor = '#341B38';
                pageTitle = 'Bodegas Alianza';
                favicon = 'favicon_alianza.png';
                root.style.setProperty('--headerColor', '#270f2b');
                root.style.setProperty('--calendarMainColor', '#68476C');
                root.style.setProperty('--calendarLightColor', '#9D889F');
                break;
            default:
                logoURL = 'freixenet_logo_calado.png';
                circleColor = '#270f2b';
                selectedColor = '#341B38';
                pageTitle = 'Freixenet';
                favicon = 'favicon.png';
                root.style.setProperty('--headerColor', '#270f2b');
                root.style.setProperty('--calendarMainColor', '#68476C');
                root.style.setProperty('--calendarLightColor', '#9D889F');
        }

        this.setState({ logoURL, circleColor, selectedColor, pageTitle, favicon });
    }

    render() {
        const { logoURL, circleColor, selectedColor, pageTitle, favicon } = this.state;
        const pathname = this.props.history.location.pathname;

        return (
            <div className={styles["headerContainer"]}>
                <Helmet>
                    <title>{pageTitle}</title>
                    <link rel="icon" href={`./images/favicon/${favicon}`} />
                </Helmet>
                <span className={styles["aligHelper"]}></span>
                <img src={`./images/${logoURL}`} alt="Logotipo" className={styles["headerLogo"]}></img>
                <div className={styles["salirContainer"]} onClick={this.logOut.bind(this)}>
                    <span className={styles["aligHelper"]}></span>
                    <img src="./images/salirIcon.png" alt="Ícono Salir" className={styles["iconoSalir"]}></img>
                </div>
                <div className={styles["menuOption"]} style={{ backgroundColor: pathname === '/ranking' ? selectedColor : circleColor }}
                    onClick={() => { this.reroute('ranking') }}>
                    <span className={styles["aligHelper"]}></span>
                    <div className={styles["menuName"]} style={{ fontFamily: pathname === '/ranking' ? 'AvenirHeavy' : 'AvenirBook' }}>Ranking</div>
                </div>
                <div className={styles["menuOption"]} style={{ backgroundColor: pathname === '/comparativo' ? selectedColor : circleColor }}
                    onClick={() => { this.reroute('comparativo') }}>
                    <span className={styles["aligHelper"]}></span>
                    <div className={styles["menuName"]} style={{ fontFamily: pathname === '/comparativo' ? 'AvenirHeavy' : 'AvenirBook' }}>Comparativo</div>
                </div>
                {/* <div className={styles["menuOption"]} style={{ backgroundColor: pathname === '/mapa' ? selectedColor : circleColor }}
                    onClick={() => { this.reroute('mapa') }}>
                    <span className={styles["aligHelper"]}></span>
                    <div className={styles["menuName"]} style={{ fontFamily: pathname === '/mapa' ? 'AvenirHeavy' : 'AvenirBook' }}>Mapa</div>
                </div> */}
                <div className={styles["menuOption"]} style={{ backgroundColor: pathname === '/top10' ? selectedColor : circleColor }}
                    onClick={() => { this.reroute('top10') }}>
                    <span className={styles["aligHelper"]}></span>
                    <div className={styles["menuName"]} style={{ fontFamily: pathname === '/top10' ? 'AvenirHeavy' : 'AvenirBook' }}>Top 10</div>
                </div>
                <div className={styles["menuOption"]} style={{ backgroundColor: pathname === '/treemap' ? selectedColor : circleColor }}
                    onClick={() => { this.reroute('treemap') }}>
                    <span className={styles["aligHelper"]}></span>
                    <div className={styles["menuName"]} style={{ fontFamily: pathname === '/treemap' ? 'AvenirHeavy' : 'AvenirBook' }}>Treemap</div>
                </div>
                <div className={styles["menuOption"]} style={{ backgroundColor: pathname === '/sellout' ? selectedColor : circleColor }}
                    onClick={() => { this.reroute('sellout') }}>
                    <span className={styles["aligHelper"]}></span>
                    <div className={styles["menuName"]} style={{ fontFamily: pathname === '/sellout' ? 'AvenirHeavy' : 'AvenirBook' }}>Sell Out</div>
                </div>
                <div className={styles["menuOption"]} style={{ backgroundColor: pathname === '/' ? selectedColor : circleColor }}
                    onClick={() => { this.reroute('') }}>
                    <span className={styles["aligHelper"]}></span>
                    <div className={styles["menuName"]} style={{ fontFamily: pathname === '/' ? 'AvenirHeavy' : 'AvenirBook' }}>Inicio</div>
                </div>
            </div>
        );
    }
}

export default Header;