import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from 'react-helmet';

import { logInAction } from "../../actions";
import styles from "./LogIn.module.scss";

class LogIn extends Component {
    constructor(props) {
        super(props);
        this.state = {
            method: null,
            email: "",
            password: "",
            token: "",
            // ==============
            logoURL: '',
            pageTitle: '',
            favicon: '',
            iconType: '',
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.logIn = this.logIn.bind(this);

        this.token1 = React.createRef();
        this.token2 = React.createRef();
        this.token3 = React.createRef();
        this.token4 = React.createRef();
        this.submitBtn = React.createRef();
        this.tokenChange.bind(this);
    }

    handleInputChange(e) {
        if (e.target.type === "text") {
            this.setState({ email: e.target.value });
        } else {
            this.setState({ password: e.target.value });
        }
    }

    tokenChange(e, nextToken) {
        if (nextToken) {
            if (this[nextToken].current) {
                if (e.target.value !== "") {
                    this[nextToken].current.focus();
                }
                this.setState({
                    token:
                        this.token1.current.value +
                        this.token2.current.value +
                        this.token3.current.value +
                        this.token4.current.value,
                });
            }
        }
    }

    logIn(e) {
        this.props.logInAction(
            {
                email: this.state.email,
                password: this.state.password,
                token: this.state.token,
            },
            this.props.history
        );
    }

    errorMessage() {
        if (this.props.errorMessage) {
            return <div className={styles["errorMessage"]}>{this.props.errorMessage}</div>;
        }
    }

    componentDidMount() {
        let hostname = window.location.hostname;
        let logoURL, pageTitle, favicon, iconType;
        const root = document.documentElement;

        switch (hostname) {
            case '3.12.19.179':
                logoURL = 'monte_xanic_logo.svg';
                pageTitle = 'Monte Xanic';
                favicon = 'favicon_montexanic.png';
                iconType = '';
                root.style.setProperty('--topContainerColor', 'white');
                root.style.setProperty('--arrowFilter', 'brightness(1)');
                root.style.setProperty('--bottomContainerColor', '#363A43');
                root.style.setProperty('--inputBorder', '2px solid #41474E');
                root.style.setProperty('--inputColor', '#2A2D34');
                root.style.setProperty('--inputTextColor', 'white');
                root.style.setProperty('--buttonColor', 'black');
                break;
            case 'demo.datalabmx.com':
                logoURL = 'datalabmx.svg';
                pageTitle = 'Dashboard BI DataLabMX';
                favicon = 'favicon_datalabmx.png';
                iconType = '';
                root.style.setProperty('--topContainerColor', 'white');
                root.style.setProperty('--arrowFilter', 'brightness(1) invert(45%) sepia(77%) saturate(2902%) hue-rotate(344deg) brightness(97%) contrast(95%)');
                root.style.setProperty('--bottomContainerColor', 'white');
                root.style.setProperty('--inputBorder', '2px solid #A9AFB0');
                root.style.setProperty('--inputColor', '#7f8c8d');
                root.style.setProperty('--inputTextColor', 'white');
                root.style.setProperty('--buttonColor', 'black');
                break;
            case '3.17.121.27':
                logoURL = 'monte_xanic_logo.svg';
                pageTitle = 'Monte Xanic';
                favicon = 'favicon_montexanic.png';
                iconType = '';
                root.style.setProperty('--topContainerColor', 'white');
                root.style.setProperty('--arrowFilter', 'brightness(1)');
                root.style.setProperty('--bottomContainerColor', '#363A43');
                root.style.setProperty('--inputBorder', '2px solid #41474E');
                root.style.setProperty('--inputColor', '#2A2D34');
                root.style.setProperty('--inputTextColor', 'white');
                root.style.setProperty('--buttonColor', 'black');
                break;
            case 'corporacionrica.datalabmx.com':
                logoURL = 'corporica_logo.svg';
                pageTitle = 'Corporación RICA';
                favicon = 'favicon_corporica.png';
                iconType = '_gray';
                root.style.setProperty('--topContainerColor', 'white');
                root.style.setProperty('--arrowFilter', 'brightness(1)');
                root.style.setProperty('--bottomContainerColor', '#ed1b2f');
                root.style.setProperty('--inputBorder', '2px solid #333333');
                root.style.setProperty('--inputColor', 'white');
                root.style.setProperty('--inputTextColor', '#333333');
                root.style.setProperty('--buttonColor', '#333333');
                break;
            case 'locotequila.datalabmx.com':
                logoURL = 'locotequila_logo.png';
                pageTitle = 'Loco Tequila';
                favicon = 'favicon_locotequila.png';
                iconType = '';
                root.style.setProperty('--topContainerColor', 'white');
                root.style.setProperty('--arrowFilter', 'brightness(0.1) saturate(100%) invert(19%) sepia(22%) saturate(2922%) hue-rotate(311deg) brightness(99%) contrast(93%)');
                root.style.setProperty('--bottomContainerColor', '#772835');
                root.style.setProperty('--inputBorder', '2px solid #58121D');
                root.style.setProperty('--inputColor', '#934450');
                root.style.setProperty('--inputTextColor', 'white');
                root.style.setProperty('--buttonColor', '#58121D');
                break;
            case 'cbrands.datalabmx.com':
                logoURL = 'cbrands_logo.svg';
                pageTitle = 'Constellation Brands';
                favicon = 'favicon_cbrands.png';
                iconType = '';
                root.style.setProperty('--topContainerColor', 'white');
                root.style.setProperty('--arrowFilter', 'brightness(0.1) saturate(100%) invert(7%) sepia(52%) saturate(6867%) hue-rotate(213deg) brightness(94%) contrast(106%)');
                root.style.setProperty('--bottomContainerColor', '#001E5B');
                root.style.setProperty('--inputBorder', '2px solid #00143B');
                root.style.setProperty('--inputColor', '#002878');
                root.style.setProperty('--inputTextColor', 'white');
                root.style.setProperty('--buttonColor', '#00143B');
                break;
            case 'alianza.datalabmx.com':
                logoURL = 'alianza_logo.svg';
                pageTitle = 'Bodegas Alianza';
                favicon = 'favicon_alianza.png';
                iconType = '';
                root.style.setProperty('--topContainerColor', '#270f2b');
                root.style.setProperty('--arrowFilter', 'brightness(0.05) saturate(100%) invert(6%) sepia(30%) saturate(3466%) hue-rotate(266deg) brightness(97%) contrast(97%)');
                root.style.setProperty('--bottomContainerColor', 'white');
                root.style.setProperty('--inputBorder', '2px solid #371B3B');
                root.style.setProperty('--inputColor', '#4a314e');
                root.style.setProperty('--inputTextColor', 'white');
                root.style.setProperty('--buttonColor', '#270f2b');
                break;
            default:
                logoURL = 'freixenet_logo_calado.png';
                pageTitle = 'Freixenet';
                favicon = 'favicon.png';
                iconType = '';
                root.style.setProperty('--topContainerColor', '#270f2b');
                root.style.setProperty('--arrowFilter', 'brightness(0.05) saturate(100%) invert(6%) sepia(30%) saturate(3466%) hue-rotate(266deg) brightness(97%) contrast(97%)');
                root.style.setProperty('--bottomContainerColor', 'white');
                root.style.setProperty('--inputBorder', '2px solid #371B3B');
                root.style.setProperty('--inputColor', '#4a314e');
                root.style.setProperty('--inputTextColor', 'white');
                root.style.setProperty('--buttonColor', '#270f2b');
        }

        this.setState({ logoURL, pageTitle, favicon, iconType });
    }

    render() {
        const { logoURL, pageTitle, favicon, iconType } = this.state;

        return (
            <div className={styles["mainContainer"]}>
                <Helmet>
                    <title>{pageTitle}</title>
                    <link rel="icon" href={`./images/favicon/${favicon}`} />
                </Helmet>
                <div className={styles["topContainer"]}>
                    <span className={styles["aligHelper"]}></span>
                    <img src={`./images/${logoURL}`} alt="Logotipo Plataforma" className={styles["mainLogo"]}></img>
                    <img src='./images/iconFlecha.png' alt="Icono Flecha" className={styles["arrowCircle"]}></img>
                </div>
                <div className={styles["bottomContainer"]}>
                    <span className={styles["aligHelper"]}></span>
                    <div className={styles["formContainer"]}>
                        <input type="text" placeholder="USUARIO" className={styles["loginInput"]} style={{ backgroundImage: `url(./images/userIcon${iconType}.png)` }}
                            onChange={this.handleInputChange.bind(this)}></input>
                        <input type="password" placeholder="CONTRASEÑA" className={styles["loginInput"]} style={{ backgroundImage: `url(./images/passwordIcon${iconType}.png)`, marginTop: '20px' }}
                            onChange={this.handleInputChange.bind(this)}></input>
                        <div className={styles["tokenContainer"]}>
                            <div className={styles["tokenTitle"]}>TOKEN</div>
                            <input
                                className={styles["tokenInput"]}
                                type="password"
                                ref={this.token1}
                                size="1"
                                min="0"
                                max="9"
                                maxLength="1"
                                onChange={(e) => this.tokenChange(e, "token2")}
                            />
                            <input
                                className={styles["tokenInput"]}
                                type="password"
                                ref={this.token2}
                                size="1"
                                min="0"
                                max="9"
                                maxLength="1"
                                onChange={(e) => this.tokenChange(e, "token3")}
                            />
                            <input
                                className={styles["tokenInput"]}
                                type="password"
                                ref={this.token3}
                                size="1"
                                min="0"
                                max="9"
                                maxLength="1"
                                onChange={(e) => this.tokenChange(e, "token4")}
                            />
                            <input
                                className={styles["tokenInput"]}
                                type="password"
                                ref={this.token4}
                                size="1"
                                min="0"
                                max="9"
                                maxLength="1"
                                onChange={(e) => this.tokenChange(e, "submitBtn")}
                            />
                        </div>
                        <button type="button" ref={this.submitBtn} onClick={this.logIn.bind(this)} className={styles["buttonLogin"]}>Entrar</button>
                        {this.errorMessage()}
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return { errorMessage: state.auth.error };
}

export default connect(mapStateToProps, { logInAction })(LogIn);